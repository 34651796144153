import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles, InputBase } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  inputRoot: {
    width: '100%',
  },
  input: {
    fontSize: 14,
    fontWeight: 400,
  },
}));

const TextFilter = ({
                      filterValue,
                      dataKey,
                      columnData: { isFilterable },
                      onChange,
                    }) => {
  const classes = useStyles();
  const { t } = useTranslation(['tables']);
  const textInput = React.useRef(null);

  useEffect(() => {
    if (filterValue === '') {
      textInput.current.value = '';
    } else if (textInput.current.value === '' && filterValue !== null) {
      textInput.current.value = filterValue;
    }
  }, [filterValue]);

  const handleInputChange = (e) => {
    if (onChange) {
      onChange(dataKey, e.target.value);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleInputChange(e);
    }
  };

  return (
    <InputBase
      inputRef={textInput}
      defaultValue={filterValue}
      onChange={handleInputChange}
      classes={{
        root: classes.inputRoot,
        input: classes.input,
      }}
      placeholder={isFilterable ? t('tables:filter') : ''}
      onKeyDown={handleKeyDown}
      disabled={!isFilterable}
      autoComplete='off'
      type='search'
    />
  );
};

TextFilter.propTypes = {
  filterValue: PropTypes.string,
  dataKey: PropTypes.string.isRequired,
  columnData: PropTypes.shape({
    isFilterable: PropTypes.bool,
  }).isRequired,
  onChange: PropTypes.func,
};

TextFilter.defaultProps = {
  filterValue: '',
  onChange: undefined,
};

export default TextFilter;
