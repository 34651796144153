const downloadPDF = async (link) => {
  // fake server request, getting the file url as response
  await setTimeout(() => {
    const response = {
      file: process.env.REACT_APP_API_URL + link,
    };
    window.location.href = response.file;
  }, 100);
};

export default downloadPDF;
