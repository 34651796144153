import React from 'react';
import { useTheme, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: 3,
    zIndex: 2000,
  },
}));

const EnvTopBar = () => {
  const theme = useTheme();
  const classes = useStyles();

  if (process.env.REACT_APP_ENV === 'production') {
    return null;
  }
  return (
    <div
      className={classes.root}
      style={{
        backgroundColor: theme.colors[process.env.REACT_APP_ENV],
      }}
    />
  );
};

export default EnvTopBar;
