import i18next from 'i18next';
import { ReactComponent as PendingIcon } from 'assets/icons/pending.svg';
import { ReactComponent as ShippedIcon } from 'assets/icons/shipped.svg';
import { ReactComponent as ProcessingIcon } from 'assets/icons/processing.svg';
import { ReactComponent as HoldIcon } from 'assets/icons/hold.svg';
import permissions from '../../config/permissions';
import FILTER_TYPES from '../Filters/constants';
import { CUSTOM_TAGS_OPTIONS } from '../Shared/CustomTags';

export const CLAIMS_STATUSES = {
  invited: 'invited',
  inProgress: 'inProgress',
  pendingReview: 'pendingReview',
  completed: 'completed',
  processing: 'processing',
  scheduled: 'scheduled',
};

export const ENCOUNTER_CLAIMS_STATUSES = {
  draft: 'draft',
  notCreated: 'notCreated',
  completed: 'completed',
};

export const ENCOUNTER_CLAIMS_SELECTOR_OPTIONS = [
  {
    label: 'Draft',
    value: 'draft',
  },
  {
    label: 'Not Created',
    value: 'notCreated',
  },
  {
    label: 'Completed',
    value: 'completed',
  },
];

export const PAYMENT_METHOD_CLAIMS_SELECTOR_OPTIONS = [
  {
    label: 'Check',
    value: 'check',
  },
  {
    label: 'ACH',
    value: 'ach',
  },
  {
    label: 'CASH',
    value: 'cash',
  },
  {
    label: 'Credit Card',
    value: 'creditCard',
  },
];

export const PR_TYPE_CLAIMS_SELECTOR_OPTIONS = [
  {
    label: 'Copay',
    value: 'copay',
  },
  {
    label: 'Coinsurance',
    value: 'coinsurance',
  },
  {
    label: 'Deductible',
    value: 'deductible',
  },
];

export const BILLING_CLAIMS_STATUSES = {
  billed: 'billed',
  pending: 'pending',
  icdIssue: 'icdIssue',
  insuranceIssue: 'insuranceIssue',
  appointmentCancelled: 'appointmentCancelled',
};

export const BILLING_CLAIMS_SELECTOR_OPTIONS = [
  {
    label: 'Billed',
    value: 'billed',
  },
  {
    label: 'Pending',
    value: 'pending',
  },
  {
    label: 'ICD Issue',
    value: 'icdIssue',
  },
  {
    label: 'Insurance Issue',
    value: 'insuranceIssue',
  },
  {
    label: 'Appt. Cancelled',
    value: 'appointmentCancelled',
  },
];

export const CLAIM_ENTITY_TYPE_SELECTOR_OPTIONS = [
  {
    label: 'Program',
    value: 'program',
  },
  {
    label: 'Assessment',
    value: 'assessment',
  },
  {
    label: 'Resource',
    value: 'resource',
  },
];

export const CPT_STATUS_SELECTOR_OPTIONS = [
  {
    label: 'Billed',
    value: 'billed',
  },
  {
    label: 'Patient Responsibility',
    value: 'patientResponsibility',
  },
  {
    label: 'In Process',
    value: 'inProcess',
  },
  {
    label: 'Paid',
    value: 'paid',
  },
  {
    label: 'Denied',
    value: 'denied',
  },
  {
    label: 'Resubmitted',
    value: 'resubmitted',
  },
  {
    label: 'Not Billable',
    value: 'notBillable',
  },
];

export const CLAIM_STATUS_SELECTOR_OPTIONS = [
  {
    label: 'Billed',
    value: 'billed',
  },
  {
    label: 'Patient Responsibility',
    value: 'patientResponsibility',
  },
  {
    label: 'In Process',
    value: 'inProcess',
  },
  {
    label: 'Paid',
    value: 'paid',
  },
  {
    label: 'Partially Paid',
    value: 'partiallyPaid',
  },
  {
    label: 'Denied',
    value: 'denied',
  },
  {
    label: 'Resubmitted',
    value: 'resubmitted',
  },
  {
    label: 'Not Billable',
    value: 'notBillable',
  },
  {
    label: 'Pending',
    value: 'pending',
  },
];

export const ENCOUNTER_CLAIMS_STATUSES_OPTIONS = [
  {
    label: i18next.t('tables:draft'),
    value: ENCOUNTER_CLAIMS_STATUSES.draft,
    icon: HoldIcon,
    allowEditAssessment: false,
    enabledForNonSponsors: false,
  },
  {
    label: i18next.t('tables:notCreated'),
    value: ENCOUNTER_CLAIMS_STATUSES.notCreated,
    icon: ProcessingIcon,
    allowEditAssessment: false,
    enabledForNonSponsors: false,
  },
  {
    label: i18next.t('tables:completed'),
    value: ENCOUNTER_CLAIMS_STATUSES.completed,
    icon: ShippedIcon,
    allowEditAssessment: true,
    enabledForNonSponsors: true,
  },
];

export const BILLING_CLAIMS_STATUSES_OPTIONS = [
  {
    label: i18next.t('tables:billed'),
    value: BILLING_CLAIMS_STATUSES.billed,
    icon: HoldIcon,
    allowEditAssessment: false,
    enabledForNonSponsors: false,
  },
  {
    label: i18next.t('tables:pending'),
    value: BILLING_CLAIMS_STATUSES.pending,
    icon: PendingIcon,
    allowEditAssessment: true,
    enabledForNonSponsors: true,
    addDivider: true,
  },
  {
    label: i18next.t('tables:icdIssue'),
    value: BILLING_CLAIMS_STATUSES.icdIssue,
    icon: ProcessingIcon,
    allowEditAssessment: false,
    enabledForNonSponsors: false,
  },
  {
    label: i18next.t('tables:insuranceIssue'),
    value: BILLING_CLAIMS_STATUSES.insuranceIssue,
    icon: ProcessingIcon,
    allowEditAssessment: false,
    enabledForNonSponsors: false,
  },
  {
    label: i18next.t('tables:appointmentCancelled'),
    value: BILLING_CLAIMS_STATUSES.appointmentCancelled,
    icon: ShippedIcon,
    allowEditAssessment: true,
    enabledForNonSponsors: true,
  },
];

export const FIELDS = [
  'accountCustomTags',
  'accountBusinessName',
  'patientFirstName',
  'patientLastName',
  'patientMrnNumber',
  'patientDateOfBirth',
  'entityName',
  'entityOrderTimestamp',
  'claimGeneratedTimestamp',
  'entityCompletedTimestamp',
  'encounterStatus',
  'billingStatus',
  'claimStatus',
  'appointmentTimestamp',
  'entityType',
  'dos',
  'cptCount',
  'cptPaid',
];

export const CLAIMS_FOR_PATIENT_FIELDS = [
  'patientMrnNumber',
  'patientDateOfBirth',
  'entityName',
  'entityOrderTimestamp',
  'claimGeneratedTimestamp',
  'entityCompletedTimestamp',
  'encounterStatus',
  'billingStatus',
  'claimStatus',
  'appointmentTimestamp',
  'entityType',
  'dos',
  'cptCount',
  'cptPaid',
];

export const GET_CLAIMS_COLUMNS = (
  isPatientPage,
  paramAccountUuid,
  totalAccountsCount,
) => [
  {
    width: 200,
    minWidth: 200,
    label: i18next.t('tables:customTags'),
    dataKey: 'accountCustomTags',
    columnData: {
      isFilterable: true,
      filterType: FILTER_TYPES.SELECT,
      options: CUSTOM_TAGS_OPTIONS,
    },
    permission: permissions.ACCOUNTS_GET,
    hidden: Boolean(paramAccountUuid),
  },
  {
    width: 220,
    label: i18next.t('tables:account'),
    dataKey: 'accountBusinessName',
    columnData: {
      isFilterable: true,
      totalAccountsCount,
    },
    flexGrow: 1,
    permission: permissions.ACCOUNTS_GET,
    hidden: Boolean(paramAccountUuid),
    requiredColumn: true,
  },
  {
    width: 120,
    label: i18next.t('tables:customId'),
    dataKey: 'accountCustomIdentifier',
    columnData: {
      isFilterable: true,
    },
    permission: permissions.ACCOUNTS_GET,
    hidden: Boolean(paramAccountUuid),
    requiredColumn: true,
  },
  {
    width: 200,
    minWidth: 200,
    label: i18next.t('tables:mrnNumber'),
    dataKey: 'patientMrnNumber',
    columnData: {
      isFilterable: true,
    },
  },
  {
    width: 200,
    minWidth: 200,
    label: i18next.t('tables:patientFirstName'),
    dataKey: 'patientFirstName',
    columnData: {
      isFilterable: true,
    },
    flexGrow: 1,
    hidden: isPatientPage,
  },
  {
    width: 200,
    label: i18next.t('tables:patientLastName'),
    dataKey: 'patientLastName',
    columnData: {
      isFilterable: true,
    },
    flexGrow: 1,
    hidden: isPatientPage,
  },
  {
    width: 150,
    label: i18next.t('tables:patientDOB'),
    dataKey: 'patientDateOfBirth',
    hidden: isPatientPage,
    columnData: {
      isFilterable: true,
      filterType: FILTER_TYPES.DATE,
    },
  },
  {
    width: isPatientPage ? 350 : 220,
    label: i18next.t('tables:assessmentResource'),
    columnData: {
      isFilterable: true,
    },
    dataKey: 'entityName',
    flexGrow: 1,
  },
  {
    width: 150,
    label: i18next.t('tables:orderDate'),
    dataKey: 'entityOrderTimestamp',
    columnData: {
      shouldRange: true,
      isFilterable: true,
      filterType: FILTER_TYPES.DATE,
    },
  },
  {
    width: 200,
    label: i18next.t('tables:claimGeneratedTimestamp'),
    dataKey: 'claimGeneratedTimestamp',
    columnData: {
      shouldRange: true,
      isFilterable: true,
      filterType: FILTER_TYPES.DATE,
    },
  },
  {
    width: 200,
    label: i18next.t('tables:createdBy'),
    dataKey: 'createdBy',
    columnData: {
      isFilterable: true,
    },
    flexGrow: 1,
  },
  {
    width: 200,
    label: i18next.t('tables:lastModifiedTimestamp'),
    dataKey: 'lastModifiedTimestamp',
    columnData: {
      shouldRange: true,
      isFilterable: true,
      filterType: FILTER_TYPES.DATE,
    },
  },
  {
    width: 200,
    label: i18next.t('tables:modifiedBy'),
    dataKey: 'modifiedBy',
    columnData: {
      isFilterable: true,
    },
    flexGrow: 1,
  },
  {
    width: 150,
    label: i18next.t('tables:appointmentDate'),
    dataKey: 'appointmentTimestamp',
    columnData: {
      shouldRange: true,
      isFilterable: true,
      filterType: FILTER_TYPES.DATE,
    },
  },
  {
    width: 150,
    label: i18next.t('tables:entityCompletedTimestamp'),
    dataKey: 'entityCompletedTimestamp',
    columnData: {
      shouldRange: true,
      isFilterable: true,
      filterType: FILTER_TYPES.DATE,
    },
  },
  {
    width: 150,
    label: i18next.t('tables:paymentDate'),
    dataKey: 'paymentDate',
    columnData: {
      shouldRange: true,
      isFilterable: true,
      filterType: FILTER_TYPES.DATE,
    },
  },
  {
    width: 200,
    label: i18next.t('tables:encounterStatus'),
    dataKey: 'encounterStatus',
    columnData: {
      isFilterable: true,
      filterType: FILTER_TYPES.SELECT,
      options: ENCOUNTER_CLAIMS_STATUSES_OPTIONS,
    },
  },
  {
    width: 220,
    label: i18next.t('tables:billingStatus'),
    dataKey: 'billingStatus',
    columnData: {
      isFilterable: true,
      filterType: FILTER_TYPES.SELECT,
      options: BILLING_CLAIMS_STATUSES_OPTIONS,
    },
  },
  {
    width: 180,
    label: i18next.t('tables:claimStatus'),
    dataKey: 'claimStatus',
    columnData: {
      isFilterable: true,
      filterType: FILTER_TYPES.SELECT,
      options: CLAIM_STATUS_SELECTOR_OPTIONS,
    },
  },
  {
    width: 180,
    label: i18next.t('tables:entityType'),
    dataKey: 'entityType',
    columnData: {
      isFilterable: true,
      filterType: FILTER_TYPES.SELECT,
      options: CLAIM_ENTITY_TYPE_SELECTOR_OPTIONS,
    },
  },
  {
    width: 150,
    label: i18next.t('tables:dos'),
    dataKey: 'dos',
    columnData: {
      shouldRange: true,
      isFilterable: true,
      filterType: FILTER_TYPES.DATE,
    },
  },
  {
    width: 150,
    label: i18next.t('tables:cptCount'),
    dataKey: 'cptCount',
    columnData: {
      isFilterable: true,
    },
    flexGrow: 1,
  },
  {
    width: 150,
    label: i18next.t('tables:cptPaid'),
    dataKey: 'cptPaid',
    columnData: {
      isFilterable: true,
    },
    flexGrow: 1,
  },
];

export const FAKE_CLAIM = {
  accountCustomTags: [],
  uuid: '',
  patientUUID: '',
  accountCustomIdentifier: '',
  accountUUID: '',
  encounterStatus: '',
  billingStatus: '',
  entityName: '',
  accountBusinessName: '',
  patientFirstName: '',
  patientLastName: '',
  patientDateOfBirth: '',
  entityOrderTimestamp: '',
  claimGeneratedTimestamp: '',
  entityCompletedTimestamp: '',
  code: '',
  entityType: '',
  moreTitle: '',
  resendInvitationAction: true,
};
