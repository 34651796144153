import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Divider, Typography, makeStyles,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { FieldArray } from 'formik';
import AddressForm from 'components/Accounts/AddressForm';
import RoundedButton from 'components/Shared/RoundedButton';
import { EMPTY_ADDRESS } from 'components/Shared/constants';

const useStyles = makeStyles((theme) => ({
  title: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(1),
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
}));

const OtherAddressesSection = ({
                                 otherAddresses,
                                 backendErrors,
                                 validateForm,
                               }) => {
  const { t } = useTranslation(['forms']);
  const classes = useStyles();

  const handleAddressRemove = (arrayHelpers, index) => () => {
    arrayHelpers.remove(index);

    // TODO: remove this when an issue will be fixed
    // https://github.com/jaredpalmer/formik/issues/1616
    setTimeout(() => {
      validateForm();
    }, 200);
  };

  const handleAddressAdd = (arrayHelpers) => () => {
    arrayHelpers.push({ ...EMPTY_ADDRESS });
  };

  const renderOtherAddresses = (arrayHelpers) => (
    <>
      {otherAddresses.map((address, index) => {
        const key = index;
        return (
          <Grid key={key} container direction='column' alignItems='flex-start'>
            <AddressForm
              prefix={`otherAddresses[${index}]`}
              backendErrors={backendErrors}
              onRemove={handleAddressRemove(arrayHelpers, index)}
            />
            <Divider className={classes.divider} />
          </Grid>
        );
      })}
      <RoundedButton
        variant='contained'
        color='primary'
        size='small'
        onClick={handleAddressAdd(arrayHelpers)}
        className={classes.addBtn}
      >
        {t('forms:addAddress')}
      </RoundedButton>
    </>
  );

  return (
    <>
      <Typography className={classes.title}>
        {t('forms:otherAddresses')}:
      </Typography>
      <FieldArray name='otherAddresses' render={renderOtherAddresses} />
    </>
  );
};

OtherAddressesSection.propTypes = {
  otherAddresses: PropTypes.array,
};

OtherAddressesSection.defaultProps = {
  otherAddresses: [],
};

export default OtherAddressesSection;
