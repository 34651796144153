import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, getIn } from 'formik';
import { makeStyles, TextField, MenuItem } from '@material-ui/core';
import cx from 'classnames';
import debounce from 'lodash/debounce';

import { DEBOUNCE_DELAY } from 'components/Shared/constants';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 100,
    marginTop: theme.spacing(3),
  },
}));

const KEY_DATA = { key: '', count: 0 };

const FormikSelectField = ({
  type,
  name,
  backendError,
  className,
  menuItems,
  children,
  ...restProps
}) => {
  const [isOpen, setOpen] = useState(false);
  const [keyData, setKeyData] = useState(KEY_DATA);
  const classes = useStyles();

  return (
    <Field type={type} name={name}>
      {({ field, form }) => {
        const frontendError = getIn(form.errors, field.name);
        const touched = getIn(form.touched, field.name);
        const debounceSetValue = debounce(form.setFieldValue, DEBOUNCE_DELAY);

        const changeValueOnKeyDown = ({ key, count }) => {
          const items = menuItems.filter((item) =>
            item.label.toLowerCase().startsWith(key),
          );
          const len = items.length;
          if (!len) {
            return;
          }
          const index = (count + len - 1) % len;
          debounceSetValue(field.name, items[index].value);
        };

        return (
          <TextField
            select
            name={field.name}
            value={field.value}
            type={type}
            error={(touched && !!frontendError) || !!backendError}
            helperText={frontendError || backendError || ' '}
            className={cx(classes.formControl, className)}
            margin="dense"
            variant="outlined"
            onChange={(e) => {
              form.setFieldValue(field.name, e.target.value);
            }}
            onBlur={field.onBlur}
            onKeyDown={(e) => {
              if (!isOpen) {
                e.persist();
                setKeyData((oldKeyData) => {
                  const newKeyData = {
                    key: e.key,
                  };
                  if (e.key === oldKeyData.key) {
                    newKeyData.count = oldKeyData.count + 1;
                  } else {
                    newKeyData.count = 1;
                  }
                  changeValueOnKeyDown(newKeyData);
                  return newKeyData;
                });
              }
            }}
            SelectProps={{
              onOpen: () => {
                setOpen(true);
              },
              onClose: () => {
                setOpen(false);
                setKeyData(KEY_DATA);
              },
            }}
            {...restProps}
          >
            {children ||
              menuItems.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
          </TextField>
        );
      }}
    </Field>
  );
};

FormikSelectField.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  menuItems: PropTypes.arrayOf(PropTypes.object),
  backendError: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

FormikSelectField.defaultProps = {
  className: undefined,
  backendError: undefined,
  children: undefined,
  menuItems: undefined,
};

export default FormikSelectField;
