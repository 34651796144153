import { createSlice } from '@reduxjs/toolkit';

const getTables = () => {
  let tables = localStorage.getItem('tables');
  return JSON.parse(tables);
};

const setTables = (tables) => {
  localStorage.setItem('tables', JSON.stringify(tables));
};

export const tableColumnsSlice = createSlice({
  name: 'tableColumns',
  initialState: {
    listOfHiddenColumns: {},
  },
  reducers: {
    getTablesFromLocalStorage: (state) => {
      state.listOfHiddenColumns = getTables() ?? {};
    },
    setTablesHiddenColumnByKey: (state, action) => {
      const {tableKey, columnKeys} = action.payload;
      const newList = {...state.listOfHiddenColumns};
      newList[tableKey] = columnKeys;
      state.listOfHiddenColumns = newList;
      setTables(newList);
    },
  },
});

export const {
  getTablesFromLocalStorage,
  setTablesHiddenColumnByKey,
} = tableColumnsSlice.actions;

export default tableColumnsSlice.reducer;

export const TABLE_KEYS = {
  assessments: 'assessments',
  assessmentsDS: 'assessmentsDS',
  patients: 'patients',
  patientsDS: 'patientsDS',
  resources: 'resources',
  resourcesDS: 'resourcesDS',
  billing: 'billing',
  billingDS: 'billingDS',
  claims: 'claims',
  claimsDS: 'claimsDS',
  invoices: 'invoices',
  invoicesDS: 'invoicesDS',
  orders: 'orders',
  ordersDS: 'ordersDS',
  accounts: 'accounts',
  users: 'users',
  allowedAccounts: 'allowedAccounts',
}
