import qs from 'qs';
import { SortDirection } from 'react-virtualized';

export const getEndpoint = (
  baseEndpoint,
  baseParams,
  { sortDirection, sortBy },
  newFilter,
) => {
  const filters = Object.entries(newFilter).map(
    ([filterKey, filterValue]) => `${filterKey}:${filterValue}`,
  );
  if (baseParams.q) {
    filters.push(baseParams.q);
  }
  let sort;
  if (sortBy) {
    const direction = sortDirection === SortDirection.DESC ? '-' : '';
    sort = `${direction}${sortBy}`;
  }
  const qsParams = {
    ...baseParams,
    q: filters,
    sort,
  };
  return `${baseEndpoint}${qs.stringify(qsParams, {
    addQueryPrefix: true,
    arrayFormat: 'repeat',
  })}`;
};

export const getTokenEndpoint = (baseEndpoint, token) =>
  `${baseEndpoint}${qs.stringify(
    {
      'continuation-token': token,
    },
    { addQueryPrefix: true },
  )}`;
