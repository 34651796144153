import React from 'react';
import {
  makeStyles,
  createStyles,
  Dialog,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import cx from 'classnames';

const useStyles = makeStyles((theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& h2': {
        margin: 0,
      },
    },
    paper: {
      minWidth: 760,
    },
    titlesBox: {
      textAlign: 'center',
      padding: theme.spacing(4, 13, 0, 13),
      margin: 0,
      display: 'flex',
      justifyContent: 'space-between',
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(4, 4, 0, 4),
      },
    },
    titles: {
      width: '100%',
    },
    title: {
      fontSize: 36,
      fontWeight: 400,
      color: theme.colors.blue2,
    },
    secondTitle: {
      fontSize: 15,
      fontWeight: 400,
      color: theme.colors.blue2,
    },
  }),
);

const Modal = ({
  title,
  firstTitleStyle,
  secondTitle,
  secondTitleStyle,
  handleClose,
  children,
  titleChildren,
  titleChildrenStyle,
  ...restProps
}) => {
  const classes = useStyles();

  return (
    <Dialog
      disablePortal
      scroll="paper"
      onClose={handleClose}
      className={classes.modal}
      classes={{ paper: classes.paper, container: classes.container }}
      {...restProps}
    >
      <DialogTitle
        disableTypography
        className={cx(classes.titlesBox, firstTitleStyle)}
      >
        <div className={cx(classes.titles, titleChildrenStyle)}>
          <Typography className={classes.title}>{title}</Typography>
          <Typography className={cx(classes.secondTitle, secondTitleStyle)}>
            {secondTitle}
          </Typography>
        </div>
        {titleChildren}
      </DialogTitle>
      {children}
    </Dialog>
  );
};

Modal.propTypes = {
  title: PropTypes.string,
  secondTitle: PropTypes.string,
  children: PropTypes.node,
  titleChildren: PropTypes.node,
  handleClose: PropTypes.func,
  firstTitleStyle: PropTypes.string,
  secondTitleStyle: PropTypes.string,
  titleChildrenStyle: PropTypes.string,
};

Modal.defaultProps = {
  title: undefined,
  secondTitle: undefined,
  titleChildren: undefined,
  children: undefined,
  handleClose: undefined,
  firstTitleStyle: undefined,
  secondTitleStyle: undefined,
  titleChildrenStyle: undefined,
};

export default Modal;
