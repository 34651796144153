import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import { SortDirection } from 'react-virtualized';
import { get, isEmpty } from 'lodash';
import ContentWrapper from 'components/Wrappers/ContentWrapper';
import TablePlaceholder from 'components/Shared/TablePlaceholder';
import VirtualizedTable from 'components/Table/VirtualizedTable';
import ConfirmDialog from 'components/Dialogs/ConfirmDialog';
import useFetchList from 'hooks/useFetchList';
import useStatusChange from 'hooks/useStatusChange';
import showServerError from 'helpers/showError';
import { getLocalizedDate, getLocalizedLabel } from 'helpers/localize';
import useOrderMetadata from 'hooks/useOrderMetadata';
import ordersPlaceholderImage from 'assets/placeholders/orders.svg';
import { useDispatch, useSelector } from 'react-redux';
import { ActivePatientContext } from '../../contexts/ActivePatientProvider';
import { NULL_QUERY_DELAY } from '../Shared/constants';
import RefreshTableButton from '../Shared/RefreshTableButton';
import PatientActions from '../Patients/PatientActions';
import PatientModals from '../Patients/PatientModals';
import PatientAdditionalNode from '../Patients/PatientAdditionalNode';
import PatientInfoBlock from '../Patients/PatientInfoBlock';
import {
  INVOICES_FOR_PATIENT_FIELDS,
  FIELDS,
  INVOICES_STATUSES_OPTIONS,
  GET_INVOICES_COLUMNS,
  MAX_INVOICES,
  INVOICES_STATUSES_TABLE_OPTIONS,
  INVOICES_STATUSES,
  FAKE_INVOICE,
} from './constants';
import { checkPermission } from '../../helpers/checkPermissions';
import permissions from '../../config/permissions';
import getFakeDataList, { FAKE_TITLE_DATA } from '../Shared/FakeDataGenerator';
import { TABLE_KEYS } from '../../redux_store/reducer/reducers/tableColumnsReducer';
import { SNACKBAR_VARIANTS } from '../../redux_store/reducer/reducers/notificationsReducer';
import { CUSTOM_TAGS_OPTIONS } from '../Shared/CustomTags';

const useStyles = makeStyles((theme) => ({
  addNewButton: {
    height: '100%',
    marginRight: theme.spacing(2),
  },
  paper: {
    overflow: 'hidden',
  },
  dot: {
    padding: theme.spacing(0, 0.5),
    color: theme.colors.lightBlue,
  },
  patientInfoBox: {
    display: 'flex',
  },
  flex: {
    display: 'flex',
  },
  refreshIcon: {
    marginLeft: theme.spacing(2),
  },
}));

/*
  could be rendered in 2 cases:
  - all account invoices
  - invoices for a special user (patientUuid in URL params)
*/
const InvoiceList = () => {
  const { t } = useTranslation([
    'titles',
    'tables',
    'errors',
    'notifications',
    'btn',
    'dialogs',
  ]);
  const classes = useStyles();
  const {
    patientUuid: paramsPatientUuid,
    accountUuid: paramAccountUuid,
  } = useParams();
  const isPatientPage = Boolean(paramsPatientUuid);
  const dispatch = useDispatch();
  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbar(...args));
  const {
    metadata: {
      currentAccount,
      currentAccountPermissions,
      childAccountPermissions,
    },
  } = useSelector((state) => state.user);
  const [filterStyle, setFilterStyle] = useState(false);
  const [isOpenStatusDialog, setOpenStatusDialog] = useState(false);
  const [openAssessmentModal, toggleAssessmentModal] = useState(
    window.location.pathname.includes('create-assessment'),
  );
  const [openResourceModal, toggleResourceModal] = useState(false);
  const [openPatientEditModal, togglePatientEditModal] = useState(false);
  const [statusChanging, setStatusChanging] = useState({});
  const [modifiedInvoices, setModifiedInvoices] = useState();
  const [orderPatientUuid, setOrderPatientUuid] = useState(null); // trigger useOrderMetadata
  const { patientUuid, patientInfo } = useContext(ActivePatientContext);

  const { loading: metadataLoading } = useOrderMetadata(
    orderPatientUuid,
    paramAccountUuid,
    () => {
      showServerError(dispatch, t('errors:noProvidersOnThisAccount'));
      setOrderPatientUuid(null);
    },
    {
      actionTitle: 'Edit patient',
      reorderUuid: 'newOrder',
      actionCallback: () => {
        togglePatientEditModal(true);
      },
      callback: () => {
        setOrderPatientUuid(null);
      },
    },
  );

  const baseEndpoint = `/accounts/${
    paramAccountUuid || currentAccount.uuid
  }/invoices`;
  const {
    items,
    totalAccountsCount,
    totalCount,
    completionRate,
    reloadData,
    clearFilter,
    loading: dataLoading,
    ...restFetchProps
  } = useFetchList({
    baseEndpoint,
    baseParams: {
      fields: (isPatientPage ? INVOICES_FOR_PATIENT_FIELDS : FIELDS).join(),
      q: isPatientPage ? `patientUUID:${paramsPatientUuid}` : undefined,
    },
    baseSort: {
      sortBy: 'entityOrderTimestamp',
      sortDirection: SortDirection.DESC,
    },
  });

  const { filter } = restFetchProps;
  const [prevTotalCount, setPrevTotalCount] = useState(totalCount);

  useEffect(() => {
    if (items) {
      setModifiedInvoices(
        items.map((invoce) => ({
          ...invoce,
          patientDateOfBirth: getLocalizedDate(
            invoce.patientDateOfBirth,
            'UTC',
          ),
          accountCustomIdentifier: invoce.accountCustomIdentifier
            ? invoce.accountCustomIdentifier
            : 'N/A',
          entityOrderTimestamp: getLocalizedDate(invoce.entityOrderTimestamp),
          entityCompletedTimestamp: getLocalizedDate(
            invoce.entityCompletedTimestamp,
          ),
          moreTitle: isPatientPage
            ? `${invoce.surveyName}`
            : `${invoce.patientFirstName} ${invoce.patientLastName}`,
          // patientMrnNumber: !invoce.patientMrnNumber ? '-' : invoce.patientMrnNumber,
          resendInvitationAction: !invoce.invitationResendAllowed,
          invoiceStatus: getLocalizedLabel(
            INVOICES_STATUSES_OPTIONS,
            invoce.invoiceStatus,
          ),
          disabledCheckbox: invoce.invoiceStatus === INVOICES_STATUSES.invoiced,
          disabledRowMenu: invoce.invoiceStatus === INVOICES_STATUSES.invoiced,
          accountCustomTags: invoce.accountCustomTags ? invoce.accountCustomTags.map(item => getLocalizedLabel(
            CUSTOM_TAGS_OPTIONS,
            item,
          )) : [],
        })),
      );
    }
  }, [items]);

  useEffect(() => {
    if (prevTotalCount !== totalCount) {
      setFilterStyle(!isEmpty(filter));
      setPrevTotalCount(totalCount);
    }
  }, [filter, totalCount]);

  const handleCloseStatusDialog = () => {
    setOpenStatusDialog(false);
    setStatusChanging({});
  };

  const handleStatusChange = (
    e,
    { uuid, accountUUID },
    selected,
    statusValue,
  ) => {
    const hasPermission = checkPermission(
      accountUUID === currentAccount.uuid
        ? currentAccountPermissions
        : childAccountPermissions,
      permissions.INVOICE_PATCH,
    );

    if (!hasPermission) {
      dispatch(
        enqueueSnackbar(
          t('notifications:notPermissionForChangeInvoiceStatus'),
          {
            variant: SNACKBAR_VARIANTS.warning,
          },
        ),
      );
      return;
    }

    const statusItem = INVOICES_STATUSES_OPTIONS.find(
      (invoiceStatus) => invoiceStatus.value === statusValue,
    );
    const newStatusChanging = {
      statusItem,
      invoiceUuid: uuid,
      accountUuid: accountUUID,
      selected,
    };
    if (selected.length > 1) {
      // show confirmation dialog
      setStatusChanging(newStatusChanging);
      setOpenStatusDialog(true);
    } else {
      // change status for single invoice
      setStatusChanging({
        ...newStatusChanging,
        preparedList: [{ accountUuid: accountUUID, target: [uuid] }],
      });
    }
  };

  const handleChangeCurrentStatus = () => {
    const { invoiceUuid, accountUuid } = statusChanging;
    setStatusChanging({
      ...statusChanging,
      preparedList: [{ accountUuid, target: [invoiceUuid] }],
      pressedSelectedButton: false,
    });
  };

  const handleChangeSelectedStatus = () => {
    const { selected } = statusChanging;

    // transform all the selected items to { [accountUuid]: [invoiceUuid, ...] }
    const selectedItems = {};
    selected.forEach((invoiceUuid) => {
      const { uuid, accountUUID } = items.find(
        (item) => item.uuid === invoiceUuid,
      );
      if (selectedItems[accountUUID]) {
        selectedItems[accountUUID].push(uuid);
      } else {
        selectedItems[accountUUID] = [uuid];
      }
    });

    // split by MAX param => [{ accountUuid, invoices: [invoiceUuid, ...] }]
    const preparedList = [];
    Object.entries(selectedItems).forEach(([key, value]) => {
      if (value.length < MAX_INVOICES) {
        preparedList.push({ accountUuid: key, target: value });
      } else {
        const iterates = Math.floor(value.length / MAX_INVOICES);
        const rest = value.length % MAX_INVOICES;
        for (let i = 0; i < iterates; i += 1) {
          preparedList.push({
            accountUuid: key,
            target: value.slice(MAX_INVOICES * i, MAX_INVOICES * (i + 1)),
          });
        }
        if (rest) {
          preparedList.push({
            accountUuid: key,
            target: value.slice(value.length - rest),
          });
        }
      }
    });
    setStatusChanging({
      ...statusChanging,
      preparedList,
      pressedSelectedButton: true,
    });
  };

  const handleStatusSuccess = () => {
    reloadData();
    handleCloseStatusDialog();
  };

  const { isLoading: isStatusLoading } = useStatusChange({
    name: 'invoice',
    statusChanging,
    onSuccess: handleStatusSuccess,
    onError: handleCloseStatusDialog,
  });

  const getOptions = () => {
    return INVOICES_STATUSES_TABLE_OPTIONS.map((invoiceStatus) => ({
      ...invoiceStatus,
      onActionClick: handleStatusChange,
    }));
  };

  const reloadInvoiceList = (hasFilter) => {
    if (!hasFilter) {
      clearFilter();
    } else {
      reloadData({ delay: NULL_QUERY_DELAY });
    }
  };

  const patientFirstName = get(patientInfo, 'firstName', '');
  const patientLastName = get(patientInfo, 'lastName', '');
  const titleText = isPatientPage
    ? (patientInfo && `${patientLastName}, ${patientFirstName}`) ||
      FAKE_TITLE_DATA
    : t('titles:invoices');

  const isTableDataLoading = dataLoading || !modifiedInvoices;
  const FAKE_INVOICE_LIST = getFakeDataList(FAKE_INVOICE);
  return (
    <>
      {isPatientPage && (
        <PatientModals
          openPatientEditModal={openPatientEditModal}
          handleClosePatientEditModal={() => {
            togglePatientEditModal(false);
          }}
          openAssessmentModal={openAssessmentModal}
          handleCloseAssessmentModal={() => toggleAssessmentModal(false)}
          openResourceModal={openResourceModal}
          handleCloseResourceModal={() => toggleResourceModal(false)}
          reloadData={() => null}
        />
      )}
      <ContentWrapper
        titleText={titleText}
        className={classes.paper}
        hasTopPaddingForSmallScreen={isPatientPage}
        underTitleText={
          isPatientPage ? (
            <PatientInfoBlock
              isForInitLoading={
                (!items && patientUuid !== paramsPatientUuid) || !patientInfo
              }
            />
          ) : (
            ''
          )
        }
        count={isPatientPage ? null : totalCount || FAKE_TITLE_DATA}
        countFiltered={filterStyle}
        actions={
          <>
            {isPatientPage ? (
              <PatientActions
                isLoading={metadataLoading}
                toggleAssessmentModal={toggleAssessmentModal}
                toggleResourceModal={toggleResourceModal}
                togglePatientEditModal={togglePatientEditModal}
                setOrderPatientUuid={setOrderPatientUuid}
              />
            ) : (
              <RefreshTableButton
                reloadData={reloadInvoiceList}
                isLoading={dataLoading}
              />
            )}
          </>
        }
        additionalNode={
          paramsPatientUuid && (
            <PatientAdditionalNode
              activeItemNumber={5}
              modifiedData={modifiedInvoices}
              totalCount={totalCount}
              reloadData={reloadInvoiceList}
              dataLoading={dataLoading}
              historyTitle={t('tables:invoicesHistory')}
            />
          )
        }
      >
        <VirtualizedTable
          tableKey={paramAccountUuid ? TABLE_KEYS.invoicesDS : TABLE_KEYS.invoices}
          hasColumnsSelect={true}
          fakeRows={FAKE_INVOICE_LIST}
          rowCount={totalCount}
          rows={modifiedInvoices}
          columns={GET_INVOICES_COLUMNS(
            isPatientPage,
            paramAccountUuid,
            totalAccountsCount,
          )}
          multiCheck
          FetchProps={{ ...restFetchProps }}
          onDataLoading={isTableDataLoading}
          currentAccountPermissions={currentAccountPermissions}
          actionProps={{
            options: getOptions(),
            optionsEntity: 'invoiceStatus',
          }}
          placeholder={
            <TablePlaceholder
              image={ordersPlaceholderImage}
              itemsNotFoundText={t('placeholders:noItemsFound', {
                item: t('titles:invoices'),
              })}
              message={t('placeholders:reviseFiltersOrCreateYourFirstItem', {
                item: t('titles:invoices'),
              })}
            />
          }
        />
        <ConfirmDialog
          open={isOpenStatusDialog}
          onClose={handleCloseStatusDialog}
          title={t('dialogs:confirmStatusChange')}
          description={t('dialogs:statusDescription', {
            status: statusChanging.statusItem
              ? statusChanging.statusItem.label
              : '',
          })}
          SelectedBtnProps={{
            onClick: handleChangeSelectedStatus,
            isLoading: statusChanging.pressedSelectedButton && isStatusLoading,
            disabled: isStatusLoading,
          }}
          CurrentBtnProps={{
            onClick: handleChangeCurrentStatus,
            isLoading: !statusChanging.pressedSelectedButton && isStatusLoading,
            disabled: isStatusLoading,
          }}
        />
      </ContentWrapper>
    </>
  );
};

export default InvoiceList;
