import React from 'react';
import { useParams } from 'react-router-dom';
import showServerError from 'helpers/showError';
import useOrderMetadata from 'hooks/useOrderMetadata';
import { useDispatch, useSelector } from 'react-redux';
import PatientActions from '../../Patients/PatientActions';
import RefreshTableButton from '../../Shared/RefreshTableButton';
import ExportFilesButton from './ExportFilesButton/ExportFilesButton';

const OrdersPatientActions = ({
                                toggleAssessmentModal,
                                toggleResourceModal,
                                togglePatientEditModal,
                                setOrderPatientUuid,
                                orderPatientUuid,
                                setReorder,
                                reorder,
                                reloadOrdersList,
                                dataLoading,
                                modifiedExportItems,
                                exportItemsLoading,
                                fetchExportItems,
                              }) => {
  const { en: { errors: tErrors, titles: tTitles } } = useSelector((state) => state.i18n);
  const dispatch = useDispatch();
  const {
    patientUuid: paramsPatientUuid,
    accountUuid: paramAccountUuid,
  } = useParams();
  const isPatientPage = Boolean(paramsPatientUuid);

  const { loading: metadataLoading } = useOrderMetadata(
    orderPatientUuid,
    reorder ? reorder.accountUUID : paramAccountUuid,
    () => {
      showServerError(dispatch, tErrors.noProvidersOnThisAccount);
      setOrderPatientUuid(null);
      setReorder(null);
    },
    {
      actionTitle: tTitles.editPatient,
      reorderUuid: reorder ? reorder.uuid : 'newOrder',
      actionCallback: () => {
        togglePatientEditModal(true);
      },
      callback: () => {
        setOrderPatientUuid(null);
        setReorder(null);
      },
    },
  );

  return <>
    {isPatientPage ? (
      <PatientActions
        isLoading={metadataLoading}
        toggleAssessmentModal={toggleAssessmentModal}
        toggleResourceModal={toggleResourceModal}
        togglePatientEditModal={togglePatientEditModal}
        setOrderPatientUuid={setOrderPatientUuid}
      />
    ) : (
      <>
        <RefreshTableButton
          reloadData={reloadOrdersList}
          isLoading={dataLoading}
        />
        <ExportFilesButton
          CSVData={modifiedExportItems}
          CSVDataLoading={exportItemsLoading}
          fetchCSVData={fetchExportItems}
        />
      </>
    )}
  </>;
};

export default OrdersPatientActions;
