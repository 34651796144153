import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CUSTOM_TAGS_OPTIONS } from '../../../Shared/CustomTags';
import Select from '../../../FormikFields/ComplexSelect/Select';

const CustomTagsSelector = ({
                              form,
                              values,
                              backendErrors,
                            }) => {
  const { t } = useTranslation(['forms']);
  const dataKey = 'customTags';

  useEffect(() => {
    if (values.customTags.length === 0) {
      form.setFieldValue(dataKey, ['Other']);
    }
  }, [values.customTags]);

  return (
    <Select
      multiSelect
      disabled={false}
      customItemsPerPage={100}
      dataKey={dataKey}
      filterValue={values && values.customTags}
      columnData={{
        isFilterable: false,
        options: CUSTOM_TAGS_OPTIONS,
      }}
      onChange={(dataKey, listOfCheckedItem) => {
        if (listOfCheckedItem.length === 0) {
          form.setFieldValue(dataKey, ['Other']);
        } else {
          form.setFieldValue(dataKey, listOfCheckedItem);
        }
      }}
      label={t('tables:customTags')}
      labelWidth={78}
      hasMenuPagination
      hasOptionalCheckBox={false}
      loading={false}
      error={backendErrors[dataKey]}
    />
  );
};

export default CustomTagsSelector;
