import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Divider,
  Typography,
  makeStyles,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Form, Field } from 'formik';
import AddressForm from 'components/Accounts/AddressForm';
import { useSelector } from 'react-redux';
import FeaturesSection from './widgets/FeaturesSection/FeaturesSection';
import BasicInfoSection from './widgets/BasicInfoSection';
import ScheduledActivitiesSection from './widgets/ScheduledActivitiesSection';
import OtherAddressesSection from './widgets/OtherAddressesSection';
import { checkPermission } from '../../../helpers/checkPermissions';
import permissions from '../../../config/permissions';
import CustomEmailSection from './widgets/CustomEmailSection/CustonEmailSection';
import cx from 'classnames';
import CustomTagsSelector from './widgets/CustomTags';

const useStyles = makeStyles((theme) => ({
  title: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(1),
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
  checkbox: {
    marginLeft: theme.spacing(0.5),
  },
  divider: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
  dataContainer: {
    maxWidth: 800,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const AccountForm = ({
                       values,
                       backendErrors,
                       isEditMode,
                       validateForm,
                       accountUuid,
                       setAccount,
                     }) => {
  const classes = useStyles();
  const { t } = useTranslation(['forms']);
  const {
    metadata: { currentAccount, currentAccountPermissions },
  } = useSelector((state) => state.user);

  const isCurrentAccountEditing = currentAccount.uuid === accountUuid;
  const isDistributionSponsor = checkPermission(
    currentAccountPermissions,
    permissions.ACCOUNTS_GET,
  );

  return (
    <Form>
      <Grid container direction='column' alignItems='flex-start'>
        <Grid container className={classes.dataContainer}>
          <Grid
            item
            xs={12}
            md={9}
            container
          >
            <Typography className={classes.title}>
              {t('forms:accountType')}:
            </Typography>
            <Field name='customTags' type='selector'>
              {({ form }) => {
                return <CustomTagsSelector
                  values={values}
                  backendErrors={backendErrors}
                  form={form}
                />;
              }}
            </Field>
          </Grid>
        </Grid>

        <Divider className={classes.divider} />

        <BasicInfoSection
          values={values}
          backendErrors={backendErrors}
          isEditMode={isEditMode}
          isCurrentAccountEditing={isCurrentAccountEditing}
          isDistributionSponsor={isDistributionSponsor}
        />

        <Divider className={classes.divider} />

        <ScheduledActivitiesSection
          scheduleDeliverTime={values.scheduleDeliverTime}
        />

        <Divider className={classes.divider} />

        <Typography className={classes.title}>
          {t('forms:organizationAddress')}:
        </Typography>
        <AddressForm
          prefix='organizationAddress'
          backendErrors={backendErrors}
        />

        <Divider className={classes.divider} />

        <Typography className={classes.title}>
          {t('forms:shippingAddress')}:
        </Typography>
        <Field name='isShippingTheSame' type='checkbox'>
          {({ field, form }) => (
            <FormControlLabel
              control={
                <Checkbox
                  name={field.name}
                  checked={field.value}
                  color='primary'
                  onChange={(e, checked) => {
                    form.setFieldValue(field.name, checked);
                  }}
                />
              }
              className={classes.checkbox}
              label={t('forms:useSameAddress')}
            />
          )}
        </Field>
        <AddressForm
          prefix='shippingAddress'
          backendErrors={values.isShippingTheSame ? undefined : backendErrors}
          disabled={values.isShippingTheSame}
        />

        <Divider className={classes.divider} />

        <OtherAddressesSection
          otherAddresses={values.otherAddresses}
          backendErrors={backendErrors}
          validateForm={validateForm}
        />
        <FeaturesSection
          disableAssessments={values.disableAssessments}
          disableOrders={values.disableOrders}
          allowedSurveys={values.allowedSurveys}
          disableResources={values.disableResourcesAll}
          allowedResources={values.allowedResources}
          isCurrentAccountEditing={isCurrentAccountEditing}
        />

        <Divider className={cx(classes.divider, classes.marginTop)} />

        <Field name='customEmailSection' type='checkbox'>
          {({ form }) => (
            <CustomEmailSection
              backendErrors={backendErrors}
              form={form}
              isDistributionSponsor={isDistributionSponsor}
              setAccount={setAccount}
            />
          )}
        </Field>
      </Grid>
    </Form>
  );
};

AccountForm.propTypes = {
  values: PropTypes.shape({
    otherAddresses: PropTypes.array,
    isShippingTheSame: PropTypes.bool,
    tradeName: PropTypes.string,
    businessName: PropTypes.string,
    scheduleDeliverTime: PropTypes.string,
  }).isRequired,
  backendErrors: PropTypes.shape({
    businessName: PropTypes.string,
    tradeName: PropTypes.string,
    phone: PropTypes.string,
    fax: PropTypes.string,
    contactName: PropTypes.string,
    headProviderName: PropTypes.string,
    customIdentifier: PropTypes.string,
  }),
  isEditMode: PropTypes.bool,
  validateForm: PropTypes.func.isRequired,
  accountUuid: PropTypes.string.isRequired,
};

AccountForm.defaultProps = {
  backendErrors: undefined,
  isEditMode: false,
};

export default AccountForm;
