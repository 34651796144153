import downloadPDF from '../../helpers/DownloadPDF';

const downloadBillingPDF = async (e, bill) => {
  const link = `/accounts/${bill.accountUUID}/billings/${bill.uuid}/billing-report/pdf`;
  await downloadPDF(link);
};

export default downloadBillingPDF;

export const downloadFile = (blob, fileName) => {
  const link = document.createElement('a');
  // create a blobURI pointing to our Blob
  link.href = URL.createObjectURL(blob);
  link.download = fileName;
  // some browser needs the anchor to be in the doc
  document.body.append(link);
  link.click();
  link.remove();
  // in case the Blob uses a lot of memory
  setTimeout(() => URL.revokeObjectURL(link.href), 7000);
};
