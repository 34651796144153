const permissions = {
  // User
  USER_GET: window.BigInt(1) << window.BigInt(0),
  USER_PATCH: window.BigInt(1) << window.BigInt(1),
  // Accounts
  ACCOUNT_GET: window.BigInt(1) << window.BigInt(2),
  ACCOUNT_PATCH: window.BigInt(1) << window.BigInt(3),
  ACCOUNT_DELETE: window.BigInt(1) << window.BigInt(4),
  ACCOUNT_CREATE: window.BigInt(1) << window.BigInt(5),
  ACCOUNTS_GET: window.BigInt(1) << window.BigInt(6),
  // Account-Users
  ACCOUNT_USER_GET: window.BigInt(1) << window.BigInt(7),
  ACCOUNT_USER_PATCH: window.BigInt(1) << window.BigInt(8),
  ACCOUNT_USER_DELETE: window.BigInt(1) << window.BigInt(9),
  ACCOUNT_USER_CREATE: window.BigInt(1) << window.BigInt(10),
  ACCOUNT_USERS_GET: window.BigInt(1) << window.BigInt(11),
  // Patients
  PATIENT_GET: window.BigInt(1) << window.BigInt(12),
  PATIENT_PATCH: window.BigInt(1) << window.BigInt(13),
  PATIENT_DELETE: window.BigInt(1) << window.BigInt(14),
  PATIENT_CREATE: window.BigInt(1) << window.BigInt(15),
  PATIENTS_GET: window.BigInt(1) << window.BigInt(16),
  // Orders
  ORDER_GET: window.BigInt(1) << window.BigInt(17),
  ORDER_PATCH: window.BigInt(1) << window.BigInt(18),
  ORDER_DELETE: window.BigInt(1) << window.BigInt(19),
  ORDER_CREATE: window.BigInt(1) << window.BigInt(20),
  ORDERS_GET: window.BigInt(1) << window.BigInt(21),
  ORDER_SURVEY_CREATE: window.BigInt(1) << window.BigInt(24),
  // Dashboard
  DASHBOARD_GET: window.BigInt(1) << window.BigInt(15),
  // Assessment
  SURVEY_ACCEPT_RESULTS: window.BigInt(1) << window.BigInt(28),
  SURVEY_ACCEPT_RESULTS_SPECIFY_PROVIDER: window.BigInt(1) << window.BigInt(33),

  ACCOUNT_PATCH_DISABLE_ORDERS: window.BigInt(1) << window.BigInt(29),
  ACCOUNT_PATCH_DISABLE_ASSESSMENTS: window.BigInt(1) << window.BigInt(30),
  ACCOUNT_GET_ALLERGIEND_RESOURCES: window.BigInt(1) << window.BigInt(31),
  ACCOUNT_PATCH_ALLERGIEND_RESOURCES: window.BigInt(1) << window.BigInt(32),
  ACCOUNT_PATCH_RESOURCE_ALL: window.BigInt(1) << window.BigInt(44),
  ACCOUNT_PATCH_ENABLE_CLAIM_MANAGEMENT: window.BigInt(1) << window.BigInt(45),
  ACCOUNT_PATCH_RESOURCES: window.BigInt(1) << window.BigInt(38),

  // Resources
  GET_RESOURCES: window.BigInt(1) << window.BigInt(34),
  CREATE_RESOURCES: window.BigInt(1) << window.BigInt(35),
  DELETE_RESOURCES: window.BigInt(1) << window.BigInt(36),

  // Claims
  ADMIN_PATIENT_IMPORT: window.BigInt(1) << window.BigInt(37),
  CLAIM_GET: window.BigInt(1) << window.BigInt(39),
  CLAIM_PATCH: window.BigInt(1) << window.BigInt(40),
  CLAIMS_GET: window.BigInt(1) << window.BigInt(41),

  // Invoices
  INVOICE_GET: window.BigInt(1) << window.BigInt(46),
  INVOICE_PATCH: window.BigInt(1) << window.BigInt(47),
  INVOICES_GET: window.BigInt(1) << window.BigInt(48),

  SURVEYS_GET: window.BigInt(1) << window.BigInt(22),
};

export default permissions;
