import getDigitPhone from '../../helpers/phone';
import { ACCOUNT_TYPES } from './constants';

export const removeUnnecessaryProps = (values) => {
  delete values.isShippingTheSame;
  delete values.isDistributionSponsor;
  delete values.disableAllergiendResources;
  delete values.disableOrdersAutomations;
  delete values.disableResourcesAll;
  delete values.disableAssessments;
  delete values.disableOrders;
  delete values.enableClaimsManagement;

  delete values.emailSurveyInvitationEnglish;
  delete values.emailSurveyInvitationSpanish;
  delete values.emailResourceInvitationEnglish;
  delete values.emailResourceInvitationSpanish;
  delete values.emailSurveyReminderEnglish;
  delete values.emailSurveyReminderSpanish;
  delete values.emailResourceReminderEnglish;
  delete values.emailResourceReminderSpanish;
  delete values.emailSurveyCompletionEnglish;
  delete values.emailSurveyCompletionSpanish;

  delete values.smsSurveyInvitationEnglish;
  delete values.smsSurveyInvitationSpanish;
  delete values.smsResourceInvitationEnglish;
  delete values.smsResourceInvitationSpanish;
  delete values.smsSurveyReminderEnglish;
  delete values.smsSurveyReminderSpanish;
  delete values.smsResourceReminderEnglish;
  delete values.smsResourceReminderSpanish;
  delete values.smsSurveyCompletionEnglish;
  delete values.smsSurveyCompletionSpanish;

  delete values.emailSurveyInvitationIsDefault;
  delete values.emailResourceInvitationIsDefault;
  delete values.smsSurveyInvitationIsDefault;
  delete values.smsResourceInvitationIsDefault;
  delete values.emailSurveyReminderIsDefault;
  delete values.emailResourceReminderIsDefault;
  delete values.smsSurveyReminderIsDefault;
  delete values.smsResourceReminderIsDefault;
  delete values.emailSurveyCompletionIsDefault;
  delete values.smsSurveyCompletionIsDefault;
  delete values.loading;

  return values;
};

export const formatSmsOrEmailValues = (typeKey, initValues, values) => {
  let formattedValues = JSON.parse(JSON.stringify(values));

  Object.keys(formattedValues[typeKey]).forEach(function(
    key,
  ) {
    const value = initValues && initValues[typeKey] && initValues[typeKey][key];
    const newValue = formattedValues[typeKey][key];

    const isNewDefault = newValue && newValue[0]?.isDefault;

    if (value && value.length > 0) {
      if (isNewDefault) {
        formattedValues[typeKey][key][0].text = value[0].text;
        formattedValues[typeKey][key][1].text = value[1].text;
      }
    } else if (value && value.length === 0 || !value) {
      if (isNewDefault) {
        delete formattedValues[typeKey][key][0].text;
        delete formattedValues[typeKey][key][1].text;
      }
    }
  });
  return formattedValues;
};

export const formattedAccountsValues = (values, checkAccountType, initValues) => {
  let formattedValues = {
    ...values,
    phone: getDigitPhone(values.phone),
    fax: getDigitPhone(values.fax),
    emailConfiguration: {
      surveyInvitation: [
        {
          local: 'english',
          text: values.emailSurveyInvitationEnglish,
          isDefault: values.emailSurveyInvitationIsDefault,
        },
        {
          local: 'spanish',
          text: values.emailSurveyInvitationSpanish,
          isDefault: values.emailSurveyInvitationIsDefault,
        },
      ],
      surveyReminder: [
        {
          local: 'english',
          text: values.emailSurveyReminderEnglish,
          isDefault: values.emailSurveyReminderIsDefault,
        },
        {
          local: 'spanish',
          text: values.emailSurveyReminderSpanish,
          isDefault: values.emailSurveyReminderIsDefault,
        },
      ],
      surveyCompletion: [
        {
          local: 'english',
          text: values.emailSurveyCompletionEnglish,
          isDefault: values.emailSurveyCompletionIsDefault,
        },
        {
          local: 'spanish',
          text: values.emailSurveyCompletionSpanish,
          isDefault: values.emailSurveyCompletionIsDefault,
        },
      ],
      resourceInvitation: [
        {
          local: 'english',
          text: values.emailResourceInvitationEnglish,
          isDefault: values.emailResourceInvitationIsDefault,
        },
        {
          local: 'spanish',
          text: values.emailResourceInvitationSpanish,
          isDefault: values.emailResourceInvitationIsDefault,
        },
      ],
      resourceReminder: [
        {
          local: 'english',
          text: values.emailResourceReminderEnglish,
          isDefault: values.emailResourceReminderIsDefault,
        },
        {
          local: 'spanish',
          text: values.emailResourceReminderSpanish,
          isDefault: values.emailResourceReminderIsDefault,
        },
      ],
    },
    smsConfiguration: {
      surveyInvitation: [
        {
          local: 'english',
          text: values.smsSurveyInvitationEnglish,
          isDefault: values.smsSurveyInvitationIsDefault,
        },
        {
          local: 'spanish',
          text: values.smsSurveyInvitationSpanish,
          isDefault: values.smsSurveyInvitationIsDefault,
        },
      ],
      surveyReminder: [
        {
          local: 'english',
          text: values.smsSurveyReminderEnglish,
          isDefault: values.smsSurveyReminderIsDefault,
        },
        {
          local: 'spanish',
          text: values.smsSurveyReminderSpanish,
          isDefault: values.smsSurveyReminderIsDefault,
        },
      ],
      surveyCompletion: [
        {
          local: 'english',
          text: values.smsSurveyCompletionEnglish,
          isDefault: values.smsSurveyCompletionIsDefault,
        },
        {
          local: 'spanish',
          text: values.smsSurveyCompletionSpanish,
          isDefault: values.smsSurveyCompletionIsDefault,
        },
      ],
      resourceInvitation: [
        {
          local: 'english',
          text: values.smsResourceInvitationEnglish,
          isDefault: values.smsResourceInvitationIsDefault,
        },
        {
          local: 'spanish',
          text: values.smsResourceInvitationSpanish,
          isDefault: values.smsResourceInvitationIsDefault,
        },
      ],
      resourceReminder: [
        {
          local: 'english',
          text: values.smsResourceReminderEnglish,
          isDefault: values.smsResourceReminderIsDefault,
        },
        {
          local: 'spanish',
          text: values.smsResourceReminderSpanish,
          isDefault: values.smsResourceReminderIsDefault,
        },
      ],
    },
  };

  if (checkAccountType) {
    formattedValues.accountType = values.isDistributionSponsor
      ? ACCOUNT_TYPES.DISTRIBUTION_SPONSOR
      : ACCOUNT_TYPES.STANDARD;
  }

  if (values.isShippingTheSame) {
    formattedValues.shippingAddress = {
      ...formattedValues.organizationAddress,
    };
  }

  formattedValues.accountPermissions = {
    disableAllergiendResources: formattedValues.disableAllergiendResources,
    disableOrdersAutomations: formattedValues.disableOrdersAutomations,
    disableResourcesAll: formattedValues.disableResourcesAll,
    disableAssessments: formattedValues.disableAssessments,
    disableOrders: formattedValues.disableOrders,
    enableClaimsManagement: formattedValues.enableClaimsManagement,
  };

  formattedValues = removeUnnecessaryProps(formattedValues);
  formattedValues = formatSmsOrEmailValues('emailConfiguration', initValues, formattedValues);
  formattedValues = formatSmsOrEmailValues('smsConfiguration', initValues, formattedValues);

  return formattedValues;
};
