import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { useMutation, useQuery } from 'react-fetching-library';
import { conformToMask } from 'react-text-mask';
import { compare } from 'fast-json-patch';
import isEqual from 'lodash/isEqual';
import ContentWrapper from 'components/Wrappers/ContentWrapper';
import RoundedButton from 'components/Shared/RoundedButton';
import showServerError from 'helpers/showError';
import AccountForm from 'components/Accounts/AccountForm/AccountForm';
import { MASK } from 'components/Shared/FormattedPhone';
import Loader from 'components/Shared/Loader';
import { ACCOUNT_TYPES } from 'components/Accounts/constants';
import useMetadataUpdate from 'hooks/useMetadataUpdate';
import BackButton from 'components/Shared/BackButton';
import { useDispatch, useSelector } from 'react-redux';
import {
  enqueueSnackbar,
  SNACKBAR_VARIANTS,
} from '../../redux_store/reducer/reducers/notificationsReducer';
import { setMetadata } from '../../redux_store/reducer/reducers/userReducer/userReducer';
import { formattedAccountsValues } from './helpers';

const AccountEdit = () => {
  const dispatch = useDispatch();
  const { accountUuid } = useParams();
  const { t } = useTranslation(['btn', 'errors', 'notifications']);
  const [backendErrors, setBackendErrors] = useState({});
  const [account, setAccount] = useState();
  const { user, metadata } = useSelector((state) => state.user);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailed, setIsFailed] = useState(false);

  const { query } = useMetadataUpdate(useQuery, [
    {
      method: 'GET',
      endpoint: `/accounts/${accountUuid}`,
    },
    false,
  ]);

  useEffect(() => {
    query().then(({ error, payload }) => {
      if (!error) {
        setAccount(payload);
      }
    });
  }, [query]);

  const accountEditAction = (patches) => ({
    method: 'PATCH',
    endpoint: `/accounts/${accountUuid}`,
    body: patches,
  });
  const { loading, mutate } = useMetadataUpdate(useMutation, [
    accountEditAction,
  ]);

  const editAccount = async (values, { setSubmitting }) => {
    let accountCopy = JSON.parse(JSON.stringify(account));
    let formattedValues = formattedAccountsValues(JSON.parse(JSON.stringify(values)), false, accountCopy);
    delete formattedValues.otherAddresses; // will be patched manually
    delete accountCopy.otherAddresses;

    if (formattedValues && formattedValues.accountPermissions && formattedValues.accountPermissions.disableOrders && accountCopy && accountCopy.accountPermissions && accountCopy.accountPermissions.hasOwnProperty('disableOrdersAutomations')) {
      formattedValues.accountPermissions.disableOrdersAutomations = accountCopy.accountPermissions.disableOrdersAutomations;
    }

    // compare all the values except deleted ones
    const patches = compare(accountCopy, formattedValues);
    // create a patch for an array manually

    if (!isEqual(account.otherAddresses, values.otherAddresses)) {
      patches.push({
        op: 'replace',
        path: '/otherAddresses',
        value: values.otherAddresses,
      });
    }
    const wasBusinessNameChanged = !isEqual(
      account.businessName,
      values.businessName,
    );

    setBackendErrors({});

    const { payload, error, status } = await mutate(patches);

    if (error) {
      setIsFailed(true);
      setTimeout(() => {
        setIsFailed(false);
      }, 2000);
      const options = payload && {
        correlationUUID: payload.correlationUUID,
        userUUID: user.uuid,
      };
      switch (status) {
        case 422: {
          showServerError(dispatch, t('errors:validationError'));
          setBackendErrors(payload.validationErrors);
          break;
        }
        default: {
          showServerError(dispatch, '', options);
          break;
        }
      }
    } else if (payload) {
      if (wasBusinessNameChanged) {
        const newAccounts = [...metadata.accounts].map((account) => {
          if (account.uuid === accountUuid) {
            return {
              ...account,
              businessName: values.businessName,
            };
          }
          return account;
        });
        dispatch(
          setMetadata({
            ...metadata,
            accounts: newAccounts,
          }),
        );
      }
      setIsSuccess(true);
      setTimeout(() => {
        setIsSuccess(false);
      }, 2000);

      let newEmailConfiguration = {};
      let newSmsConfiguration = {};

      Object.keys(payload.emailConfiguration).forEach(function(key) {
        if (payload.emailConfiguration[key] && payload.emailConfiguration[key].length > 0) {
          newEmailConfiguration[key] = payload.emailConfiguration[key];
        } else if (account.emailConfiguration[key] && account.emailConfiguration[key].length > 0) {
          newEmailConfiguration[key] = account.emailConfiguration[key];
        } else {
          newEmailConfiguration[key] = [];
        }
      });
      Object.keys(payload.smsConfiguration).forEach(function(key) {
        if (payload.smsConfiguration[key] && payload.smsConfiguration[key].length > 0) {
          newSmsConfiguration[key] = payload.smsConfiguration[key];
        } else if (account.smsConfiguration[key] && account.smsConfiguration[key].length > 0) {
          newSmsConfiguration[key] = account.smsConfiguration[key];
        } else {
          newSmsConfiguration[key] = [];
        }
      });

      const newPayload = {
        ...payload,
        emailConfiguration: newEmailConfiguration,
        smsConfiguration: newSmsConfiguration,
      };

      setAccount(newPayload);
      dispatch(
        enqueueSnackbar({
          message: t('notifications:accountEdited', {
            name: formattedValues.businessName,
          }),
          options: {
            variant: SNACKBAR_VARIANTS.success,
          },
        }),
      );
    }
    setSubmitting(false);
  };

  const checkIsDefault = (firstKey, secondKey) => {
    return account?.[firstKey]?.[secondKey]?.find((e) => e.local === 'english')?.isDefault ||
      account?.[firstKey]?.[secondKey]?.find((e) => e.local === 'spanish')?.isDefault ||
      account?.[firstKey]?.[secondKey].length === 0;
  };

  const findEmailOrSmsText = (firstKey, secondKey, local) => {
    return account?.[firstKey]?.[secondKey]?.find(
      (e) => e.local === local,
    )?.text;
  };

  return account ? (
    <Formik
      initialValues={{
        ...account,
        phone: conformToMask(account.phone, MASK).conformedValue,
        fax: conformToMask(account.fax, MASK).conformedValue,
        ianaTimeZone: account.ianaTimeZone || 'America/New_York',
        isShippingTheSame: isEqual(
          account.organizationAddress,
          account.shippingAddress,
        ),
        isDistributionSponsor:
          account.accountType === ACCOUNT_TYPES.DISTRIBUTION_SPONSOR,
        disableResourcesAll: account.accountPermissions.disableResourcesAll,
        disableAllergiendResources:
        account.accountPermissions.disableAllergiendResources,
        disableOrdersAutomations:
        account.accountPermissions.disableOrdersAutomations,
        disableAssessments: account.accountPermissions.disableAssessments,
        disableOrders: account.accountPermissions.disableOrders,
        enableClaimsManagement:
        account.accountPermissions.enableClaimsManagement,
        allowedSurveys: account.allowedSurveys || [],
        allowedResources: account.allowedResources || [],
        customTags: account.customTags,

        emailSurveyInvitationEnglish: findEmailOrSmsText('emailConfiguration', 'surveyInvitation', 'english'),
        emailSurveyInvitationSpanish: findEmailOrSmsText('emailConfiguration', 'surveyInvitation', 'spanish'),
        emailSurveyInvitationIsDefault: checkIsDefault('emailConfiguration', 'surveyInvitation'),
        emailResourceInvitationEnglish: findEmailOrSmsText('emailConfiguration', 'resourceInvitation', 'english'),
        emailResourceInvitationSpanish: findEmailOrSmsText('emailConfiguration', 'resourceInvitation', 'spanish'),
        emailResourceInvitationIsDefault: checkIsDefault('emailConfiguration', 'resourceInvitation'),
        emailSurveyReminderEnglish: findEmailOrSmsText('emailConfiguration', 'surveyReminder', 'english'),
        emailSurveyReminderSpanish: findEmailOrSmsText('emailConfiguration', 'surveyReminder', 'spanish'),
        emailSurveyReminderIsDefault: checkIsDefault('emailConfiguration', 'surveyReminder'),
        emailResourceReminderEnglish: findEmailOrSmsText('emailConfiguration', 'resourceReminder', 'english'),
        emailResourceReminderSpanish: findEmailOrSmsText('emailConfiguration', 'resourceReminder', 'spanish'),
        emailResourceReminderIsDefault: checkIsDefault('emailConfiguration', 'resourceReminder'),
        emailSurveyCompletionEnglish: findEmailOrSmsText('emailConfiguration', 'surveyCompletion', 'english'),
        emailSurveyCompletionSpanish: findEmailOrSmsText('emailConfiguration', 'surveyCompletion', 'spanish'),
        emailSurveyCompletionIsDefault: checkIsDefault('emailConfiguration', 'surveyCompletion'),

        smsSurveyInvitationEnglish: findEmailOrSmsText('smsConfiguration', 'surveyInvitation', 'english'),
        smsSurveyInvitationSpanish: findEmailOrSmsText('smsConfiguration', 'surveyInvitation', 'spanish'),
        smsSurveyInvitationIsDefault: checkIsDefault('smsConfiguration', 'surveyInvitation'),
        smsResourceInvitationEnglish: findEmailOrSmsText('smsConfiguration', 'resourceInvitation', 'english'),
        smsResourceInvitationSpanish: findEmailOrSmsText('smsConfiguration', 'resourceInvitation', 'spanish'),
        smsResourceInvitationIsDefault: checkIsDefault('smsConfiguration', 'resourceInvitation'),
        smsSurveyReminderEnglish: findEmailOrSmsText('smsConfiguration', 'surveyReminder', 'english'),
        smsSurveyReminderSpanish: findEmailOrSmsText('smsConfiguration', 'surveyReminder', 'spanish'),
        smsSurveyReminderIsDefault: checkIsDefault('smsConfiguration', 'surveyReminder'),
        smsResourceReminderEnglish: findEmailOrSmsText('smsConfiguration', 'resourceReminder', 'english'),
        smsResourceReminderSpanish: findEmailOrSmsText('smsConfiguration', 'resourceReminder', 'spanish'),
        smsResourceReminderIsDefault: checkIsDefault('smsConfiguration', 'resourceReminder'),
        smsSurveyCompletionEnglish: findEmailOrSmsText('smsConfiguration', 'surveyCompletion', 'english'),
        smsSurveyCompletionSpanish: findEmailOrSmsText('smsConfiguration', 'surveyCompletion', 'spanish'),
        smsSurveyCompletionIsDefault: checkIsDefault('smsConfiguration', 'surveyCompletion'),
        loading: 'Loading...',
      }}
      validateOnBlur={false}
      enableReinitialize
      onSubmit={editAccount}
    >
      {({
          values,
          isSubmitting,
          handleSubmit,
          validateForm,
        }) => (
        <ContentWrapper
          titleText={t('titles:editAccount')}
          scrollBarSupport
          actions={
            <>
              <BackButton />
              <RoundedButton
                type='submit'
                variant='contained'
                color='primary'
                size='small'
                disabled={
                  isSubmitting ||
                  values.scheduleDeliverTime === 'invalid_value'
                }
                onClick={handleSubmit}
                isLoading={loading}
                isSuccess={isSuccess}
                isFailed={isFailed}
              >
                {t('btn:save')}
              </RoundedButton>
            </>
          }
        >
          <AccountForm
            values={values}
            backendErrors={backendErrors}
            validateForm={validateForm}
            isEditMode
            accountUuid={accountUuid}
            setAccount={setAccount}
          />
        </ContentWrapper>
      )}
    </Formik>
  ) : (
    <ContentWrapper>
      <Loader fullHeight />
    </ContentWrapper>
  );
};

export default AccountEdit;
