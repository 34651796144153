import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  fade,
  makeStyles,
  TableCell,
  Checkbox,
  Grid,
  Chip,
} from '@material-ui/core';
import {
  AutoSizer,
  Column,
  Table,
  InfiniteLoader,
  SortDirection,
  SortIndicator,
} from 'react-virtualized';
import cx from 'classnames';
import TableCellMore from 'components/Table/TableCellMore';
import FilterBox from 'components/Filters/FilterBox';
import getStatusBadge, { getTagsColor } from 'helpers/getStatusBadge';
import { Badge } from 'components/Shared/Badge';
import CellPlaceholder from 'components/Table/CellPlaceholder';
import { checkPermission } from 'helpers/checkPermissions';
import Loader from 'components/Shared/Loader';
import { NEW_LINE_MARKER, renderLocalizedData } from 'helpers/localize';
import useDeepCompareMemo from 'hooks/useDeepCompareMemo';
import useDeepCompareEffect from 'hooks/useDeepCompareEffect';
import { camelCase } from 'lodash/string';
import { RESOURCE_ICON_LIST_FOR_TABLE } from '../Resources/constants';
import Skeleton from '@material-ui/lab/Skeleton';
import BlurScreen from '../Shared/blur_screen';
import Select from '../FormikFields/ComplexSelect/Select';
import { useTranslation } from 'react-i18next';
import SettingsIcon from '@material-ui/icons/Settings';
import CellSimpleText from './CellSimpleText';
import FILTER_TYPES from '../Filters/constants';
import {
  getTablesFromLocalStorage,
  setTablesHiddenColumnByKey,
} from '../../redux_store/reducer/reducers/tableColumnsReducer';
import { useDispatch, useSelector } from 'react-redux';

const CHECKBOX_WIDTH = 42;
const MORE_WIDTH = 72;
const FILTER_WIDTH = 56;

const HEADER_HEIGHT = 79;

const CELL_DATA_HEIGHT = 20;
const CELL_PADDING_HEIGHT = 32;

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    height: 'calc(100% - 16px)',
    overflowY: 'hidden',
    '--webkit-overflow-scrolling': 'touch', // possible solution for Safari chopping scrolling issue
    '--webkit-transform': 'translate3d(0, 0, 0)', // founded here https://stackoverflow.com/questions/24156709/choppy-scrolling-in-safari
    overflowX: 'auto',
  },
  tableOverflow: {
    overflowX: 'hidden',
    position: 'relative',
    height: '100%',
  },
  header: {
    padding: theme.spacing(1, 2),
  },
  headerTitle: {
    color: fade(theme.palette.common.black, 0.5),
    height: 32,
  },
  headerTitleWithoutSorting: {
    cursor: 'default',
  },
  headerFilterContainer: {
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  row: {
    borderRadius: theme.shape.borderRadius,
    cursor: 'pointer',
  },
  rowHover: {
    '&:hover': {
      backgroundColor: theme.palette.grey[200],
    },
  },
  dataCell: {
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: '100%',

    '& div': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
  },
  flexContainer: {
    display: 'flex',
    boxSizing: 'border-box',
  },
  flexFiller: {
    flex: '1 1 72px !important',
    justifyContent: 'flex-end',
    display: 'flex',
  },
  filtersAndSettings: {
    position: 'absolute',
    right: 10,
    display: 'flex',
    backgroundColor: 'white',
    top: 30,
    boxShadow: theme.shadows[3],
    alignItems: 'center',
    borderRadius: '50%',
  },
  loader: {
    display: 'flex',
    width: '100%',
    height: 'calc(100% - 30px)',
    bottom: '0px',
    opacity: 1,
    position: 'sticky',
    top: 40,
    left: 0,
    flexGrow: 0,
    flexShrink: 0,
  },
  hideLoading: {
    opacity: 0,
    height: 0,
    overflow: 'hidden',
  },
  blurLoading: {
    background: 'rgba(255, 255, 255, 0.59)',
    filter: 'blur(5px)',
    transition: 'all .5s ease',
    position: 'absolute',
    height: '100%',
    width: '100%',
  },
  showLoader: {
    opacity: 1,
    transition: 'all .5s ease',
  },
  hideLoader: {
    opacity: 0,
    transition: 'all .5s ease',
  },
  noClick: {
    cursor: 'initial',
  },
  warning: {
    backgroundColor: 'rgba(255, 255, 0, 0.28)',
    width: '100%',
  },
  noOutline: {
    outline: 'none',
  },
  noBorder: {
    borderBottom: 'none',
  },
  evenRow: {
    backgroundColor: theme.palette.grey[50],
  },
  greenRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: 8,
    paddingLeft: 10,
    color: theme.colors.green,
    '&::before': {
      width: 11,
      minWidth: 11,
      height: 11,
      content: '"."',
      marginRight: 5,
      borderRadius: '50%',
      display: 'inline-block',
      backgroundColor: theme.colors.green,
    },
  },
  redRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: 8,
    paddingLeft: 10,
    color: theme.palette.error.main,
    '&::before': {
      width: 11,
      minWidth: 11,
      height: 11,
      content: '"."',
      marginRight: 5,
      borderRadius: '50%',
      display: 'inline-block',
      backgroundColor: theme.palette.error.main,
    },
  },
  amberRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingRight: 8,
    paddingLeft: 10,
    color: theme.colors.orange,
    '&::before': {
      width: 11,
      minWidth: 11,
      height: 11,
      content: '"."',
      marginRight: 5,
      borderRadius: '50%',
      display: 'inline-block',
      backgroundColor: theme.colors.orange,
    },
  },
  checkboxHeader: {
    padding: theme.spacing(0.25, 0, 0, 0),
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  checkBoxHeaderStyle: {
    color: theme.colors.lightBlue,
  },
  checkboxCell: {
    padding: 0,
  },
  checkboxLoader: {
    marginTop: theme.spacing(1.5),
  },
  tableBody: {
    marginTop: theme.spacing(2.5),
  },
  filterHeader: {
    padding: theme.spacing(1),
  },
  headerFilter: {
    height: 32,
    justifyContent: 'center',
  },
  settingsIcon: {
    padding: theme.spacing(1),
  },
  filterButton: {
    color: theme.colors.lightBlue,
  },
  moreCell: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  rowNumber: {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.secondary.main,
  },
  selected: {
    color: theme.colors.lightBlue,
  },
  titleCount: {
    height: 21,
    fontSize: 11,
    color: theme.palette.common.white,
    backgroundColor: theme.colors.lightBlue,

    '@media print': {
      display: 'none',
    },
  },
  titleCountLabel: {
    padding: theme.spacing(0, 1),

    '@media print': {
      display: 'none',
    },
  },
  titleItem: {
    position: 'relative',
    marginLeft: 4,
    fontWeight: 500,

    '@media print': {
      display: 'none',
    },
  },
  resourceType: {
    display: 'flex',
  },
  resourceTypePadding: {
    width: 8,
  },
}));

function getTextWidth(text) {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context.font = '15px / 22.02px Roboto, Helvetica, Arial, sans-serif'

  return context.measureText(text).width + 32;
}

const VirtualizedTable = ({
                            fakeRows,
                            rowCount: _rowCount,
                            rows: _rows,
                            columns,
                            onRowClick,
                            actionProps,
                            multiCheck,
                            placeholder,
                            onDataLoading,
                            useColoredAssessments,
                            FetchProps: {
                              onLoadMoreRows,
                              sort,
                              onSort,
                              filter,
                              onFilter,
                              fetchAllItems,
                            },
                            currentAccountPermissions,
                            onEndCheckboxClick,
                            endCheckboxLabel,
                            tableKey = '',
                            hasColumnsSelect,
                            hideContextMenu,
                            ...tableProps
                          }) => {
  const tableRef = useRef();
  const classes = useStyles();
  const [memoSort, setMemoSort] = useState({});
  const [memoFilter, setMemoFilter] = useState({});
  const [selected, setSelected] = useState([]);
  const [isSelectedLoading, setSelectedLoading] = useState(false);
  const [availableRowsLength, setAvailableRowsLength] = useState();
  const { listOfHiddenColumns } = useSelector((state) => state.tableColumns);
  const dispatch = useDispatch();

  let rows = _rows ?? fakeRows;
  let rowCount = _rowCount ?? fakeRows.length;

  const { t } = useTranslation(['titles']);

  useEffect(() => {
    dispatch(getTablesFromLocalStorage());
  }, []);

  const changeTableHiddenColumn = (tableKey, newHiddenColumns) => {
    dispatch(setTablesHiddenColumnByKey({ tableKey, columnKeys: newHiddenColumns }));
  }

  const availableColumns = columns.filter(
    ({ permission, hidden }) =>
      checkPermission(currentAccountPermissions, permission) && !hidden,
  ).filter((column) => !(listOfHiddenColumns[tableKey] ?? []).includes(column.dataKey));

  const availableColumnsDataKeys = availableColumns.map(column => column.dataKey);

  // get array of row lines on rows changing only
  const getMaxRowLinesList = useDeepCompareMemo(
    () =>
      (rows).map((row) => Object.values(row).reduce((rowLines, cellValue, currentIndex) => {
        if (!(availableColumnsDataKeys ?? []).includes(Object.keys(row)[currentIndex])) {
          return 1 > rowLines ? 1 : rowLines;
        }

        if (!cellValue || !cellValue.length) {
          return rowLines;
        }
        if (Array.isArray(cellValue)
          && (Object.keys(row)[currentIndex] === 'customTags'
            || Object.keys(row)[currentIndex] === 'accountCustomTags'
            || Object.keys(row)[currentIndex] === 'surveyTags'
          )) {
          let countOfRows = 0;
          let currentRowWidth = 0;

          for (let index in cellValue) {
            if (cellValue.hasOwnProperty(index)) {
              const newItemWidth = getTextWidth(cellValue[index].toUpperCase())
              if (currentRowWidth + newItemWidth <= 170) {
                currentRowWidth += newItemWidth + 5;
              } else {
                countOfRows += 1;
                currentRowWidth = newItemWidth;
              }
            }
          }
          countOfRows += 1;

          return countOfRows * 1.5 > rowLines ? countOfRows * 1.5 : rowLines;
        }

        if (Array.isArray(cellValue)) {
          return cellValue.length > rowLines ? cellValue.length : rowLines;
        }

        const cellLines = cellValue.toString().split(NEW_LINE_MARKER).length;
        return cellLines > rowLines ? cellLines : rowLines;
      }, 1)),
    [rows, availableColumnsDataKeys],
  );

  // recalculate row heights
  useDeepCompareEffect(() => {
    if (tableRef.current) {
      tableRef.current.recomputeRowHeights();
    }
  }, [getMaxRowLinesList]);

  // set selected items when all items have been loaded
  useEffect(() => {
    let selectedRows = [];
    rows.forEach((row) => {
      if (!row.disabledCheckbox) {
        selectedRows = [...selectedRows, row.uuid];
      }
    });
    setAvailableRowsLength(selectedRows.length);

    if (isSelectedLoading && rowCount === rows.length) {
      setSelected(selectedRows);
      setSelectedLoading(false);
    }
  }, [rows.length]);

  useEffect(() => {
    if (tableRef.current && (JSON.stringify(sort) !== JSON.stringify(memoSort) || JSON.stringify(filter) !== JSON.stringify(memoFilter))) {
      tableRef.current.scrollToRow(0);
      setMemoSort(sort)
      setMemoFilter(filter)
    }
  }, [sort, filter]);

  const headerHeight = HEADER_HEIGHT;

  const getColumnsWidth = () => {
    let width = availableColumns.reduce((sym, column) => sym + column.width, 0);
    if (multiCheck) {
      width += CHECKBOX_WIDTH;
    }
    if (actionProps) {
      width += MORE_WIDTH;
    }
    return width + FILTER_WIDTH;
  };

  const columnsWidth = getColumnsWidth();

  const getRowClassName = ({ index }) =>
    cx(classes.row, classes.noOutline, classes.flexContainer, {
      [classes.rowHover]: index !== -1 && onRowClick != null && !onDataLoading,
      [classes.evenRow]: index >= 0 && index % 2 !== 1,
    });

  const handleCheckboxClick = (e, uuid) => {
    e.stopPropagation();
    const selectedIndex = selected.indexOf(uuid);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, uuid);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleCheckboxAllClick = () => {
    if (selected.length === availableRowsLength) {
      setSelected([]);
      return;
    }

    if (rows.length === rowCount) {
      let selectedRows = [];
      rows.forEach((row) => {
        if (!row.disabledCheckbox) {
          selectedRows = [...selectedRows, row.uuid];
        }
      });

      setSelected(selectedRows);
      return;
    }
    setSelectedLoading(true);
    fetchAllItems();
  };

  const handleRowClick = ({ rowData }) => {
    if (onRowClick && rowData && !onDataLoading) {
      onRowClick(rowData);
    }
  };

  const getSortDirection = (dataKey) => {
    return sort.sortBy === dataKey && sort.sortDirection === SortDirection.ASC
      ? SortDirection.DESC
      : SortDirection.ASC;
  };

  const rowGetter = ({ index }) => {
    return rows[index] || {};
  };

  const isRowLoaded = ({ index }) => {
    return !!rows[index];
  };

  const loadMoreRows = ({ stopIndex }) => {
    onLoadMoreRows(stopIndex);
  };

  const setTableRef = useCallback((node, registerChild) => {
    tableRef.current = node;
    registerChild(node);
  }, []);

  const getRowHeight = ({ index }) => {
    const maxLines = getMaxRowLinesList[index] || 1;
    let minHeight = 0;
    columns.forEach(item => {
      if (item && item.columnData && item.columnData.filterType === FILTER_TYPES.CHECKBOX) {
        minHeight = 30;
      }
    });
    return (minHeight < CELL_DATA_HEIGHT ? CELL_DATA_HEIGHT : minHeight) * maxLines + CELL_PADDING_HEIGHT;
  };

  // ----- Checkbox render
  const headerCheckboxRenderer = () => {
    return (
      <TableCell
        component='div'
        className={cx(classes.flexContainer, classes.checkboxHeader)}
        style={{ height: headerHeight }}
      >
        {isSelectedLoading ? (
          <Loader size={20} className={classes.checkboxLoader} disableShrink />
        ) : (
          <Checkbox
            disabled={availableRowsLength === 0}
            indeterminate={
              selected.length > 0 && selected.length < availableRowsLength
            }
            checked={
              availableRowsLength !== 0 &&
              selected.length === availableRowsLength
            }
            onChange={handleCheckboxAllClick}
            inputProps={{ 'aria-label': 'select all Option' }}
          />
        )}
      </TableCell>
    );
  };

  const cellCheckboxRenderer = (cellProps) => {
    const {
      rowData: { uuid, disabledCheckbox },
    } = cellProps;
    return (
      <TableCell
        component='div'
        className={cx(
          classes.flexContainer,
          classes.checkboxCell,
          classes.noBorder,
        )}
      >
        {uuid ? (
          <Checkbox
            disabled={disabledCheckbox}
            // className={classes.checkBoxHeaderStyle}
            checked={selected.includes(uuid)}
            inputProps={{ 'aria-labelledby': uuid }}
            onClick={(e) => handleCheckboxClick(e, uuid)}
          />
        ) : null}
      </TableCell>
    );
  };
  // -----

  // ----- Content render
  const headerRenderer = (headerProps) => {
    const {
      dataKey,
      label,
      columnData = {},
      disableSort,
      flexGrow,
      width,
    } = headerProps;

    return (
      <TableCell
        component='div'
        className={cx(classes.flexContainer,
          classes.headerFilterContainer,
          classes.header, {
            [classes.headerTitleWithoutSorting]: !!disableSort,
          })}
        variant='head'
        style={{
          height: headerHeight,
          maxWidth: !flexGrow ? width : null,
        }}
      >
        <Grid
          container
          alignItems='center'
          className={classes.headerTitle}
          onClick={() => {
            if (!disableSort) {
              onSort(
                sort.sortBy === dataKey &&
                sort.sortDirection === SortDirection.DESC
                  ? {}
                  : {
                    sortBy: dataKey,
                    sortDirection: getSortDirection(dataKey),
                  },
              );
            }
          }}
        >
          {label}
          {!!columnData.totalAccountsCount && (
            <Chip
              label={columnData.totalAccountsCount}
              classes={{
                root: cx(classes.titleItem, classes.titleCount),
                label: classes.titleCountLabel,
              }}
            />
          )}
          {sort.sortBy === dataKey && (
            <SortIndicator sortDirection={sort.sortDirection} />
          )}
        </Grid>
        <FilterBox
          filterValue={(filter && filter[dataKey]) || ''}
          dataKey={dataKey}
          columnData={columnData}
          onChange={onFilter}
          maxWidth={width}
        />
      </TableCell>
    );
  };

  const cellRenderer = (cellProps) => {
    const { cellData, rowData, dataKey } = cellProps;
    const { uuid, status } = rowData;
    const withIcon = dataKey === 'resourceName';
    const withBubble = dataKey === 'accountCustomTags'
      || dataKey === 'customTags'
      || dataKey === 'surveyTags';
    const isStatus = dataKey.toLowerCase().includes('status');

    let child;
    if (onDataLoading && !_rows) {
      return (
        <div
          style={{
            width: '100%',
            padding: '0 16px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              width: '100%',
            }}
          >
            <Skeleton variant='text' />
          </div>
        </div>
      );
    }

    if (isStatus) {
      child = (
        <Badge
          label={cellData}
          colorType={getStatusBadge(camelCase(cellData))}
        />
      );
    } else if (withBubble && cellData && cellData.length) {
      child = (
        <div style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 5,
        }}>
          {
            cellData.map(item =>
              <Badge
                label={item}
                colorType={getTagsColor(item)}
              />)
          }
        </div>
      );
    } else if (withIcon) {
      child = (
        <div className={classes.resourceType}>
          {RESOURCE_ICON_LIST_FOR_TABLE[rowData.resourceType]}
          <div className={classes.resourceTypePadding} />
          {renderLocalizedData(cellData ?? '-')}
        </div>
      );
    } else if (uuid) {
      child = <CellSimpleText
        value={cellData}
      />;
    } else {
      child = <CellPlaceholder />;
    }

    return (
      <TableCell
        component='div'
        className={cx(
          classes.flexContainer,
          classes.dataCell,
          classes.noBorder,
          {
            [classes.warning]: cellData === 'N/A',
            [classes.noClick]: onRowClick == null || onDataLoading,
            [classes.selected]: selected.find((id) => id === uuid),

            [classes.greenRow]:
            useColoredAssessments &&
            dataKey === 'surveyName' &&
            status === 'Invited',
            [classes.redRow]:
            useColoredAssessments &&
            dataKey === 'surveyName' &&
            status === 'Pending Review',
            [classes.amberRow]:
            useColoredAssessments &&
            dataKey === 'surveyName' &&
            status === 'In Progress',
          },
        )}
        variant='body'
      >
        {child}
      </TableCell>
    );
  };
  // -----

  const cellMoreRenderer = (cellProps, actionProps) => {
    const { rowData } = cellProps;

    return actionProps ? (
      <TableCellMore
        rowData={rowData}
        actionProps={actionProps}
        disabledRowMenu={rowData.disabledRowMenu}
        selected={selected}
        className={cx(
          classes.flexContainer,
          classes.moreCell,
          classes.noBorder,
          {
            [classes.noClick]: onRowClick == null || onDataLoading,
          },
        )}
      />
    ) : <div className={cx(
      classes.flexContainer,
      classes.moreCell,
      classes.noBorder,
      {
        [classes.noClick]: onRowClick == null || onDataLoading,
      },
    )} style={{ width: MORE_WIDTH }} />;
  };

  // -----
  const endCheckboxRenderer = (cellProps) => {
    return <div
      style={{
        width: '100%',
        paddingLeft: 30,
      }}
    >
      <Checkbox
        name={'end_checkbox'}
        checked={false}
        color='primary'
        onChange={() => {
          onEndCheckboxClick(cellProps.rowData);
        }}
      />
    </div>;
  };
  // -----

  // ----- Filter render
  const headerFilterRenderer = () => {
    return (
      <TableCell
        component='div'
        className={cx(
          classes.flexContainer,
          classes.filterHeader,
          classes.noClick,
          classes.headerFilterContainer,
          classes.flexFiller,
        )}
        variant='head'
        style={{ height: headerHeight }}
      />
    );
  };

  return (
    <div className={classes.tableOverflow}>
      <div
        className={classes.container}
      >
        <BlurScreen isBlurShown={onDataLoading && !!_rows}>
          <AutoSizer>
            {({ height, width }) => (
              <InfiniteLoader
                isRowLoaded={isRowLoaded}
                loadMoreRows={loadMoreRows}
                rowCount={typeof rowCount === 'number' ? rowCount : 0}
                minimumBatchSize={30}
                threshold={30}
              >
                {({ onRowsRendered, registerChild }) => (
                  <Table
                    onRowsRendered={onRowsRendered} // required InfiniteLoader prop
                    ref={(node) => setTableRef(node, registerChild)} // required InfiniteLoader prop
                    noRowsRenderer={() => placeholder}
                    rowCount={typeof rowCount === 'number' ? rowCount : 0}
                    rowGetter={rowGetter}
                    width={width > columnsWidth ? width : columnsWidth}
                    height={height - 20}
                    rowHeight={getRowHeight}
                    gridStyle={{
                      direction: 'inherit',
                    }}
                    headerHeight={headerHeight}
                    onRowClick={!onDataLoading ? handleRowClick : null}
                    rowClassName={getRowClassName}
                    gridClassName={cx(classes.noOutline, classes.tableBody)}
                    {...tableProps}
                  >
                    {multiCheck && (
                      <Column
                        key='checkbox'
                        headerRenderer={headerCheckboxRenderer}
                        cellRenderer={cellCheckboxRenderer}
                        dataKey='checkbox'
                        className={classes.flexContainer}
                        width={CHECKBOX_WIDTH}
                        disableSort
                      />
                    )}
                    {availableColumns.map(({ dataKey, permission, ...other }) => {
                      return (
                        <Column
                          key={dataKey}
                          headerRenderer={(props) =>
                            headerRenderer({ ...props, ...other })
                          }
                          cellRenderer={cellRenderer}
                          dataKey={dataKey}
                          className={classes.flexContainer}
                          headerClassName={classes.noOutline}
                          {...other}
                        />
                      );
                    })}
                    {onEndCheckboxClick && <Column
                      key='end_checkbox'
                      headerRenderer={() => headerRenderer({
                        label: endCheckboxLabel ?? '',
                        disableSort: true,
                        width: 100,
                        dataKey: 'end_checkbox',
                        flexGrow: 1,
                        columnData: {
                          shouldRange: false,
                          isFilterable: false,
                        },
                      })}
                      cellRenderer={(props) => endCheckboxRenderer(props, actionProps)}
                      dataKey='filter'
                      className={classes.flexContainer}
                      width={100}
                      disableSort
                      flexShrink={1}
                      flexGrow={1}
                    />
                    }
                    {!hideContextMenu && <Column
                      key='filter'
                      headerRenderer={headerFilterRenderer}
                      cellRenderer={(props) => cellMoreRenderer(props, actionProps)}
                      dataKey='filter'
                      className={classes.flexFiller}
                      width={MORE_WIDTH}
                      disableSort
                      flexShrink={1}
                      flexGrow={1}
                    />}
                  </Table>
                )}
              </InfiniteLoader>
            )}
          </AutoSizer>
        </BlurScreen>
      </div>

      {
        hasColumnsSelect && tableKey &&
        <div className={classes.filtersAndSettings}>
          <Grid container alignItems='center' className={classes.settingsIcon}
          >
            <Select
              multiSelect
              disabled={false}
              customItemsPerPage={100}
              dataKey={'table_settings'}
              filterValue={columns.filter(
                ({ permission, hidden }) =>
                  checkPermission(currentAccountPermissions, permission) && !hidden,
              ).map((columnData) => columnData.dataKey)
                .filter(value => !(listOfHiddenColumns[tableKey] ?? []).includes(value))}
              columnData={{
                isFilterable: false,
                options: columns.filter(
                  ({ permission, hidden }) =>
                    checkPermission(currentAccountPermissions, permission) && !hidden,
                ).map((columnData) => {
                  return {
                    label: columnData.label,
                    value: columnData.dataKey,
                    required: columnData.requiredColumn,
                  };
                }),
              }}
              onChange={(dataKey, listOfCheckedItem) => {
                const newList = columns.filter(
                  ({ permission, hidden }) =>
                    checkPermission(currentAccountPermissions, permission) && !hidden,
                );

                const newHiddenColumns = newList
                  .map((columnData) => columnData.dataKey)
                  .filter(value => !listOfCheckedItem.includes(value));


                const requiredColumns = newList
                  .filter(columnData => columnData.requiredColumn)
                  .map((columnData) => columnData.dataKey);


                let isAllRequiredColumnHidden = true;
                requiredColumns.forEach(value => {
                  if (isAllRequiredColumnHidden) {
                    isAllRequiredColumnHidden = newHiddenColumns.includes(value);
                  }
                });

                let isAllColumnHidden = true;
                newList.map((columnData) => columnData.dataKey).forEach(value => {
                  if (isAllColumnHidden) {
                    isAllColumnHidden = newHiddenColumns.includes(value);
                  }
                });

                if (!isAllColumnHidden) {
                  if (!isAllRequiredColumnHidden || requiredColumns.length === 0) {
                    changeTableHiddenColumn(tableKey, newHiddenColumns);
                  } else {
                    changeTableHiddenColumn(tableKey, newHiddenColumns.filter(value => value !== requiredColumns[0]));
                  }
                } else {
                  if (requiredColumns.length !== 0) {
                    changeTableHiddenColumn(tableKey, newHiddenColumns.filter(value => value !== requiredColumns[0]));
                  } else {
                    newHiddenColumns.splice(0, 1);
                    changeTableHiddenColumn(tableKey, newHiddenColumns);
                  }
                }
              }}
              label={t('titles:displayedColumns')}
              labelWidth={78}
              hasMenuPagination={true}
              customChild={<SettingsIcon className={classes.filterButton} />}
            />
          </Grid>
        </div>
      }
    </div>
  );
};

VirtualizedTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string.isRequired,
    }),
  ).isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      dataKey: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      width: PropTypes.number.isRequired,
    }),
  ).isRequired,
  rowCount: PropTypes.number.isRequired,
  onRowClick: PropTypes.func,
  multiCheck: PropTypes.bool,
  onDataLoading: PropTypes.bool,
  useColoredAssessments: PropTypes.bool,
  currentAccountPermissions: PropTypes.number.isRequired,
  FetchProps: PropTypes.shape({
    onLoadMoreRows: PropTypes.func.isRequired,
    sort: PropTypes.shape({
      sortBy: PropTypes.string,
      sortDirection: PropTypes.string,
    }),
    onSort: PropTypes.func,
    filter: PropTypes.shape({
      name: PropTypes.string,
    }),
    onFilter: PropTypes.func,
    fetchAllItems: PropTypes.func,
  }).isRequired,
  actionProps: PropTypes.shape({
    actions: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string.isRequired,
        onActionClick: PropTypes.func.isRequired,
        children: PropTypes.elementType.isRequired,
      }),
    ).isRequired,
  }),
  placeholder: PropTypes.node.isRequired,
};

VirtualizedTable.defaultProps = {
  onRowClick: undefined,
  multiCheck: false,
  onDataLoading: false,
  useColoredAssessments: false,
  actionProps: undefined,
};

export default VirtualizedTable;
