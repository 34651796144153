/* eslint-disable */
import React, { createRef, useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Button,
  fade,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { ChevronRight } from '@material-ui/icons';
import { Warning } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-fetching-library';

import { get } from 'lodash';
import cx from 'classnames';
import ContentWrapper from 'components/Wrappers/ContentWrapper';
import CollapseBlock from 'components/Shared/CollapseBlock';
import useMetadataUpdate from 'hooks/useMetadataUpdate';
import { ReactComponent as DownloadIcon } from 'assets/icons/file_download.svg';
import RoundedButton from 'components/Shared/RoundedButton';
import { ASSESSMENT_STATUSES } from 'components/Assessments/constants';
import smoothscroll from 'smoothscroll-polyfill';
import { SURVEYS_QUESTION_TYPES } from '../Shared/constants';
import { getLocalizedDate } from '../../helpers/localize';
import { ActivePatientContext } from '../../contexts/ActivePatientProvider';
import { checkPermission } from '../../helpers/checkPermissions';
import permissions from '../../config/permissions';
import AllergyBaselineAssessmentReport from './HelpModals/AllergyBaselineAssessmentReport';
import PediatricAllergyBaselineAssessmentReport
  from './HelpModals/PediatricAllergyBaselineAssessmentReport';
import SNOT22Report from './HelpModals/SNOT22Report';
import AsthmaControlAssessmentReport from './HelpModals/AsthmaControlAssessmentReport';
import QScaleReport from './HelpModals/QScaleReport';
import ReportNavigation from './Navigation';
import ReportViewTable from '../Shared/ReportViewTable';
import MarkdownParser from './MarkdownParser';
import ProvidersModal from './ProvidersModal';
import PatientInfoBlock from '../Patients/PatientInfoBlock';
import EmptyPageLoader from '../Shared/EmptyPageLoader';
import showServerError from '../../helpers/showError';
import {
  enqueueSnackbar,
  SNACKBAR_VARIANTS,
} from '../../redux_store/reducer/reducers/notificationsReducer';
import { useDispatch, useSelector } from 'react-redux';

// --------- adding smooth scrolling for click nav link ---------
smoothscroll.polyfill();

const getCount = ( SurveyItem ) => {
  let colors = { success: 0, warning: 0, error: 0, info: 0 };

  SurveyItem.map( item => {
    if(item.itemType === SURVEYS_QUESTION_TYPES.GridSelect) {
      item.subItems.map(item => {
        if (item.value) {
          item.value.valueUIClass ? (colors[item.value.valueUIClass] += 1) : (colors.info += 1);
        }
      })
    }

    if (item.value) {
      if (item.value) {
        item.value.valueUIClass ? (colors[item.value.valueUIClass] += 1) : (colors.info += 1);
      }
    }

    if(item.values) {
      item.values.map( item => {
        item.valueUIClass ? (colors[item.valueUIClass] += 1) : (colors.info += 1);
      })
    }
  })

  return colors;
}

const useStyles = makeStyles((theme) => ({
  PDF: {
    display: 'none',

    '@media print': {
      display: 'block',
    },
  },
  patientName: {
    color: theme.colors.lightBlue,
    textAlign: 'end',
    fontWeight: 500,
  },
  dot: {
    padding: theme.spacing(0, 0.5),
    color: theme.colors.lightBlue,
  },
  driver: {
    borderTop: '1px solid rgba(0, 0, 0, 0.13)',
  },
  patientInfoBox: {
    display: 'flex',
  },
  title: {
    fontSize: 13,
    textTransform: 'uppercase',
    fontWeight: 700,
    letterSpacing: 2,
    marginRight: 2,
  },
  text: {
    fontSize: 15,
    fontWeight: 400,
  },
  red: {
    color: theme.palette.error.main,
  },
  sign: {
    color: fade(theme.colors.blue2, 0.5),
  },
  helpButton: {
    display: 'flex',
    opacity: 1,
    right: 0,
    transition: '0.3s',
    fontSize: 11,
    textTransform: 'uppercase',
    color: theme.colors.lightBlue,
    fontWeight: 800,
    letterSpacing: 1,
    borderRadius: 8,
    marginTop: 5,
    marginLeft: -15,
    background: 'transparent',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
      background: 'transparent',
    },
    '&:active': {
      boxShadow: 'none',
      background: 'transparent',
    },
  },
  helpIcon: {
    padding: 8,
    border: `1px solid ${theme.palette.error.light}`,
    marginLeft: 5,
    marginBottom: 'auto',
    width: 20,
    height: 20,
    cursor: 'pointer',
    borderColor: theme.colors.lightBlue,
    fontSize: 12,
    color: theme.colors.lightBlue,
  },
  reportName: {
    fontSize: 22,
    fontWeight: 400,
    color: theme.colors.lightBlue,
    display: 'flex',
    alignItems: 'center',
  },
  collapseTabsContainer: {
    position: 'relative',
  },
  collapseButton: {
    position: 'absolute',
    top: -30,
    height: 20,
    fontSize: 9,
    width: 120,
  },
  collapseByDefaultButton: {
    position: 'absolute',
    top: -30,
    left: 130,
    height: 20,
    fontSize: 9,
  },
  blockTitle: {
    fontSize: 18,
    fontWeight: 400,
    color: fade(theme.colors.blue2, 0.5),
  },
  printBtn: {
    width: 49,
    borderColor: theme.colors.lightBlue,
  },
  printBtnLabel: {
    width: 24,
    height: 24,
  },
  container: {
    position: 'relative',
    marginTop: theme.spacing(2),
    borderTop: `1px solid ${fade(theme.palette.common.black, 0.13)}`,
  },
  billLink: {
    padding: 0,
    textTransform: 'none',
    borderRadius: theme.shape.borderRadius,
    background: fade(theme.colors.green, 0.3),
    '&:hover': {
      background: fade(theme.colors.green, 0.5),
    },
  },
  statusTitle: {
    fontSize: 14,
    letterSpacing: 2,
    color: theme.colors.green,
    textTransform: 'uppercase',
    fontWeight: 700,
  },
  arrowIcon: {
    width: 40,
    height: 40,
    color: theme.colors.green,
  },
  warningBoxStyle: {
    padding: 10,
    backgroundColor: fade(theme.colors.orange, .8),
    margin: '10px 0 10px 24px',
    borderRadius: 5,
    border: `1 px solid ${theme.colors.orange}`,
    boxShadow: '1px 4px 15px -5px rgba(240, 95, 95, 0.97)',
    width: 'auto'
  },
  warningTextStyle: {
    color: 'white',
  },
  warningIconStyle: {
    color: 'white',
    margin: ' 5px 15px 5px 5px',
  },
  acceptResultBtnLabel: {
    color: theme.palette.common.white,
    letterSpacing: 2,
    fontWeight: 700,
  },
  acceptResult: {
    marginRight: theme.spacing(2),
    background: theme.colors.green,
    padding: theme.spacing(1.25, 2),
  },
  notPrintingArea: {
    '@media print': {
      display: 'none',
    },
  },
  underTitleText: {
    fontSize: 15,
    fontStyle: 'italic',
    color: fade(theme.colors.blue2, 0.5),
    marginTop: theme.spacing(1),
  },
  actionsStyle: {
    [theme.breakpoints.between(0, 1315)]: {
      paddingTop: 0,
    },
  },
  hidden: {
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    background: theme.colors.green,
  },
  tabButton: {
    borderRadius: theme.spacing(2),
    padding: '3px 10px',
    width: 'auto',
    marginRight: 10,
    lineHeight: 1.4,
    fontSize: 12,
    color: theme.colors.grey,
    borderColor: theme.colors.grey,
    backgroundColor: theme.palette.common.white,
    boxShadow: 'none',
  },
  firstButton: {
    borderTopRightRadius: 0,
    borderTopLeftRadius: 17,
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 17,
  },
  secondButton: {
    borderRadius: 0,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    '&:hover': {
      borderRightWidth: 0,
      borderLeftWidth: 0,
    },
  },
  thirdButton: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 17,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 17,
  },
  activeTabButton: {
    color: theme.colors.lightBlue,
    borderColor: theme.colors.lightBlue,
    backgroundColor: theme.colors.white,
  },
}));

const ReportView = () => {
  const classes = useStyles();
  const { en: { notifications: tNotifications } } = useSelector((state) => state.i18n);
  const [surveyAccountPermissions, setSurveyAccountPermissions] = useState(null);
  const [openProvidersModal, toggleProvidersModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showHelpPopup, setShowHelpPopup] = useState(false);
  const [pageHeight, setPageHeight] = useState();
  const [blockTitles, setBlockTitles] = useState([]);
  const dispatch = useDispatch();
  const [currentBlockId, setCurrentBlockId] = useState();
  const [isReportPDFLoading, setIsReportPDFLoading] = useState(false);
  const [isBillingPDFLoading, setIsBillingPDFLoading] = useState(false);
  const [refs, setRefs] = useState({});
  const { t } = useTranslation(['btn', 'errors', 'notifications', 'titles']);
  const [surveysInfo, setSurveyInfo] = useState();
  const [counterOpenTabs, setCounterOpenTabs] = useState(0);
  const [isOpenDefaultTab, setIsOpenDefaultTab] = useState(true);
  const topRef = useRef();
  const {
    user,
    metadata: { currentAccountPermissions, currentAccount, childAccountPermissions },
  } = useSelector((state) => state.user);
  const {
    patientUuid: paramsPatientUuid,
    accountUuid: paramAccountUuid,
    assessmentUuid,
    status: paramStatus,
  } = useParams();
  const [status, setStatus] = useState(paramStatus);
  const {
    patientUuid,
    patientInfo,
  } = useContext(ActivePatientContext);
  const isDSandChildAccountSurvey = paramAccountUuid !== currentAccount.uuid;

  const isDistributionSponsor = checkPermission(
    currentAccountPermissions,
    permissions.ACCOUNTS_GET,
  );

  const updateCurrentBlockId = (id, hasDelayed = false) => {
    setTimeout(() => {
      setCurrentBlockId(id);
    }, hasDelayed ? 600 : 0);
  };

  const isOpenDefaultTabSelected = (newRefs) => {
    let newIsOpenDefaultTab = !surveysInfo.surveyItems.find(item =>
      item.expandedByDefault !== newRefs[item.title].open
    )
    if (newIsOpenDefaultTab !== isOpenDefaultTab) {
      setIsOpenDefaultTab(newIsOpenDefaultTab)
    }
  }

  const setOpenBlock = (title) => {
    const newRefs = { ...refs };
    setCounterOpenTabs(
      newRefs[title].open ? counterOpenTabs - 1 : counterOpenTabs + 1,
    );
    newRefs[title].open = !newRefs[title].open;
    isOpenDefaultTabSelected(newRefs);
    setRefs(newRefs);
  };

  const openAllBlock = () => {
    const newRefs = { ...refs };
    Object.keys(refs).forEach((refKeys) => {
      newRefs[refKeys].open = true;
      setCounterOpenTabs(Object.keys(refs).length);
    });
    isOpenDefaultTabSelected(newRefs);
    setRefs(newRefs);
  };
  const closeAllBlock = () => {
    const newRefs = { ...refs };
    Object.keys(refs).forEach((refKeys) => {
      newRefs[refKeys].open = false;
      setCounterOpenTabs(0);
    });
    isOpenDefaultTabSelected(newRefs);
    setRefs(newRefs);
  };

  const setOpenBlockByDefault = () => {
    let counterExpandedByDefaultTabs = 0;
    const refs = surveysInfo.surveyItems.reduce(
      (refsObj, { title, expandedByDefault }) => {
        if (expandedByDefault) counterExpandedByDefaultTabs += 1;
        refsObj[title] = {
          open: !!expandedByDefault,
          ref: createRef(),
        };
        return refsObj;
      },
      {},
    );
    setIsOpenDefaultTab(true);
    setCounterOpenTabs(counterExpandedByDefaultTabs);
    setRefs(refs);
  };

  const acceptReportAction = () => ({
    method: 'POST',
    endpoint: `/accounts/${paramAccountUuid}/surveys/${assessmentUuid}/accept-results`,
  });

  const { loading, mutate } = useMetadataUpdate(useMutation, [
    acceptReportAction,
  ]);

  const handleAcceptReport = async () => {
    const { payload, error, status } = await mutate([]);
    if (error) {
      const options = payload && {
        correlationUUID: payload.correlationUUID,
        userUUID: user.uuid,
      };
      switch (status) {
        case 422: {
          showServerError(dispatch, t('errors:validationError'));
          // setBackendErrors(payload.validationErrors);
          break;
        }
        default: {
          showServerError(dispatch, '', options);
          break;
        }
      }
    } else if (payload) {
    updateReport();
    }
  };

  const updateReport = async () => {
    getSurveyInfo();
    setStatus(ASSESSMENT_STATUSES.completed);
    dispatch(
      enqueueSnackbar({
        message: t(t('notifications:assessmentAccepted')),
        options: {
          variant: SNACKBAR_VARIANTS.success,
        },
      }),
    );
    enqueueSnackbar(t('notifications:assessmentAccepted'), {
      variant: SNACKBAR_VARIANTS.success,
    });
  };

  const { query: querySurveys, loading: loadingSurveys } = useMetadataUpdate(useQuery, [
    {
      method: 'GET',
      endpoint: `/accounts/${paramAccountUuid}/surveys/${assessmentUuid}/report`,
    },
    false,
  ]);

  useEffect(() => {
    setPageHeight(window.innerHeight);
    window.addEventListener('resize', () => {
      setTimeout(() => {
        setPageHeight(window.innerHeight);
      }, 300);
    });

    if (isDistributionSponsor) {
      setSurveyAccountPermissions(childAccountPermissions);
    } else {
      setSurveyAccountPermissions(currentAccountPermissions);
    }
  }, []);

  useEffect(() => {
    if (!isLoading && surveysInfo) {
      let newBlockTitles = [];
      surveysInfo.surveyItems.forEach(({ title, hideAutoGeneratedReportContent, reportContent }) => {
        if(!(!!hideAutoGeneratedReportContent === true && !!reportContent)) {
          newBlockTitles = [...newBlockTitles, title];
        }
      });
      setBlockTitles(newBlockTitles);
      setCurrentBlockId(newBlockTitles[0]);
      setOpenBlockByDefault();
    }
  }, [isLoading]);

  const getSurveyInfo = () => {
    querySurveys().then(({ error, payload }) => {
      if (!error) {
        const newSurveyInfo = {
          ...payload,
          termsAcceptedTimestamp: getLocalizedDate(
            payload.termsAcceptedTimestamp,
          ),
        };
        newSurveyInfo.surveyItems = newSurveyInfo.surveyItems.map((props) => {
          const colors = getCount(props.surveyItems);
          return { ...props, colors }
        });
        setSurveyInfo(newSurveyInfo);
        setIsLoading(false);
      }
    });
  }

  const getReportPDF = (link) => ({
    method: 'GET',
    endpoint: link,
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/pdf',
    },
    credentials: 'include',
  });

  const { mutate: getPDFFile } = useMutation(getReportPDF);

  const downloadPDF = async (link) => {
    const { payload, error, status } = await getPDFFile(link);
    if (error) {
      const options = payload && {
        correlationUUID: payload.correlationUUID,
        userUUID: user.uuid,
      };
      switch (status) {
        case 401: {
          showServerError(dispatch, tNotifications.notPermissionForEditingClaim);
          break;
        }
        default: {
          showServerError(dispatch, '', options);
          break;
        }
      }
      setTimeout(() => {
        setIsReportPDFLoading(false);
        setIsBillingPDFLoading(false);
      }, 300);
    } else if (payload && payload.size > 0) {
      const url = window.URL.createObjectURL(new Blob([payload], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${get(patientInfo, 'lastName', '') + '_' + surveysInfo.localizedTitle + '_' + surveysInfo.termsAcceptedTimestamp}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      setIsReportPDFLoading(false);
      setIsBillingPDFLoading(false);
    }
  };

  const downloadReportPDF = async () => {
    const link = `/accounts/${paramAccountUuid}/surveys/${assessmentUuid}/report/pdf`;
    await setIsReportPDFLoading(true);
    await downloadPDF(link);
  }

  const downloadBillingPDF = async (billingUUID) => {
    const link = `/accounts/${paramAccountUuid}/billings/${billingUUID}/billing-report/pdf`;
    await setIsBillingPDFLoading(true);
    await downloadPDF(link);
  };

  useEffect(() => {
    if (paramsPatientUuid && !loadingSurveys) {
      getSurveyInfo();
    }
  }, [paramsPatientUuid]);

  if (
    (patientUuid !== paramsPatientUuid)
    || !patientInfo
    || isLoading
    || surveysInfo === null
  ) {
    return <>
      <EmptyPageLoader />
      <PatientInfoBlock isReportPage={true} isForInitLoading />
    </>;
  }

  const patientFullName =
    patientInfo &&
    `${get(patientInfo, 'lastName', '')}, ${get(patientInfo, 'firstName', '')}`;

  const hasPermission = checkPermission(
    surveyAccountPermissions,
    isDistributionSponsor
      ? permissions.SURVEY_ACCEPT_RESULTS_SPECIFY_PROVIDER
      : permissions.SURVEY_ACCEPT_RESULTS,
  )

  const hasHelpInfoPopup = surveysInfo.localizedTitle === 'Q-Scale' ||
    surveysInfo.localizedTitle === 'Asthma Control Assessment' ||
    surveysInfo.localizedTitle === 'SNOT-22 Assessment' ||
    surveysInfo.localizedTitle === 'Pediatric Allergy Baseline Assessment' ||
    surveysInfo.localizedTitle === 'Allergy Baseline Assessment';

  return (
    <>
      {
        isDistributionSponsor &&
        <ProvidersModal
          updateReport={updateReport}
          openProvidersModal={openProvidersModal}
          toggleProvidersModal={toggleProvidersModal}
        />
      }
      <ContentWrapper
        className={classes.notPrintingArea}
        underTitleText={`Test Date: ${surveysInfo.termsAcceptedTimestamp}`}
        underTitleTextStyle={classes.underTitleText}
        actionsStyle={classes.actionsStyle}
        titleText={`${surveysInfo.localizedTitle} Report`}
        additionTitleButton={
          hasHelpInfoPopup ?
          <IconButton
            className={classes.helpIcon}
            onClick={() => {
              setShowHelpPopup(true);
            }}
          >
            ?
          </IconButton>
             : null
        }
        actions={
          <>
            <Grid container direction="column">
              <Typography
                noWrap
                className={cx(classes.titleItem, classes.titleText, classes.patientName)}
              >
                {patientFullName}
              </Typography>
              <Grid container>
                <PatientInfoBlock isReportPage={true}/>
              </Grid>
            </Grid>
          </>
        }
      >
        <div className={classes.driver} ref={topRef}/>
        {
          <Grid container>
              <Grid item xs={9} alignItems="center" alignContent={'center'}>
                <Box
                  py={3}
                  pl="4px"
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Box display="flex" justifyContent="flex-start">
                    <Button
                      color="primary"
                      variant={"outlined"}
                      className={cx(classes.tabButton, { [classes.activeTabButton]: isOpenDefaultTab },)}
                      onClick={setOpenBlockByDefault}
                    >
                      {t('btn:default')}
                    </Button>
                    <Button
                      color="primary"
                      variant={"outlined"}
                      className={cx(classes.tabButton, { [classes.activeTabButton]: counterOpenTabs === surveysInfo.surveyItems.length })}
                      onClick={openAllBlock}
                    >
                      {t('btn:expandAll')}
                    </Button>
                    <Button
                      color="primary"
                      variant={"outlined"}
                      className={cx(classes.tabButton, { [classes.activeTabButton]: counterOpenTabs === 0 })}
                      onClick={closeAllBlock}
                    >
                      {t('btn:closeAll')}
                    </Button>
                  </Box>
                  <Box>
                    <RoundedButton
                      isLoading={isReportPDFLoading}
                      className={classes.printBtn}
                      classes={{
                        label: classes.printBtnLabel,
                      }}
                      variant="outlined"
                      loaderColor="primary"
                      onClick={surveysInfo && downloadReportPDF}
                      disabled={!surveysInfo}
                    >
                      <DownloadIcon />
                    </RoundedButton>
                  </Box>
                </Box>
              </Grid>
            {status === ASSESSMENT_STATUSES.pendingReview
                && hasPermission
                && (<Grid item xs={3}>
                  <Box pl={3} py={3} alignItems="center">
                    <RoundedButton
                      variant="contained"
                      isLoading={loading}
                      onClick={isDSandChildAccountSurvey
                        ? () => toggleProvidersModal(true)
                        : () => handleAcceptReport()
                      }
                      className={classes.acceptResult}
                      classes={{
                        label: classes.acceptResultBtnLabel,
                      }}
                    >
                      {t('btn:acceptResults')}
                    </RoundedButton>
                  </Box>
                </Grid>
              )}
              {status === ASSESSMENT_STATUSES.inProgress
              && (
                <Grid item xs={3}>
                  <Grid container direction="row" alignItems="center" className={classes.warningBoxStyle}>
                    <Warning className={classes.warningIconStyle}/>
                    <Box>
                      <Typography className={cx(classes.statusTitle, classes.warningTextStyle)}>
                        {t('titles:assessmentInProgress')}
                      </Typography>
                      <Typography className={cx(classes.text, classes.warningTextStyle)}>
                        {t('titles:someDataNotAvailable')}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              )}
              {status === ASSESSMENT_STATUSES.completed && (
                <Grid item xs={3}>
                  <Box pl={3} py={3} alignItems="center">
                    <RoundedButton
                      fullWidth
                      className={classes.billLink}
                      onClick={surveysInfo.billingUUID && (() => downloadBillingPDF(surveysInfo.billingUUID))}
                      disabled={!surveysInfo.billingUUID}
                      isLoading={isBillingPDFLoading}
                    >
                      <Box
                        py={1}
                        px={2}
                        pr={0}
                        display="flex"
                        justifyContent="space-between"
                      >
                        <Box textAlign="left">
                          <Typography className={classes.statusTitle}>
                            {t('titles:accepted')}
                          </Typography>
                          <Typography className={classes.text}>
                            {t('titles:viewBillingReport')}
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="center">
                          <Typography
                            className={classes.text}>{new Date(surveysInfo.reviewedTimestamp).toLocaleDateString()}</Typography>
                          <ChevronRight className={classes.arrowIcon} />
                        </Box>
                      </Box>
                    </RoundedButton>
                  </Box>
                </Grid>
              )}
              <Grid item xs={9} className={classes.collapseTabsContainer}>
                <Box pl="4px">
                  {
                    surveysInfo.surveyItems.map(({ title, surveyItems, colors, reportContent, hideAutoGeneratedReportContent }) => (
                      !!hideAutoGeneratedReportContent === true && !!reportContent
                        ? null
                        : <CollapseBlock
                            refs={refs}
                            colors={colors}
                            pageHeight={pageHeight}
                            setCurrentBlockId={updateCurrentBlockId}
                            title={title}
                            currentBlockId={currentBlockId}
                            setOpenBlock={setOpenBlock}
                            reportContent={reportContent}
                          >
                              {
                                reportContent && <MarkdownParser content={reportContent} />
                              }
                              {
                                !!hideAutoGeneratedReportContent === true && !reportContent
                                ? null
                                : <ReportViewTable
                                  surveyList={surveyItems}
                                  counterColoredAnswers={colors}
                                />
                              }
                          </CollapseBlock>
                      )
                    )
                  }
                </Box>
              </Grid>
              <ReportNavigation
                topRef={topRef}
                refs={refs}
                setRefs={setRefs}
                currentBlockId={currentBlockId}
                setCurrentBlockId={updateCurrentBlockId}
                blockTitles={blockTitles}
                setOpenBlock={setOpenBlock}
                isOpenDefaultTabSelected={isOpenDefaultTabSelected}
              />
            </Grid>
        }
        {
          !isLoading && surveysInfo.localizedTitle === 'Allergy Baseline Assessment' &&
          <AllergyBaselineAssessmentReport
            showHelpPopup={showHelpPopup}
            setShowHelpPopup={setShowHelpPopup}
          />
        }
        {
          !isLoading && surveysInfo.localizedTitle === 'Pediatric Allergy Baseline Assessment' &&
          <PediatricAllergyBaselineAssessmentReport
            showHelpPopup={showHelpPopup}
            setShowHelpPopup={setShowHelpPopup}
          />
        }
        {
          !isLoading && surveysInfo.localizedTitle === 'SNOT-22 Assessment' &&
          <SNOT22Report
            showHelpPopup={showHelpPopup}
            setShowHelpPopup={setShowHelpPopup}
          />
        }
        {
          !isLoading && surveysInfo.localizedTitle === 'Asthma Control Assessment' &&
          <AsthmaControlAssessmentReport
            showHelpPopup={showHelpPopup}
            setShowHelpPopup={setShowHelpPopup}
          />
        }
        {
          !isLoading && surveysInfo.localizedTitle === 'Q-Scale' &&
          <QScaleReport
            showHelpPopup={showHelpPopup}
            setShowHelpPopup={setShowHelpPopup}
          />
        }
      </ContentWrapper>
    </>
  );
};

export default ReportView;

