import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { useMutation } from 'react-fetching-library';
import ContentWrapper from 'components/Wrappers/ContentWrapper';
import RoundedButton from 'components/Shared/RoundedButton';
import showServerError from 'helpers/showError';
import { routes } from 'config/routes';
import AccountForm from 'components/Accounts/AccountForm/AccountForm';
import { EMPTY_ADDRESS } from 'components/Shared/constants';
import { AppRouteContext } from 'contexts';
import useMetadataUpdate from 'hooks/useMetadataUpdate';
import BackButton from 'components/Shared/BackButton';
import { useDispatch, useSelector } from 'react-redux';
import {
  enqueueSnackbar,
  SNACKBAR_VARIANTS,
} from '../../redux_store/reducer/reducers/notificationsReducer';
import { formattedAccountsValues } from './helpers';

const AccountCreate = () => {
  const { t } = useTranslation(['btn', 'errors', 'notifications']);
  const [backendErrors, setBackendErrors] = useState({});
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { setAppRoute } = useContext(AppRouteContext);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailed, setIsFailed] = useState(false);

  const accountCreateAction = (values) => ({
    method: 'PUT',
    endpoint: '/accounts',
    body: values,
  });
  const { loading, mutate } = useMetadataUpdate(useMutation, [
    accountCreateAction,
  ]);

  const createAccount = async (values, { setSubmitting }) => {
    let formattedValues = formattedAccountsValues(values, true, {});
    setBackendErrors({});

    const { payload, error, status } = await mutate(formattedValues);
    if (error) {
      setIsFailed(true);
      setTimeout(() => {
        setIsFailed(false);
      }, 2000);
      const options = payload && {
        correlationUUID: payload.correlationUUID,
        userUUID: user.uuid,
      };
      setSubmitting(false);
      switch (status) {
        case 422: {
          showServerError(dispatch, t('errors:validationError'));
          setBackendErrors(payload.validationErrors);
          break;
        }
        default: {
          showServerError(dispatch, '', options);
          break;
        }
      }
    } else if (payload) {
      dispatch(
        enqueueSnackbar({
          message: t('notifications:accountCreated', {
            name: formattedValues.businessName,
          }),
          options: {
            variant: SNACKBAR_VARIANTS.success,
          },
        }),
      );
      setIsSuccess(true);
      setTimeout(() => {
        setIsSuccess(false);
        setAppRoute({
          path: routes.accountUserCreate.path,
          params: {
            accountUuid: payload.uuid,
          },
          title: t('titles:createUser'),
        });
      }, 1000);
    }
  };

  return (
    <Formik
      initialValues={{
        isDistributionSponsor: false,
        disableAllergiendResources: false,
        disableOrdersAutomations: false,
        disableResourcesAll: false,
        disableAssessments: false,
        disableOrders: false,
        enableClaimsManagement: false,
        businessName: '',
        phone: '',
        fax: '',
        ianaTimeZone: 'America/New_York',
        contactName: '',
        isShippingTheSame: false,
        organizationAddress: {
          ...EMPTY_ADDRESS,
        },
        shippingAddress: {
          ...EMPTY_ADDRESS,
        },
        otherAddresses: [],
        scheduleDeliverTime: 540,
        allowedSurveys: [],
        allowedResources: [],
        customTags: ['Other'],

        emailSurveyInvitationIsDefault: true,
        emailResourceInvitationIsDefault: true,
        emailSurveyReminderIsDefault: true,
        emailResourceReminderIsDefault: true,
        emailSurveyCompletionIsDefault: true,

        smsSurveyInvitationIsDefault: true,
        smsResourceInvitationIsDefault: true,
        smsSurveyReminderIsDefault: true,
        smsResourceReminderIsDefault: true,
        smsSurveyCompletionIsDefault: true,
        loading: 'Loading...',
      }}
      validateOnBlur={false}
      onSubmit={createAccount}
    >
      {({
        isValid,
        values,
        handleSubmit,
        dirty,
        isSubmitting,
        validateForm,
      }) => (
        <ContentWrapper
          titleText={t('titles:createAccount')}
          scrollBarSupport
          actions={
            <>
              <BackButton />
              <RoundedButton
                variant="contained"
                color="primary"
                size="small"
                disabled={
                  !isValid ||
                  isSubmitting ||
                  !dirty ||
                  values.scheduleDeliverTime === 'invalid_value'
                }
                onClick={handleSubmit}
                isLoading={loading}
                isSuccess={isSuccess}
                isFailed={isFailed}
              >
                {t('btn:create')}
              </RoundedButton>
            </>
          }
        >
          <AccountForm
            values={values}
            backendErrors={backendErrors}
            validateForm={validateForm}
            accountUuid=""
          />
        </ContentWrapper>
      )}
    </Formik>
  );
};

export default AccountCreate;
