import React, {  useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import showServerError from 'helpers/showError';
import { useDispatch, useSelector } from 'react-redux';
import { checkPermission } from '../../helpers/checkPermissions';
import permissions from '../../config/permissions';
import { useMutation } from 'react-fetching-library';
import RoundedButton from '../Shared/RoundedButton';
import { ReactComponent as ExportIcon } from '../../assets/icons/export-icon.svg';
import { getEndpoint } from '../../helpers/endpoint';
import { GET_RESOURCES_COLUMNS } from './constants';
import { TABLE_KEYS } from '../../redux_store/reducer/reducers/tableColumnsReducer';

const useStyles = makeStyles((theme) => ({
  iconBtn: {
    borderRadius: theme.shape.borderRadius,
    minHeight: 0,
    minWidth: 0,
    padding: '0 8px',
    height: 49,
    marginLeft: 18,
  },
  icon: {
    width: 15,
    height: 15,
    marginRight: 12,
    transform: 'rotate( -180deg )',
  },
}));

const ResourcesCSVButton = ({
                              tableFilter,
                              tableSort,
                            }) => {
  const { t } = useTranslation([
    'titles',
    'tables',
    'errors',
    'notifications',
    'btn',
    'dialogs',
  ]);
  const classes = useStyles();
  const {
    patientUuid: paramsPatientUuid,
    accountUuid: paramAccountUuid,
  } = useParams();
  const isPatientPage = Boolean(paramsPatientUuid);
  const dispatch = useDispatch();
  const {
    user,
    metadata: { currentAccount, currentAccountPermissions },
  } = useSelector((state) => state.user);
  const [isCSVLoading, setIsCSVLoading] = useState(false);
  const { listOfHiddenColumns } = useSelector((state) => state.tableColumns);
  const tableKey = paramAccountUuid ? TABLE_KEYS.resourcesDS : TABLE_KEYS.resources;

  const baseEndpoint = `/accounts/${
    paramAccountUuid || currentAccount.uuid
  }`;

  const getResourcesCSV = (fields) => ({
    method: 'GET',
    endpoint: getEndpoint(`${baseEndpoint}/export-resources.csv`, { fields }, tableSort, tableFilter),
    responseType: 'blob',
    headers: {
      'Content-Type': 'text/csv',
    },
  });

  const { mutate: getCSV } = useMutation(getResourcesCSV);

  const FIELDS_FOR_CSV = GET_RESOURCES_COLUMNS(
    isPatientPage,
    paramAccountUuid,
  ).filter(
    ({ permission, hidden }) =>
      checkPermission(currentAccountPermissions, permission) && !hidden,
  ).map((columnData) => columnData.dataKey)
    .filter(value => !(listOfHiddenColumns[tableKey] ?? []).includes(value));

  const hasLoadCSVPermission = checkPermission(
    currentAccountPermissions,
    permissions.ADMIN_PATIENT_IMPORT,
  );

  return (
    <>
      {
        hasLoadCSVPermission &&
        <RoundedButton
          variant={'outlined'}
          color='primary'
          size='small'
          isLoading={isCSVLoading}
          onClick={async () => {
            setIsCSVLoading(true);
            const { payload, error, status } = await getCSV(FIELDS_FOR_CSV.join());
            if (error) {
              const options = payload && {
                correlationUUID: payload.correlationUUID,
                userUUID: user.uuid,
              };
              switch (status) {
                case 401: {
                  showServerError(dispatch, t('notifications:notPermissionForEditingClaim'));
                  break;
                }
                default: {
                  showServerError(dispatch, '', options);
                  break;
                }
              }
            } else if (payload && payload.size > 0) {
              const url = window.URL.createObjectURL(new Blob([payload]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', `Resources ${Date.now()}.csv`);
              document.body.appendChild(link);
              link.click();
            }
            setIsCSVLoading(false);
          }}
          className={classes.iconBtn}
          LoadingWrapperProps={{
            LoaderProps: {
              disableShrink: true,
            },
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              paddingLeft: 4,
            }}
          >
            <ExportIcon className={classes.icon} />
            <div style={{
              textTransform: 'none',
              fontSize: '0.875rem',
              fontWeight: 500,
              lineHeight: 1.75,
              letterSpacing: '0.02857em',
              marginRight: 8,
            }}>
              {t('btn:Download')}
            </div>
          </div>
        </RoundedButton>
      }
    </>
  );
};

export default ResourcesCSVButton;
