import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import IdleTimer from 'react-idle-timer';
import IdleDialog from 'components/Dialogs/IdleDialog';
import { useDispatch, useSelector } from 'react-redux';
import { INFO_DURATION } from '../../contexts/NotificationsProvider';
import {
  closeSnackbar,
  enqueueSnackbar, SNACKBAR_VARIANTS,
} from '../../redux_store/reducer/reducers/notificationsReducer';
import { logout } from '../../redux_store/reducer/reducers/userReducer/api';

const IDLE_TIMEOUT = 1000 * 60 * 30;
const LOGOUT_TIMEOUT = 1000 * 60;
const Idle = () => {
  const { t } = useTranslation('notifications');
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const userLogoutNotification = useRef();
  const userActivityIdleRef = useRef();
  const idleRef = useRef();

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleIdle = () => {
    if (user) {
      const currentTime = new Date().getTime();
      const lastActiveTime = idleRef.current.getLastActiveTime();
      if (currentTime - lastActiveTime > IDLE_TIMEOUT + LOGOUT_TIMEOUT) {
        dispatch(logout());
      } else {
        setDialogOpen(true);
      }
    }
  };

  const handleLogout = () => {
    if (user) {
      dispatch(logout());
    }
    setDialogOpen(false);
    userLogoutNotification.current = dispatch(
      enqueueSnackbar({
        message: t('autoLogout'),
        options: {
          _key: 'logout',
          persist: true,
          variant: SNACKBAR_VARIANTS.info,
          autoHideDuration: INFO_DURATION,
          preventDuplicate: true,
        },
      }),
    );
  };

  useEffect(() => {
    if (user && userLogoutNotification.current) {
      dispatch(closeSnackbar('logout'));
    }
  }, [user]);

  return (
    <>
      <IdleTimer
        ref={idleRef}
        element={document}
        onIdle={handleIdle}
        timeout={IDLE_TIMEOUT}
      />
      {isDialogOpen && (
        <IdleTimer
          ref={userActivityIdleRef}
          element={document}
          onIdle={handleLogout}
          timeout={LOGOUT_TIMEOUT}
        />
      )}
      <IdleDialog
        open={isDialogOpen}
        logout={() => {
          dispatch(logout());
          setDialogOpen(false);
        }}
        onClose={handleDialogClose}
        onLogout={handleLogout}
        logoutTimeout={LOGOUT_TIMEOUT / 1000}
      />
    </>
  );
};

export default Idle;
