import React from 'react';
import PropTypes from 'prop-types';
import {
  Divider,
  Typography,
  makeStyles,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';
import cx from 'classnames';
import { checkPermission } from '../../../../../helpers/checkPermissions';
import permissions from '../../../../../config/permissions';
import { useSelector } from 'react-redux';
import MultiSelectSection from './MultiSelectSection';

const useStyles = makeStyles((theme) => ({
  title: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(1),
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
  checkbox: {
    marginLeft: theme.spacing(0.5),
  },
  divider: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
  featuresContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  features: {
    flex: '1 0 50%',
    display: 'flex',
    flexDirection: 'column',
  },
  hideFeatures: {
    display: 'none',
  },
}));

const FeaturesSection = ({
                           disableAssessments,
                           disableResources,
                           disableOrders,
                           allowedSurveys,
                           allowedResources,
                           isCurrentAccountEditing,
                         }) => {
  const classes = useStyles();
  const { t } = useTranslation(['forms', 'errors', 'notifications']);
  const { disabledFeatures } = useSelector((state) => state.appSettings);

  const {
    metadata: { childAccountPermissions },
  } = useSelector((state) => state.user);

  const canDisableOrders = checkPermission(
    childAccountPermissions,
    permissions.ACCOUNT_PATCH_DISABLE_ORDERS,
  );
  const canDisableAssessments = checkPermission(
    childAccountPermissions,
    permissions.ACCOUNT_PATCH_DISABLE_ASSESSMENTS,
  );
  const canDisableAllergiendResources = checkPermission(
    childAccountPermissions,
    permissions.ACCOUNT_PATCH_ALLERGIEND_RESOURCES,
  );
  const canEnableClaimsManagement = checkPermission(
    childAccountPermissions,
    permissions.ACCOUNT_PATCH_ENABLE_CLAIM_MANAGEMENT,
  );
  const canDisableResources = checkPermission(
    childAccountPermissions,
    permissions.ACCOUNT_PATCH_RESOURCE_ALL,
  );

  const isAnyDisableFeaturesAvailable =
    canDisableOrders ||
    canDisableAssessments ||
    canDisableAllergiendResources ||
    canDisableResources;

  const isAnyEnableFeaturesAvailable = canEnableClaimsManagement;

  const isAnyFeaturesAvailable =
    isAnyDisableFeaturesAvailable || isAnyEnableFeaturesAvailable;

  return (
    <>
      {!isCurrentAccountEditing && isAnyFeaturesAvailable && (
        <>
          <Divider className={cx(classes.divider, classes.marginTop)} />
          <Typography className={classes.title}>
            {t('forms:features')}:
          </Typography>
          <div className={classes.featuresContainer}>
            <div
              className={cx(classes.features, {
                [classes.hideFeatures]: !isAnyDisableFeaturesAvailable,
              })}
            >
              {canDisableOrders && (
                <Field name='disableOrders' type='checkbox'>
                  {({ field, form }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={field.name}
                          checked={!field.value}
                          color='primary'
                          onChange={(e, checked) => {
                            form.setFieldValue(field.name, !checked);
                          }}
                        />
                      }
                      className={classes.checkbox}
                      label={t('forms:enableOrders')}
                    />
                  )}
                </Field>
              )}
              {canDisableAllergiendResources && (
                <Field name='disableAllergiendResources' type='checkbox'>
                  {({ field, form }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={field.name}
                          checked={!field.value}
                          color='primary'
                          onChange={(e, checked) => {
                            form.setFieldValue(field.name, !checked);
                          }}
                        />
                      }
                      className={classes.checkbox}
                      label={t('forms:enableAllergiEndResources')}
                    />
                  )}
                </Field>
              )}
            </div>
            <div
              className={cx(classes.features, {
                [classes.hideFeatures]: !isAnyEnableFeaturesAvailable,
              })}
            >
              {canDisableOrders && (
                <div style={{
                  display: 'inline-flex',
                  opacity: !disableOrders ? 1 : 0,
                  flexGrow: 1,
                  transition: 'opacity 0.3s',
                }}>
                  <Field name='disableOrdersAutomations' type='checkbox'>
                    {({ field, form }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            name={field.name}
                            checked={field.value}
                            color='primary'
                            onChange={(e, checked) => {
                              form.setFieldValue(field.name, checked);
                            }}
                          />
                        }
                        className={classes.checkbox}
                        label={t('forms:disableOrdersAutomations')}
                      />
                    )}
                  </Field> :
                </div>
              )}
              {canEnableClaimsManagement && (
                <Field name='enableClaimsManagement' type='checkbox'>
                  {({ field, form }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={field.name}
                          checked={field.value}
                          color='primary'
                          onChange={(e, checked) => {
                            form.setFieldValue(field.name, checked);
                          }}
                        />
                      }
                      className={classes.checkbox}
                      label={t('forms:enableClaimsManagement')}
                    />
                  )}
                </Field>
              )}
            </div>
            <div
              className={cx(classes.features, {
                [classes.hideFeatures]: !isAnyDisableFeaturesAvailable,
              })}
            >
              {canDisableAssessments && (
                <Field name='allowedSurveys' type='checkbox'>
                  {({ form }) => (
                    <MultiSelectSection
                      checkboxFieldName={'disableAssessments'}
                      checkboxFieldLabel={'enableAssessments'}
                      selectorFieldName={'allowedSurveys'}
                      selectorFieldLabel={'allowedSurveys'}
                      isSelectorDisabled={disableAssessments}
                      disableOptionCheckboxKey={'hasAutomation'}
                      selectedItems={allowedSurveys}
                      getOptionsEndpoint={`/surveys`}
                      hideOptionalCheckBox={disabledFeatures.includes(
                        'account_automations',
                      )}
                      optionalCheckBoxKey={'enableAutomation'}
                      onOptionalCheckboxTap={(e, key, value) => {
                        const newAllowedSurveys = allowedSurveys.map(
                          (survey) => {
                            if (survey.key === key) {
                              return {
                                key: key,
                                enableAutomation: value,
                              };
                            } else {
                              return survey;
                            }
                          },
                        );
                        form.setFieldValue('allowedSurveys', newAllowedSurveys);
                      }}
                    />
                  )}
                </Field>
              )}
            </div>
            <div
              className={cx(classes.features, {
                [classes.hideFeatures]: !isAnyEnableFeaturesAvailable,
              })}
            >
              {canDisableResources && (
                <MultiSelectSection
                  checkboxFieldName={'disableResourcesAll'}
                  checkboxFieldLabel={'enableResources'}
                  selectorFieldName={'allowedResources'}
                  selectorFieldLabel={'allowedResources'}
                  isSelectorDisabled={disableResources}
                  selectedItems={allowedResources}
                  getOptionsEndpoint={`/resources`}
                />
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

FeaturesSection.propTypes = {
  disableAssessments: PropTypes.bool,
  isCurrentAccountEditing: PropTypes.bool,
};

FeaturesSection.defaultProps = {
  disableAssessments: false,
  isCurrentAccountEditing: false,
};

export default FeaturesSection;
