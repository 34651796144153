import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, FormControlLabel, Checkbox } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';
import Select from '../../../../FormikFields/ComplexSelect/Select';
import useMetadataUpdate from '../../../../../hooks/useMetadataUpdate';
import { useQuery } from 'react-fetching-library';

const useStyles = makeStyles((theme) => ({
  title: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(1),
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
  checkbox: {
    marginLeft: theme.spacing(0.5),
  },
  divider: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
  featuresContainer: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  features: {
    flex: '1 0 50%',
    display: 'flex',
    flexDirection: 'column',
  },
  hideFeatures: {
    display: 'none',
  },
  selectorContainer: {
    padding: '12px 16px',
    width: '100%',
  },
}));

const MultiSelectSection = ({
                              checkboxFieldName,
                              checkboxFieldLabel,
                              selectorFieldName,
                              selectorFieldLabel,
                              isSelectorDisabled,
                              selectedItems,
                              getOptionsEndpoint,
                              optionalCheckBoxKey,
                              onOptionalCheckboxTap,
                              hideOptionalCheckBox,
                              disableOptionCheckboxKey,
                            }) => {
  const classes = useStyles();
  const { t } = useTranslation(['forms', 'errors', 'notifications']);
  const [optionList, setOptionList] = useState([]);

  const { query, loading: loadingSurveys } = useMetadataUpdate(useQuery, [
    {
      method: 'GET',
      endpoint: getOptionsEndpoint,
    },
    false,
  ]);

  useEffect(() => {
    if (getOptionsEndpoint) {
      query().then(({ error, payload }) => {
        const result = (payload && payload.result) || payload;
        if (!error && result && result.length > 0) {
          const newOptions = result.map((item) => {
            const newOptionValues = {
              value: item.id || item.resourceKey,
              label: item.title,
            };
            if (item[disableOptionCheckboxKey] !== undefined) {
              newOptionValues.disableOptionCheckbox =
                !item[disableOptionCheckboxKey];
            }
            return newOptionValues;
          });
          setOptionList(newOptions);
        }
      });
    }
  }, [getOptionsEndpoint]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <Field name={checkboxFieldName} type='checkbox'>
        {({ field, form }) => (
          <FormControlLabel
            control={
              <Checkbox
                name={field.name}
                checked={!field.value}
                color='primary'
                onChange={(e, checked) => {
                  form.setFieldValue(field.name, !checked);
                }}
              />
            }
            className={classes.checkbox}
            label={t('forms:' + checkboxFieldLabel)}
          />
        )}
      </Field>
      {optionList.length > 0 && (
        <Field name={selectorFieldName} type='selector'>
          {({ field, form }) => (
            <div className={classes.selectorContainer}>
              <Select
                multiSelect
                loading={loadingSurveys}
                disabled={isSelectorDisabled}
                dataKey={selectorFieldName}
                filterValue={selectedItems}
                columnData={{
                  isFilterable: false,
                  options: optionList,
                }}
                onChange={(dataKey, val) => {
                  form.setFieldValue(field.name, val);
                }}
                label={`${t('forms:' + selectorFieldLabel)}`}
                labelWidth={78}
                hasMenuPagination
                hideOptionalCheckBox={hideOptionalCheckBox}
                optionalCheckBoxKey={optionalCheckBoxKey}
                onOptionalCheckboxTap={onOptionalCheckboxTap}
              />
            </div>
          )}
        </Field>
      )}
    </div>
  );
};

MultiSelectSection.propTypes = {
  disableResources: PropTypes.bool,
  isCurrentAccountEditing: PropTypes.bool,
  hideOptionalCheckBox: PropTypes.bool,
  optionalCheckBoxKey: PropTypes.string,
  onOptionalCheckboxTap: PropTypes.func,
};

MultiSelectSection.defaultProps = {
  disableResources: false,
  isCurrentAccountEditing: false,
  optionalCheckBoxKey: null,
  onOptionalCheckboxTap: null,
  hideOptionalCheckBox: false,
};

export default MultiSelectSection;
