import i18next from 'i18next';
import { ReactComponent as PendingIcon } from 'assets/icons/pending.svg';
import { ReactComponent as ShippedIcon } from 'assets/icons/shipped.svg';
import { ReactComponent as ProcessingIcon } from 'assets/icons/processing.svg';
import { ReactComponent as HoldIcon } from 'assets/icons/hold.svg';
import permissions from '../../config/permissions';
import FILTER_TYPES from '../Filters/constants';
import { CUSTOM_TAGS_OPTIONS, SURVEY_TAGS_OPTIONS } from '../Shared/CustomTags';

export const ASSESSMENT_STATUSES = {
  invited: 'invited',
  inProgress: 'inProgress',
  pendingReview: 'pendingReview',
  completed: 'completed',
  processing: 'processing',
  scheduled: 'scheduled',
  eTransfer: 'eTransfer',
  canceled: 'canceled',
};

export const ASSESSMENT_STATUSES_OPTIONS = [
  {
    label: i18next.t('tables:invited'),
    value: ASSESSMENT_STATUSES.invited,
    icon: HoldIcon,
    allowEditAssessment: false,
    enabledForNonSponsors: false,
  },
  {
    label: i18next.t('tables:inProgress'),
    value: ASSESSMENT_STATUSES.inProgress,
    icon: PendingIcon,
    allowEditAssessment: false,
    enabledForNonSponsors: false,
  },
  {
    label: i18next.t('tables:scheduled'),
    value: ASSESSMENT_STATUSES.scheduled,
    icon: ProcessingIcon,
    allowEditAssessment: false,
    enabledForNonSponsors: false,
  },
  {
    label: i18next.t('tables:pendingReview'),
    value: ASSESSMENT_STATUSES.pendingReview,
    icon: ProcessingIcon,
    allowEditAssessment: true,
    enabledForNonSponsors: true,
    addDivider: true,
  },
  {
    label: i18next.t('tables:completed'),
    value: ASSESSMENT_STATUSES.completed,
    icon: ShippedIcon,
    allowEditAssessment: true,
    enabledForNonSponsors: true,
  },
  {
    label: i18next.t('tables:processing'),
    value: ASSESSMENT_STATUSES.processing,
    icon: ShippedIcon,
    allowEditAssessment: false,
    enabledForNonSponsors: false,
  },
  {
    label: i18next.t('tables:eTransfer'),
    value: ASSESSMENT_STATUSES.eTransfer,
    icon: ShippedIcon,
    allowEditAssessment: false,
    enabledForNonSponsors: false,
  },
  {
    label: i18next.t('tables:canceled'),
    value: ASSESSMENT_STATUSES.canceled,
    icon: ShippedIcon,
    allowEditAssessment: false,
    enabledForNonSponsors: false,
  },
];

export const ASSESSMENT_FILTER_LIST = [
  {
    title: 'All',
    id: 'all',
  },
  {
    title: 'Respiratory & Allergy',
    id: 'respiratoryAndAllergy',
  },
  {
    title: 'Emotional & Psychological',
    id: 'emotionalAndPsychological',
  },
  {
    title: 'Lifestyle & Preventive Care',
    id: 'lifestyleAndPreventiveCare',
  },
  {
    title: 'Chronic Pain',
    id: 'chronicPain',
  },
];

export const FIELDS = [
  'accountCustomTags',
  'accountBusinessName',
  'patientFirstName',
  'patientLastName',
  'patientDateOfBirth',
  'surveyName',
  'orderDate',
  'status',
  'invitedTimestamp',
  'appointmentTimestamp',
  'patientMrnNumber',
  'createdBy',
];

export const ASSESSMENTS_FOR_PATIENT_FIELDS = [
  'patientDateOfBirth',
  'surveyName',
  'orderDate',
  'status',
  'invitedTimestamp',
  'appointmentTimestamp',
  'patientMrnNumber',
  'createdBy',
];

export const GET_ASSESSMENTS_COLUMNS = (
  isPatientPage,
  paramAccountUuid,
  totalAccountsCount,
) => [
  {
    width: 200,
    minWidth: 200,
    label: i18next.t('tables:customTags'),
    dataKey: 'accountCustomTags',
    columnData: {
      isFilterable: true,
      filterType: FILTER_TYPES.SELECT,
      options: CUSTOM_TAGS_OPTIONS,
    },
    permission: permissions.ACCOUNTS_GET,
    hidden: Boolean(paramAccountUuid),
  },
  {
    width: 200,
    minWidth: 200,
    label: i18next.t('tables:surveyTags'),
    dataKey: 'surveyTags',
    columnData: {
      isFilterable: true,
      filterType: FILTER_TYPES.SELECT,
      options: SURVEY_TAGS_OPTIONS,
    },
    permission: permissions.ACCOUNTS_GET,
    hidden: Boolean(paramAccountUuid),
  },
  {
    width: 220,
    label: i18next.t('tables:account'),
    dataKey: 'accountBusinessName',
    columnData: {
      isFilterable: true,
      totalAccountsCount,
    },
    flexGrow: 3,
    permission: permissions.ACCOUNTS_GET,
    hidden: Boolean(paramAccountUuid),
    requiredColumn: true,
  },
  {
    width: 120,
    label: i18next.t('tables:customId'),
    dataKey: 'accountCustomIdentifier',
    columnData: {
      isFilterable: true,
    },
    permission: permissions.ACCOUNTS_GET,
    hidden: Boolean(paramAccountUuid),
    requiredColumn: true,
  },
  {
    width: 200,
    minWidth: 200,
    label: i18next.t('tables:mrnNumber'),
    dataKey: 'patientMrnNumber',
    columnData: {
      isFilterable: true,
    },
  },
  {
    width: 200,
    minWidth: 200,
    label: i18next.t('tables:patientFirstName'),
    dataKey: 'patientFirstName',
    columnData: {
      isFilterable: true,
    },
    flexGrow: 3,
    hidden: isPatientPage,
  },
  {
    width: 200,
    label: i18next.t('tables:patientLastName'),
    dataKey: 'patientLastName',
    columnData: {
      isFilterable: true,
    },
    flexGrow: 3,
    hidden: isPatientPage,
  },
  {
    width: 150,
    label: i18next.t('tables:patientDOB'),
    dataKey: 'patientDateOfBirth',
    hidden: isPatientPage,
    columnData: {
      isFilterable: true,
      filterType: FILTER_TYPES.DATE,
    },
  },
  {
    width: isPatientPage ? 350 : 220,
    label: i18next.t('tables:assessmentName'),
    columnData: {
      isFilterable: true,
    },
    dataKey: 'surveyName',
    flexGrow: 3,
  },
  {
    width: 150,
    label: i18next.t('tables:orderDate'),
    dataKey: 'orderDate',
    columnData: {
      shouldRange: true,
      isFilterable: true,
      filterType: FILTER_TYPES.DATE,
    },
  },
  {
    width: 150,
    label: i18next.t('tables:appointmentTimestamp'),
    dataKey: 'appointmentTimestamp',
    columnData: {
      shouldRange: true,
      isFilterable: true,
      filterType: FILTER_TYPES.DATE,
    },
  },
  {
    width: 150,
    label: i18next.t('tables:invitedTimestamp'),
    dataKey: 'invitedTimestamp',
    columnData: {
      shouldRange: true,
      isFilterable: true,
      filterType: FILTER_TYPES.DATE,
    },
  },
  {
    width: 200,
    label: i18next.t('tables:createdBy'),
    dataKey: 'createdBy',
    columnData: {
      isFilterable: true,
    },
  },
  {
    width: 200,
    label: i18next.t('tables:status'),
    dataKey: 'status',
    columnData: {
      isFilterable: true,
      filterType: FILTER_TYPES.SELECT,
      options: ASSESSMENT_STATUSES_OPTIONS,
    },
  },
];

export const INVITATION_TYPE = {
  send: 'send',
  resend: 'resend',
  notAllowed: 'notAllowed',
}

export const FAKE_ASSESSMENT = {
  accountCustomTags: [],
  uuid: '',
  accountUUID: '',
  accountBusinessName: '',
  patientUUID: '',
  patientMrnNumber: '',
  patientFirstName: '',
  patientLastName: '',
  accountCustomIdentifier: '',
  patientDateOfBirth: '',
  surveyName: '',
  orderDate: '',
  status: 'In Progress',
  invitationType: INVITATION_TYPE.notAllowed,
  url: '',
  moreTitle: '',
  resendInvitationAction: false,
  claimable: false,
  helpSeekingBehaviorAlerts: [],
};
