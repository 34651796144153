import i18next from 'i18next';
import permissions from '../../config/permissions';
import FILTER_TYPES from '../Filters/constants';
import { ReactComponent as PendingIcon } from '../../assets/icons/pending.svg';
import { ReactComponent as ShippedIcon } from '../../assets/icons/shipped.svg';
import { CUSTOM_TAGS_OPTIONS } from '../Shared/CustomTags';

export const MAX_INVOICES = 100;

export const INVOICES_STATUSES = {
  invoiced: 'invoiced',
  pending: 'pending',
};

export const INVOICES_STATUSES_OPTIONS = [
  {
    label: i18next.t('tables:invoiced'),
    value: INVOICES_STATUSES.invoiced,
    icon: ShippedIcon,
    enabledForNonSponsors: false,
  },
  {
    label: i18next.t('tables:pending'),
    value: INVOICES_STATUSES.pending,
    icon: PendingIcon,
    enabledForNonSponsors: false,
  },
];

export const INVOICES_STATUSES_TABLE_OPTIONS = [
  {
    label: i18next.t('tables:invoiced'),
    value: INVOICES_STATUSES.invoiced,
    icon: ShippedIcon,
    enabledForNonSponsors: false,
  },
];

export const FIELDS = [
  'accountCustomTags',
  'accountBusinessName',
  'patientFirstName',
  'patientLastName',
  'patientDateOfBirth',
  'entityName',
  'entityOrderTimestamp',
  'entityCompletedTimestamp',
  'invoiceStatus',
];

export const INVOICES_FOR_PATIENT_FIELDS = [
  'patientDateOfBirth',
  'entityName',
  'entityOrderTimestamp',
  'entityCompletedTimestamp',
  'invoiceStatus',
];

export const GET_INVOICES_COLUMNS = (
  isPatientPage,
  paramAccountUuid,
  totalAccountsCount,
) => [
  {
    width: 200,
    minWidth: 200,
    label: i18next.t('tables:customTags'),
    dataKey: 'accountCustomTags',
    columnData: {
      isFilterable: true,
      filterType: FILTER_TYPES.SELECT,
      options: CUSTOM_TAGS_OPTIONS,
    },
    permission: permissions.ACCOUNTS_GET,
    hidden: Boolean(paramAccountUuid),
  },
  {
    width: 220,
    label: i18next.t('tables:account'),
    dataKey: 'accountBusinessName',
    columnData: {
      isFilterable: true,
      totalAccountsCount,
    },
    flexGrow: 1,
    permission: permissions.ACCOUNTS_GET,
    hidden: Boolean(paramAccountUuid),
    requiredColumn: true,
  },
  {
    width: 120,
    label: i18next.t('tables:customId'),
    dataKey: 'accountCustomIdentifier',
    columnData: {
      isFilterable: true,
    },
    permission: permissions.ACCOUNTS_GET,
    hidden: Boolean(paramAccountUuid),
    requiredColumn: true,
  },
  // {
  //   width: 200,
  //   minWidth: 200,
  //   label: i18next.t('tables:mrnNumber'),
  //   dataKey: 'patientMrnNumber',
  //   columnData: {
  //     isFilterable: true,
  //   },
  // },
  {
    width: 200,
    minWidth: 200,
    label: i18next.t('tables:patientFirstName'),
    dataKey: 'patientFirstName',
    columnData: {
      isFilterable: true,
    },
    flexGrow: 1,
    hidden: isPatientPage,
  },
  {
    width: 200,
    label: i18next.t('tables:patientLastName'),
    dataKey: 'patientLastName',
    columnData: {
      isFilterable: true,
    },
    flexGrow: 1,
    hidden: isPatientPage,
  },
  {
    width: isPatientPage ? 350 : 220,
    label: i18next.t('tables:entityName'),
    columnData: {
      isFilterable: true,
    },
    dataKey: 'entityName',
    flexGrow: 1,
  },
  {
    width: 150,
    label: i18next.t('tables:patientDOB'),
    dataKey: 'patientDateOfBirth',
    hidden: isPatientPage,
  },
  {
    width: 150,
    label: i18next.t('tables:orderDate'),
    dataKey: 'entityOrderTimestamp',
    columnData: {
      shouldRange: true,
      isFilterable: true,
      filterType: FILTER_TYPES.DATE,
    },
  },
  {
    width: 150,
    label: i18next.t('tables:entityCompletedTimestamp'),
    dataKey: 'entityCompletedTimestamp',
    columnData: {
      shouldRange: true,
      isFilterable: true,
      filterType: FILTER_TYPES.DATE,
    },
  },
  {
    width: 200,
    label: i18next.t('tables:invoiceStatus'),
    dataKey: 'invoiceStatus',
    columnData: {
      isFilterable: true,
      filterType: FILTER_TYPES.SELECT,
      options: INVOICES_STATUSES_OPTIONS,
    },
  },
];

export const FAKE_INVOICE = {
  accountCustomTags: [],
  uuid: '',
  patientUUID: '',
  accountCustomIdentifier: '',
  accountUUID: '',
  invoiceStatus: '',
  entityName: '',
  accountBusinessName: '',
  patientFirstName: '',
  patientLastName: '',
  patientDateOfBirth: '',
  entityOrderTimestamp: '',
  entityCompletedTimestamp: '',
  moreTitle: '',
  resendInvitationAction: true,
  disabledCheckbox: true,
  disabledRowMenu: true,
};
