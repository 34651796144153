import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import {
  fade,
  Collapse,
  makeStyles,
  ListItem,
  ListItemIcon,
  ListItemText,
  List,
} from '@material-ui/core';
import cx from 'classnames';
import { AppRouteContext } from 'contexts';

const useStyles = makeStyles((theme) => ({
  navItem: {
    padding: theme.spacing(1.5, 3),
    color: theme.colors.gray1,
    textTransform: 'uppercase',
    fontWeight: '500',
    '&:hover': {
      background: fade(theme.palette.common.white, 0.3),
    },
  },
  navItemChild: {
    padding: theme.spacing(0.5, 4, 0.5, 9),
    color: theme.palette.common.white,
    textTransform: 'none',
  },
  listItemText: {
    fontSize: 13,
    fontWeight: 'bold',
    letterSpacing: 2,
    opacity: 0.5,
    color: theme.palette.common.white,
    overflow: 'hidden',
    whiteSpace: 'normal',
  },
  selectedItem: {
    background: `linear-gradient(90deg, ${fade(theme.colors.blue, 0)} 0%,
      ${theme.colors.blue3} 99%)`,
  },
  selectedItemText: {
    opacity: 1,
  },
  simpleIcon: {
    margin: theme.spacing(0.5),
    width: 16,
    height: 16,
    borderRadius: '50%',
    backgroundColor: theme.colors.gray1,
  },
  navIcon: {
    width: 20,
    height: 20,
  },
  listItemIcon: {
    fill: theme.colors.gray1,
    minWidth: 40,
  },
}));

const NavItem = ({ item, isChild, disabled }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const { setAppRoute, breadcrumbs } = useContext(AppRouteContext);

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  const handleClick = () => {
    if (item.route.externalReference) {
      openInNewTab(item.route.path);
    } else {
      setOpen(!open);
      setAppRoute({
        path: item.route.path,
        title: item.route.additional.title,
        clearBreadcrumbs: true,
      });
    }
  };

  const selected =
    breadcrumbs[0].title &&
    (breadcrumbs[0].title === item.name ||
      breadcrumbs[0].title === item.route.additional.title);
  const ItemRouteIcon = item.route && item.route.additional.icon;
  const icon = ItemRouteIcon ? (
    <ItemRouteIcon
      fill={selected ? null : fade('#FFF', 0.3)}
      className={classes.navIcon}
    />
  ) : (
    <div className={classes.simpleIcon} />
  );

  return (
    <>
      <ListItem
        button
        className={cx(classes.navItem, {
          [classes.navItemChild]: isChild,
          [classes.selectedItem]: selected,
        })}
        onClick={handleClick}
        disabled={disabled}
      >
        {!isChild && (
          <ListItemIcon className={classes.listItemIcon}>{icon}</ListItemIcon>
        )}
        <ListItemText
          primary={item.name || item.route.additional.title}
          primaryTypographyProps={{
            className: cx(classes.listItemText, {
              [classes.selectedItemText]: selected,
            }),
          }}
        />
      </ListItem>

      {item.children && (
        <Collapse in={open} timeout="auto">
          <List component="div" disablePadding>
            {item.children.map((childItem) => (
              <NavItem key={childItem.id} item={childItem} isChild />
            ))}
          </List>
        </Collapse>
      )}
    </>
  );
};

NavItem.propTypes = {
  item: PropTypes.shape({
    route: PropTypes.object,
    name: PropTypes.string,
    children: PropTypes.array,
  }).isRequired,
  isChild: PropTypes.bool,
  disabled: PropTypes.bool,
};

NavItem.defaultProps = {
  isChild: false,
  disabled: false,
};

export default NavItem;
