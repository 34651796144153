import { useState, useEffect } from 'react';
import { useQuery } from 'react-fetching-library';

import useMetadataUpdate from 'hooks/useMetadataUpdate';
import { getEndpoint, getTokenEndpoint } from 'helpers/endpoint';

const useExportCsv = ({ baseEndpoint, baseParams, sort, filter }) => {
  const [list, setList] = useState({});
  const [params, setParams] = useState({
    endpoint: '',
    allFetched: null, // all items fetched flag
  });
  const { endpoint, allFetched } = params;

  const { query } = useMetadataUpdate(useQuery, [
    {
      method: 'GET',
      endpoint,
    },
    false,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      const { error, payload } = await query();
      if (!error) {
        const newList = {
          ...payload,
        };
        if (endpoint.includes('continuation-token')) {
          newList.items = [].concat(list.items, payload.items);
        }
        setList(newList);

        if (newList.continuationToken) {
          setParams({
            allFetched: false,
            endpoint: getTokenEndpoint(baseEndpoint, newList.continuationToken),
          });
        } else {
          setParams({
            allFetched: true,
            endpoint: '',
          });
        }
      }
    };

    if (endpoint) {
      fetchData();
    }
  }, [endpoint, query]);

  const fetchExportItems = () => {
    setParams({
      allFetched: false,
      endpoint: getEndpoint(baseEndpoint, baseParams, sort, filter),
    });
  };

  return {
    fetchExportItems,
    exportItems: allFetched ? list.items : null,
    exportItemsLoading: allFetched === false,
  };
};

export default useExportCsv;
