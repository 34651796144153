import { useState, useEffect } from 'react';
import { useQuery } from 'react-fetching-library';
import debounce from 'lodash/debounce';
import qs from 'qs';

import useMetadataUpdate from 'hooks/useMetadataUpdate';
import useDidUpdate from 'hooks/useDidUpdate';
import { ssItems } from 'config/sessionStorage';
import useExportCsv from 'hooks/useExportCsv';
import { getEndpoint, getTokenEndpoint } from 'helpers/endpoint';
import { QUERY_DELAY } from 'components/Shared/constants';
import { useDispatch, useSelector } from 'react-redux';
import { checkIsMaintenanceMode } from '../helpers/downForMaintenance';
import { updateIsMaintenanceMode } from '../redux_store/reducer/reducers/userReducer/userReducer';

const ITEMS_PER_PAGE = 50;

const useFetchList = ({
                        baseEndpoint,
                        baseParams,
                        exportEndpoint,
                        baseSort,
                        baseFilter,
                        customMethod,
                        patches,
                      }) => {
  /*
    items: Array,
    totalCount: Number,
    continuationToken: String
  */
  const dispatch = useDispatch();
  const [list, setList] = useState({});
  const [sort, setSort] = useState({});
  const [filter, setFilter] = useState({});
  const paramsEndpoint = `${baseEndpoint}${qs.stringify(baseParams, {
    addQueryPrefix: true,
  })}`;
  /*
    endpoint: String
    fetchAll: Boolean - load all the items (check all checkbox)
    pagesToLoad: Number - number of pages to load immediately
      (remove item, scroll down using scrollbar)
  */
  const [params, setParams] = useState({
    endpoint: '',
    fetchAll: false,
    pagesToLoad: 1,
  });
  const { endpoint, fetchAll, pagesToLoad } = params;
  const {
    metadata: { currentAccount },
  } = useSelector((state) => state.user);

  const getParams = (patches) => ({
    method: customMethod ?? 'GET',
    endpoint,
    body: patches,
  });

  const { query, loading } = useMetadataUpdate(useQuery, [
    getParams(patches),
    false,
  ]);

  const { fetchExportItems, exportItems, exportItemsLoading } = useExportCsv({
    baseEndpoint: exportEndpoint,
    baseParams: {
      ...baseParams,
      fields: undefined,
    },
    sort,
    filter,
  });

  // load filter/sort params from session storage
  useEffect(() => {
    const storageFilters =
      JSON.parse(sessionStorage.getItem(ssItems.FILTERS)) || {};
    const filters = storageFilters[paramsEndpoint] || {};
    const newSort = filters.sort || baseSort || {};
    const localStorageHasFilters =
      filters.filter && Object.keys(filters.filter).length > 0;
    const newFilter =
      (localStorageHasFilters ? filters.filter : false) || baseFilter || {};

    setSort(newSort);
    setFilter(newFilter);
    setParams({
      ...params,
      endpoint: getEndpoint(baseEndpoint, baseParams, newSort, newFilter),
    });
  }, []);

  // save filter/sort params to session storage
  useEffect(() => {
    const storageFilters =
      JSON.parse(sessionStorage.getItem(ssItems.FILTERS)) || {};
    storageFilters[paramsEndpoint] = { sort, filter };
    sessionStorage.setItem(ssItems.FILTERS, JSON.stringify(storageFilters));
  }, [sort, filter]);

  /*
    Clear filters and fetch new list on current account changing
    or on base endpoint changing.
    Need to trigger an endpoint changing even if it's the same.
    Case: accounts list
  */
  useDidUpdate(() => {
    const newSort = baseSort || {};
    const newFilter = {};
    let newEndpoint = getEndpoint(baseEndpoint, baseParams, newSort, newFilter);
    if (endpoint === newEndpoint) {
      newEndpoint += ' ';
    }

    setList({});
    setSort(newSort);
    setFilter(newFilter);
    setParams({
      endpoint: newEndpoint,
      fetchAll: false,
      pagesToLoad: 1,
    });
  }, [currentAccount.uuid, paramsEndpoint]);

  // fetch data on endpoint changing
  useEffect(() => {
    const setNewEndpoint = (token) => {
      setParams({
        ...params,
        endpoint: getTokenEndpoint(baseEndpoint, token),
      });
    };

    const fetchData = async () => {
      if (endpoint) {
        const { error, payload } = await query(patches);
        if (!error) {
          const newList = {
            totalCount: list.totalCount,
            totalAccountsCount: list.totalAccountsCount,
            completionRate: list.completionRate,
            ...payload,
            aggregations: params.pagesToLoad === 1
              ? payload.aggregations
              : list.aggregations,
          };
          if (endpoint.includes('continuation-token')) {
            newList.items = [].concat(list.items, payload.items);
          }

          if (payload.result) {
            newList.items = payload.result;
          }

          setList(newList);

          // need to load more than 1 page at once
          if (Math.ceil(newList.items.length / ITEMS_PER_PAGE) < pagesToLoad) {
            if (newList.continuationToken) {
              setNewEndpoint(newList.continuationToken);
            } else {
              setParams({
                ...params,
                pagesToLoad: 1,
              });
            }
            // need to load all the pages
          } else if (fetchAll) {
            if (newList.items.length !== newList.totalCount) {
              setNewEndpoint(newList.continuationToken);
            } else {
              setParams({
                ...params,
                fetchAll: false,
              });
            }
          }
        } else {
          const response = await checkIsMaintenanceMode();
          if (response.status === 200) {
            dispatch(updateIsMaintenanceMode(true));
          }
        }
      }
    };

    fetchData();
  }, [endpoint, query]);

  const onSort = (newSort) => {
    setParams({
      ...params,
      endpoint: getEndpoint(baseEndpoint, baseParams, newSort, filter),
    });
    setSort(newSort);
  };

  const onFilter = (dataKey, value, resetPrevList) => {
    const newFilter = { ...filter, [dataKey]: value };
    if (!value) {
      delete newFilter[dataKey];
    }
    setParams({
      ...params,
      endpoint: getEndpoint(baseEndpoint, baseParams, sort, newFilter),
    });
    if (resetPrevList) {
      setList({});
    }
    setFilter(newFilter);
  };

  const clearFilter = () => {
    const newFilter = {};
    setParams({
      ...params,
      endpoint: getEndpoint(baseEndpoint, baseParams, sort, newFilter),
    });
    setFilter(newFilter);
  };

  const onLoadMoreRows = (stopIndex) => {
    const token = list.continuationToken;
    if (list.items && stopIndex > list.items.length - 1 && token && !loading) {
      setParams({
        ...params,
        endpoint: getTokenEndpoint(baseEndpoint, token),
        pagesToLoad: Math.ceil((stopIndex + 1) / ITEMS_PER_PAGE),
      });
    }
  };

  const fetchAllItems = () => {
    if (fetchAll) {
      return;
    }
    const token = list.continuationToken;
    if (token) {
      setParams({
        ...params,
        endpoint: getTokenEndpoint(baseEndpoint, token),
        fetchAll: true,
      });
    }
  };

  const reloadData = (props) => {
    let DELAY = QUERY_DELAY;
    // eslint-disable-next-line no-prototype-builtins
    if (props && props.hasOwnProperty('delay')) {
      DELAY = props.delay;
    }
    const pages = Math.ceil(list.items.length / ITEMS_PER_PAGE);
    // need to trigger endpoint changing
    let newEndpoint = getEndpoint(baseEndpoint, baseParams, sort, filter);
    if (endpoint === newEndpoint) {
      newEndpoint += ' ';
    }
    setTimeout(() => {
      setParams({
        ...params,
        endpoint: newEndpoint,
        pagesToLoad: pages,
      });
    }, DELAY);
  };

  return {
    items: list.items,
    totalAccountsCount: list.totalAccountsCount,
    aggregations: list.aggregations,
    completionRate: list.completionRate,
    totalCount: list.totalCount,
    onLoadMoreRows,
    fetchAllItems,
    sort,
    onSort,
    filter,
    clearFilter,
    onFilter: debounce(onFilter, 500),
    reloadData,
    exportItems,
    fetchExportItems,
    exportItemsLoading,
    loading,
  };
};

export default useFetchList;
