import { createTheme, Grid, makeStyles, MuiThemeProvider } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'formik';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {
  BILLING_CLAIMS_SELECTOR_OPTIONS,
  BILLING_CLAIMS_STATUSES,
  CLAIM_STATUS_SELECTOR_OPTIONS,
  PAYMENT_METHOD_CLAIMS_SELECTOR_OPTIONS,
} from '../../constants';
import CptInfo from './../widgets/CptInfo';
import Select from '../../../FormikFields/ComplexSelect/Select';
import DataInput from '../../../FormikFields/DataInput';
import FormikTextField from '../../../FormikFields/FormikTextField';
import defaultTheme from '../../../../config/theme';
import { addDays, getFormattedDate } from '../../../../helpers/localize';
import BlurScreen from '../../../Shared/blur_screen';

const customTheme = createTheme({
  ...defaultTheme,
  breakpoints: {
    values: {
      xs: 0,
      sm: 850,
      md: 1100,
      lg: 1500,
      xl: 1536,
    },
  },
});

const useStyles = makeStyles((theme) => ({
  mb1: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(1.5),
    },
  },
  form: {
    position: 'relative',
  },
  datepickerWrapperClassName: {
    padding: '3px 12px',
  },
  loaderContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  selector: {
    backgroundColor: '#D9D9D9',
  },
  acceptResultBtnLabel: {
    color: theme.palette.common.white,
    letterSpacing: 2,
    fontWeight: 700,
  },
  acceptResult: {
    background: theme.colors.green,
    padding: theme.spacing(1.25, 2),
  },
  tableInput: {
    marginTop: 0,
  },
}));

const ClaimForm = ({
                     claim,
                     setClaim,
                     errors,
                     backendErrors,
                     setBackendErrors,
                     cptCodes,
                     modifiers,
                     insuranceProviders,
                     appointmentProviders,
                     isAllDataNotLoaded,
                     isDistributionSponsor,
                   }) => {
  const classes = useStyles();
  const { t } = useTranslation(['btn', 'errors', 'notifications']);
  const [filteredInsuranceProviders, setInsuranceProviders] = useState(insuranceProviders);
  const [prevManuallySetClaimStatus, setPrevManuallySetClaimStatus] = useState(null);
  const [prevPaymentDate, setPrevPaymentDate] = useState();

  useEffect(() => {
    setInsuranceProviders(insuranceProviders);
  }, [insuranceProviders]);

  useEffect(() => {
    if (prevManuallySetClaimStatus == null) {
      setPrevManuallySetClaimStatus(claim.claimStatus);
    }
  }, [claim]);

  const updateBackendErrorsIfNeeded = (ids) => {
    ids.forEach((id) => {
      if (backendErrors[id]) {
        const newBackendErrors = { ...backendErrors };
        delete newBackendErrors[id];
        setBackendErrors(newBackendErrors);
      }
    });
  };

  const updateCPTErrorsIfNeeded = (id, key) => {
    if (backendErrors[id] || backendErrors.activities) {
      if (backendErrors[id] && typeof backendErrors[id] === 'string') {
        updateBackendErrorsIfNeeded([id]);
      } else {
        if (backendErrors[id]) {
          const newBackendError = { ...backendErrors };
          const newError = { ...newBackendError[id] };
          newError[id] && newError[id][key] && delete newError[id][key];
          newBackendError.activities && delete newBackendError.activities;
          setBackendErrors({
            ...newBackendError,
            [id]: newError,
          });
        } else {
          const newBackendError = { ...backendErrors };
          newBackendError.activities && delete newBackendError.activities;
          setBackendErrors({
            ...newBackendError,
          });
        }
      }
    }
  };

  const onDismiss = (id) => {
    updateBackendErrorsIfNeeded([id]);
    const newCpt = claim && claim.activities.filter((c) => {
      return id !== c.id;
    });
    updateClaimValue('activities', newCpt);
  };

  const getAutoUpdatedValues = (key, value) => {
    let autoUpdatedValues = {};
    switch (key) {
      case 'isPatientSeen': {
        if (value === false) {
          autoUpdatedValues = { billingStatus: 'appointmentCancelled' };
        }
        break;
      }
      case 'documentDate': {
        if (value) {
          autoUpdatedValues = { isDocumentUploaded: true };
        }
        break;
      }
      case 'billingStatus': {
        if (value === 'insuranceIssue') {
          autoUpdatedValues = { claimStatus: 'notBillable' };
        } else if (value === 'appointmentCancelled') {
          autoUpdatedValues = {
            isPatientSeen: false,
            claimStatus: 'notBillable',
          };
        } else if (value === 'billed') {
          autoUpdatedValues = {
            isPatientSeen: true,
            claimStatus: 'billed',
          };
        } else if (value === 'pending') {
          autoUpdatedValues = {
            claimStatus: 'pending',
          };
        } else {
          autoUpdatedValues = { claimStatus: prevManuallySetClaimStatus };
        }
        break;
      }
      case 'claimStatus': {
        setPrevManuallySetClaimStatus(value);
        break;
      }
      case 'paymentDate': {
        setPrevPaymentDate(value);
      }
      default: {
        break;
      }
    }

    return autoUpdatedValues;
  };

  const autoUpdateClaimStatusIfNeeded = (originalClaim) => {
    let claim = JSON.parse(JSON.stringify(originalClaim));
    if (claim.activities) {
      let isAllPaid = claim.activities.length > 0;
      let isAllPatientResponsibility = claim.activities.length > 0;
      let isAllDenied = claim.activities.length > 0;
      let isPartiallyPaid = false;
      let paymentDate;

      claim.activities.forEach(e => {
        if (e.cptStatus !== 'paid') {
          isAllPaid = false;
        }
        if (e.cptStatus !== 'denied') {
          isAllDenied = false;
        }
        if (e.cptStatus !== 'patientResponsibility') {
          isAllPatientResponsibility = false;
        }
      });

      let hasNotPaidCPT = false;
      const paidSumm = claim.activities.map((activity) => {
          const paid = activity.paid;
          if (!parseFloat(paid || 0)) {
            hasNotPaidCPT = true;
          }
          return isNaN(parseFloat(paid ?? 0)) ? 0 : parseFloat(paid ?? 0);
        },
      ).reduce((a, b) => a + b, 0);

      if (hasNotPaidCPT && paidSumm > 0) {
        isPartiallyPaid = true;
      }

      const allPRisNotNull = claim.activities.map((activity) => {
          const PR = activity.pr;
          return isNaN(parseFloat(PR ?? 0)) ? 0 : parseFloat(PR ?? 0);
        },
      ).reduce((a, b) => a * b, 1) !== 0 && claim.activities.length;

      if (paidSumm > 0 || (allPRisNotNull && paidSumm === 0)) {
        const date = new Date();
        const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        if (!isAllPaid && paidSumm > 0) {
          isAllPaid = true;
        } else if (!isAllPatientResponsibility && allPRisNotNull && paidSumm === 0) {
          isAllPatientResponsibility = true;
        }

        paymentDate = getFormattedDate(lastDay);
      }

      if (claim.billingStatus === BILLING_CLAIMS_STATUSES.pending) {
        claim = {
          ...claim,
          claimStatus: 'pending',
        };
      } else if (claim.billingStatus === BILLING_CLAIMS_STATUSES.insuranceIssue
        || claim.billingStatus === BILLING_CLAIMS_STATUSES.appointmentCancelled) {
        claim = {
          ...claim,
          claimStatus: 'notBillable',
        };
      } else if (isAllPaid || isAllPatientResponsibility || isAllDenied || isPartiallyPaid) {
        claim = {
          ...claim,
          claimStatus: isPartiallyPaid
            ? 'partiallyPaid'
            : isAllPaid
              ? 'paid'
              : isAllDenied
                ? 'denied'
                : isAllPatientResponsibility
                  ? 'patientResponsibility'
                  : claim.billingStatus === BILLING_CLAIMS_STATUSES.billed
                    ? 'billed'
                    : prevManuallySetClaimStatus,
        };
      } else {
        claim = {
          ...claim,
          claimStatus: claim.billingStatus === BILLING_CLAIMS_STATUSES.billed
            ? 'billed'
            : prevManuallySetClaimStatus,
        };
      }

      claim = {
        ...claim,
        paymentDate: claim.activities.length === 0
        || claim.billingStatus === BILLING_CLAIMS_STATUSES.insuranceIssue
        || claim.billingStatus === BILLING_CLAIMS_STATUSES.appointmentCancelled
          ? ''
          : claim.paymentDate
            ? claim.paymentDate
            : paymentDate
              ? paymentDate
              : prevPaymentDate,
      };
    }
    return claim;
  };

  const updateClaimValue = (key, value) => {
    const autoUpdatedValues = getAutoUpdatedValues(key, value);

    updateBackendErrorsIfNeeded([
      ...Object.keys(autoUpdatedValues),
      key,
    ]);

    let newClaim = {
      ...claim,
      ...autoUpdatedValues,
      [key]: value,
    };

    if (key === 'activities' || key === 'billingStatus') {
      newClaim = autoUpdateClaimStatusIfNeeded(newClaim);
    }

    setClaim(newClaim);
  };

  return (
    <BlurScreen isBlurShown={isAllDataNotLoaded}>
      <MuiThemeProvider theme={customTheme}>
        <Form className={classes.form}>
          <Grid
            container
            justify='flex-start'
            spacing={3}
            alignItems='flex-start'
            className={classes.mb1}
          >
            <>
              <Grid item lg={9} md={8} sm={6} xs={0} />
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <Select
                  required={true}
                  disabled={!isDistributionSponsor}
                  dataKey='billingStatus'
                  filterValue={claim && claim.billingStatus}
                  columnData={{
                    isFilterable: true,
                    options: BILLING_CLAIMS_SELECTOR_OPTIONS,
                  }}
                  onChange={updateClaimValue}
                  label={`${t('forms:billingStatus')}`}
                  labelWidth={78}
                  error={errors.billingStatus || backendErrors.billingStatus}
                  className={cx(classes.margin0, classes.fullWidth)}
                />
              </Grid>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <FormikTextField
                  disabled={!isDistributionSponsor}
                  required={true}
                  fullWidth
                  type='text'
                  hideErrorAres={true}
                  name='location'
                  label={`${t('forms:location')}`}
                  backendError={backendErrors.location}
                  onChange={(e) =>
                    updateClaimValue('location', e.currentTarget.value)
                  }
                  className={classes.tableInput}
                />
              </Grid>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <Select
                  filterValue={claim.appointmentProvider}
                  disabled={!isDistributionSponsor || !appointmentProviders}
                  required={false}
                  dataKey='appointmentProvider'
                  columnData={{
                    isFilterable: false,
                    options: appointmentProviders,
                  }}
                  onChange={updateClaimValue}
                  label={`${t('forms:appointmentProvider')}`}
                  labelWidth={78}
                  error={errors.appointmentProvider || backendErrors.appointmentProvider}
                  className={cx(classes.margin0, classes.fullWidth)}
                />
              </Grid>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <Select
                  filterValue={claim.insurance}
                  disabled={!isDistributionSponsor}
                  required={claim && claim.billingStatus !== 'insuranceIssue' && claim.billingStatus !== 'appointmentCancelled'}
                  dataKey='insurance'
                  columnData={{
                    isFilterable: true,
                    options: filteredInsuranceProviders,
                  }}
                  hasSearch={true}
                  totalCount={filteredInsuranceProviders?.length || 0}
                  onFilter={(searchText) => {
                    if (!searchText) {
                      setInsuranceProviders(insuranceProviders);
                    } else {
                      const newOptions = insuranceProviders?.filter(insuranceProvider => insuranceProvider.label.toLowerCase().includes(searchText.toLowerCase()));
                      setInsuranceProviders(newOptions ? newOptions : []);
                    }
                  }}
                  hideEmptyValue
                  onChange={updateClaimValue}
                  label={`${t('forms:insurance')}`}
                  labelWidth={78}
                  error={errors.insurance || backendErrors.insurance}
                  className={cx(classes.margin0, classes.fullWidth)}
                />
              </Grid>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <FormikTextField
                  fullWidth
                  type='text'
                  name='identifier'
                  label={`${t('forms:ID')}`}
                  hideErrorAres={true}
                  backendError={errors.identifier || backendErrors.identifier}
                  multiline
                  required={false}
                  rows={1}
                  maxRows={1}
                  onChange={(e) =>
                    updateClaimValue('identifier', e.currentTarget.value)
                  }
                  className={classes.tableInput}
                  disabled={!isDistributionSponsor}
                />
              </Grid>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <DataInput
                  disabled={!isDistributionSponsor}
                  required={true}
                  datepickerWrapperClassName={classes.datepickerWrapperClassName}
                  name='appointmentDate'
                  backendErrors={{ ...backendErrors, ...errors }}
                  updateValue={updateClaimValue}
                  maxDate={addDays(claim.appointmentDate ? new Date(claim.appointmentDate) : new Date(), 7)}
                  minDate={addDays(claim.appointmentDate ? new Date(claim.appointmentDate) : new Date(), -3)}
                />
              </Grid>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <DataInput
                  required={true}
                  datepickerWrapperClassName={classes.datepickerWrapperClassName}
                  disabled={!isDistributionSponsor}
                  name='documentDate'
                  backendErrors={{ ...backendErrors, ...errors }}
                  updateValue={updateClaimValue}
                  maxDate={new Date()}
                />
              </Grid>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <Select
                  required={true}
                  filterValue={claim.isPatientSeen}
                  dataKey='isPatientSeen'
                  columnData={{
                    isFilterable: false,
                    options: [
                      {
                        label: 'Yes',
                        value: true,
                      },
                      {
                        label: 'No',
                        value: false,
                      },
                    ],
                  }}
                  onChange={updateClaimValue}
                  label={`${t('forms:patientSeen')}`}
                  labelWidth={78}
                  error={errors.isPatientSeen || backendErrors.isPatientSeen}
                  className={cx(classes.margin0, classes.fullWidth)}
                  disabled={!isDistributionSponsor}
                />
              </Grid>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <Select
                  filterValue={claim.isDocumentUploaded}
                  dataKey='isDocumentUploaded'
                  required={true}
                  columnData={{
                    isFilterable: false,
                    options: [
                      {
                        label: 'Yes',
                        value: true,
                      },
                      {
                        label: 'No',
                        value: false,
                      },
                    ],
                  }}
                  onChange={updateClaimValue}
                  label={`${t('forms:documentUploaded')}`}
                  labelWidth={78}
                  error={errors.isDocumentUploaded || backendErrors.isDocumentUploaded}
                  className={cx(classes.margin0, classes.fullWidth)}
                  disabled={!isDistributionSponsor}
                />
              </Grid>
              <CptInfo
                disabled={
                  !isDistributionSponsor
                  || claim.billingStatus === BILLING_CLAIMS_STATUSES.insuranceIssue
                  || claim.billingStatus === BILLING_CLAIMS_STATUSES.appointmentCancelled
                }
                updateCPTErrorsIfNeeded={updateCPTErrorsIfNeeded}
                updateClaimValue={updateClaimValue}
                onDismiss={onDismiss}
                backendErrors={{ ...backendErrors, ...errors.activities }}
                cptCodes={cptCodes}
                modifiers={modifiers}
                activities={!claim
                || claim.billingStatus === BILLING_CLAIMS_STATUSES.insuranceIssue
                || claim.billingStatus === BILLING_CLAIMS_STATUSES.appointmentCancelled
                  ? []
                  : claim && claim.activities
                }
                appointmentDate={claim.appointmentDate}
                claimStatus={claim && claim.claimStatus}
                cptStatus={claim && claim.cptStatus}
                billingStatus={claim && claim.billingStatus}
              />
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <DataInput
                  datepickerWrapperClassName={classes.datepickerWrapperClassName}
                  disabled={!isDistributionSponsor}
                  name='checkDate'
                  backendErrors={{ ...backendErrors, ...errors }}
                  updateValue={updateClaimValue}
                  maxDate={addDays(new Date(), 10)}
                />
              </Grid>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <FormikTextField
                  disabled={!isDistributionSponsor}
                  fullWidth
                  type='text'
                  hideErrorAres={true}
                  name='checkNumber'
                  label={`${t('forms:check')}`}
                  backendError={backendErrors.checkNumber}
                  onChange={(e) =>
                    updateClaimValue('checkNumber', e.currentTarget.value)
                  }
                  className={classes.tableInput}
                />
              </Grid>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <Select
                  disabled={!isDistributionSponsor}
                  dataKey='paymentMethod'
                  filterValue={claim && claim.paymentMethod}
                  columnData={{
                    isFilterable: true,
                    options: PAYMENT_METHOD_CLAIMS_SELECTOR_OPTIONS,
                  }}
                  onChange={updateClaimValue}
                  label={`${t('forms:paymentMethod')}`}
                  labelWidth={78}
                  error={backendErrors.paymentMethod}
                  className={cx(classes.margin0, classes.fullWidth)}
                />
              </Grid>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <DataInput
                  required={claim.billingStatus !== 'insuranceIssue'
                    && claim.billingStatus !== 'appointmentCancelled'
                    && !!claim?.activities?.reduce((accumulator, currentValue) => {
                      return accumulator + (+currentValue?.paid ?? 0);
                    }, 0)
                  }
                  disabled={!isDistributionSponsor}
                  name='paymentDate'
                  backendErrors={{ ...backendErrors, ...errors }}
                  updateValue={updateClaimValue}
                  datepickerWrapperClassName={classes.datepickerWrapperClassName}
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <FormikTextField
                  disabled={!isDistributionSponsor}
                  fullWidth
                  type='text'
                  name='notes'
                  hideErrorAres={true}
                  label={`${t('forms:comments')}`}
                  backendError={backendErrors.notes}
                  multiline
                  rows={3}
                  maxRows={10}
                  onChange={(e) =>
                    updateClaimValue('notes', e.currentTarget.value)
                  }
                  className={classes.tableInput}
                />
              </Grid>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <DataInput
                  disabled={true}
                  name='claimGeneratedTimestamp'
                  updateValue={(name, value) =>
                    updateClaimValue(name, value)
                  }
                  backendErrors={{ ...backendErrors, ...errors }}
                  datepickerWrapperClassName={classes.datepickerWrapperClassName}
                />
              </Grid>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <FormikTextField
                  disabled={true}
                  fullWidth
                  type='text'
                  hideErrorAres={true}
                  name='createdBy'
                  label={`${t('forms:createdBy')}`}
                  backendError={backendErrors.createdBy}
                  className={classes.tableInput}
                />
              </Grid>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <DataInput
                  disabled={true}
                  name='lastModifiedTimestamp'
                  updateValue={(name, value) =>
                    updateClaimValue(name, value)
                  }
                  backendErrors={{ ...backendErrors, ...errors }}
                  datepickerWrapperClassName={classes.datepickerWrapperClassName}
                />
              </Grid>
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <FormikTextField
                  disabled={true}
                  fullWidth
                  type='text'
                  hideErrorAres={true}
                  name='modifiedBy'
                  label={`${t('forms:modifiedBy')}`}
                  backendError={backendErrors.modifiedBy}
                  className={classes.tableInput}
                />
              </Grid>


              <Grid item lg={9} md={8} sm={6} xs={0} />
              <Grid item lg={3} md={4} sm={6} xs={12}>
                <Select
                  disabled={!isDistributionSponsor}
                  dataKey='claimStatus'
                  filterValue={claim && claim.claimStatus}
                  columnData={{
                    isFilterable: true,
                    options: CLAIM_STATUS_SELECTOR_OPTIONS,
                  }}
                  disabledItems={
                    claim && claim.billingStatus && claim.billingStatus === BILLING_CLAIMS_STATUSES.pending
                      ? CLAIM_STATUS_SELECTOR_OPTIONS.filter(item => item.value !== 'pending').map(item => item.value)
                      : claim && claim.billingStatus && (claim.billingStatus === BILLING_CLAIMS_STATUSES.appointmentCancelled || claim.billingStatus === BILLING_CLAIMS_STATUSES.insuranceIssue)
                        ? CLAIM_STATUS_SELECTOR_OPTIONS.filter(item => item.value !== 'notBillable').map(item => item.value)
                        : []
                  }
                  onChange={updateClaimValue}
                  label={`${t('forms:claimStatus')}`}
                  labelWidth={78}
                  error={errors.claimStatus || backendErrors.claimStatus}
                  className={cx(classes.margin0, classes.fullWidth)}
                />
              </Grid>
            </>
          </Grid>
        </Form>
      </MuiThemeProvider>
    </BlurScreen>
  );
};

ClaimForm.propTypes = {
  claim: PropTypes.shape().isRequired,
  setClaim: PropTypes.func.isRequired,
  backendErrors: PropTypes.any.isRequired,
  isDistributionSponsor: PropTypes.bool.isRequired,
  setBackendErrors: PropTypes.func.isRequired,
  cptCodes: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
  insuranceProviders: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
};

export default ClaimForm;
