import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Checkbox,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import i18next from 'i18next';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  inputRoot: {
    width: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  input: {
    fontSize: 14,
    fontWeight: 400,
    whiteSpace: 'nowrap',

    '&:focus': {
      backgroundColor: 'transparent !important',
    },
  },
  buttonContainer: {
    cursor: 'default',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  button: {
    width: '100%',
    marginTop: 6,
  },
}));

const SelectFilter = ({
                        filterValue,
                        hideMultiple,
                        dataKey,
                        columnData: { isFilterable, options },
                        onChange,
                      }) => {
  const classes = useStyles();
  const inputElement = React.useRef()
  const [value, setValue] = React.useState([]);
  const [selectorOptions, setSelectorOptions] = React.useState([]);
  const [open, setOpen] = React.useState(false);

  const { t } = useTranslation(['btn']);
  useEffect(() => {
    if (hideMultiple) {
      if (filterValue === '') {
        setValue('All');
      } else {
        setValue(filterValue);
      }
      setSelectorOptions([
        { label: i18next.t('tables:all'), value: 'All' },
        ...options,
      ]);
    } else {
      if (filterValue === '') {
        setValue(['']);
      } else {
        setValue(filterValue.split(','));
      }
      setSelectorOptions([
        { label: i18next.t('tables:all'), value: '' },
        ...options,
      ]);
    }
  }, []);

  useEffect(() => {
    if (hideMultiple) {
      if (filterValue === '') {
        setValue('All');
      } else {
        setValue(filterValue);
      }
    } else {
      if (filterValue === '') {
        setValue(['']);
      } else {
        setValue(filterValue.split(','));
      }
    }
  }, [filterValue]);

  const handleSelectClose = () => {
    if (!hideMultiple) {
      onChange && onChange(dataKey, value.join(','));
      handleClose()
    }
  };

  const handleSelectChange = (event) => {
    const {
      target: { value: newValue },
    } = event;

    if (!hideMultiple) {
      if (newValue.includes('button')) {
        return;
      }

      if (newValue.length === 0 || newValue.filter(filterValue => filterValue !== '').length === options.length) {
        setValue(['']);
        return;
      }

      let diff;
      if (newValue.length > value.length) {
        diff = newValue.filter((x) => !value.includes(x));
      } else {
        diff = value.filter((x) => !newValue.includes(x));
      }

      if (diff[0] === '') {
        setValue(['']);
      } else {
        setValue(
          typeof newValue === 'string'
            ? newValue.split(',').filter((ele) => {
              return ele !== '';
            })
            : newValue.filter((ele) => {
              return ele !== '';
            }),
        );
      }
    } else {
      onChange(dataKey, newValue === 'All' ? ''  : newValue);
      setValue(newValue);
      handleClose()
    }
  };

  const getLabelByKey = (selected) => {
    if (hideMultiple) {
      const option = selectorOptions.find((key) => key.value === selected);
      return option ? option.label : '';
    } else {
      let renderedValue = '';

      selected.forEach((val) => {
        const option = selectorOptions.find((key) => key.value === val);
        const label = option ? option.label : '';
        renderedValue += `${label}, `;
      });

      return renderedValue.slice(0, -2);
    }
  };

  const applyFilter = () => {
    onChange(dataKey, value.join(','));
    handleClose()
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (

    <Select
      id={`select-${dataKey}`}
      ref={inputElement}
      value={value}
      open={open}
      onOpen={handleOpen}
      multiple={!hideMultiple}
      onChange={handleSelectChange}
      onClose={handleSelectClose}
      SelectProps={{
        classes: {
          select: classes.select,
        },
        MenuProps: {
          getContentAnchorEl: null,
        },
      }}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        getContentAnchorEl: null,
      }}
      input={
        <TextField
          id={`select-${dataKey}`}
          select
          value={filterValue}
          classes={{
            select: classes.select,
            root: classes.inputRoot,
          }}
          InputProps={{
            classes: {
              input: classes.input,
              select: classes.select,
            },
            disableUnderline: true,
            disabled: !isFilterable,
          }}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      }
      renderValue={(selected) => getLabelByKey(selected)}
    >
      {selectorOptions.map((option) => {
        return (
          <MenuItem key={option.value} value={option.value}>
            {!hideMultiple && <Checkbox checked={value.indexOf(option.value) > -1} />}
            <ListItemText primary={option.label} />
          </MenuItem>
        );
      })}
      {!hideMultiple && <MenuItem disableRipple
                                  key={'button'}
                                  value={'button'}
                                  className={classes.buttonContainer}
      >
        <Button
          color='primary'
          disabled={value.join(',') === filterValue}
          variant={'contained'}
          className={cx(classes.button)}
          onClick={applyFilter}
        >
          {t('btn:applyFilter')}
        </Button>
      </MenuItem>}
    </Select>
  );
};

SelectFilter.propTypes = {
  filterValue: PropTypes.arrayOf(PropTypes.string),
  dataKey: PropTypes.string.isRequired,
  columnData: PropTypes.shape({
    isFilterable: PropTypes.bool,
    options: PropTypes.array,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
};

SelectFilter.defaultProps = {
  filterValue: [],
};

export default SelectFilter;
