import React, { useRef, useState } from 'react';
import { ReactComponent as ExportIcon } from '../../../../assets/icons/export-icon.svg';
import RoundedButton from '../../../Shared/RoundedButton';
import { useDispatch, useSelector } from 'react-redux';
import { checkPermission } from '../../../../helpers/checkPermissions';
import { useStyles } from './styles';
import permissions from '../../../../config/permissions';
import { REQUEST_STATUS } from '../../../../redux_store/reducer/reducers/userReducer/constants';
import ExportFilesButtonPopover from './widgets/ExportFilesButtonPopover';
import ExportCSVButtonLink from './widgets/ExportCSVButtonLink';
import { useParams } from 'react-router-dom';
import { useMutation } from 'react-fetching-library';
import showServerError from '../../../../helpers/showError';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const ExportFilesButton = ({
                             fetchCSVData,
                             CSVDataLoading,
                             CSVData,
                           }) => {
  const { t } = useTranslation(['btn']);
  const classes = useStyles();
  const dispatch = useDispatch();
  const downloadCSVLink = useRef();
  const { accountUuid: paramAccountUuid } = useParams();
  const { en: { notifications: tNotifications, titles: tTitles } } = useSelector((state) => state.i18n);
  const { metadata: { currentAccountPermissions, currentAccount } } = useSelector((state) => state.user);
  const [anchorEl, setAnchorEl] = useState();
  const [exporting, setExporting] = useState();
  const [downloadingZIPStatus, setDownloadingZIPStatus] = useState(REQUEST_STATUS.idle);
  const hasLoadFilesPermission = checkPermission(
    currentAccountPermissions,
    permissions.ADMIN_PATIENT_IMPORT,
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getClaimsZIP = () => ({
    method: 'GET',
    endpoint: `/accounts/${paramAccountUuid || currentAccount.uuid}/orders/report`,
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/zip',
    },
  });

  const { mutate: getZIPFile } = useMutation(getClaimsZIP);

  const downloadedZIPFile = async () => {
    handleClose();
    setDownloadingZIPStatus(REQUEST_STATUS.loading);
    const { payload, error, status } = await getZIPFile();
    if (error) {
      setDownloadingZIPStatus(REQUEST_STATUS.failed);
      setTimeout(() => {
        setDownloadingZIPStatus(REQUEST_STATUS.idle);
      }, 2000);
      const options = payload && {
        correlationUUID: payload.correlationUUID,
        userUUID: user.uuid,
      };
      switch (status) {
        case 401: {
          showServerError(dispatch, tNotifications.notPermissionForEditingClaim);
          break;
        }
        default: {
          showServerError(dispatch, '', options);
          break;
        }
      }
    } else if (payload && payload.size > 0) {
      setDownloadingZIPStatus(REQUEST_STATUS.succeeded);
      setTimeout(() => {
        setDownloadingZIPStatus(REQUEST_STATUS.idle);
      }, 2000);
      const url = window.URL.createObjectURL(new Blob([payload], { type: 'application/zip' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${tTitles.orders} ${Date.now()}.zip`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    }
  };

  return hasLoadFilesPermission && <div>
    <RoundedButton
      variant={'outlined'}
      color='primary'
      size='small'
      isLoading={CSVDataLoading || downloadingZIPStatus === REQUEST_STATUS.loading}
      isSuccess={downloadingZIPStatus === REQUEST_STATUS.succeeded}
      isFailed={downloadingZIPStatus === REQUEST_STATUS.failed}
      whiteIconStatusColor={true}
      onClick={handleClick}
      className={classes.iconBtn}
      LoadingWrapperProps={{
        LoaderProps: {
          disableShrink: true,
        },
      }}
    >
      <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingLeft: 4,
      }}
      >
        <ExportIcon className={classes.icon} />
        <div style={{
          textTransform: 'none',
          fontSize: '0.875rem',
          fontWeight: 500,
          lineHeight: 1.75,
          letterSpacing: '0.02857em',
          marginRight: 8,
        }}>
          {t('btn:Download')}
        </div>
        <ExpandMoreIcon />
      </div>
    </RoundedButton>
    <ExportFilesButtonPopover
      anchorEl={anchorEl}
      handleClose={handleClose}
      classes={classes}
      setExporting={setExporting}
      fetchCSVData={fetchCSVData}
      downloadedZIPFile={downloadedZIPFile}
    />
    <ExportCSVButtonLink
      CSVData={CSVData}
      exporting={exporting}
      setExporting={setExporting}
      downloadCSVLink={downloadCSVLink}
    />
  </div>;
};

export default ExportFilesButton;
