import * as React from 'react';
import {
  IconButton,
  InputAdornment,
  InputBase,
  makeStyles,
} from '@material-ui/core';
import TodayIcon from '@material-ui/icons/Today';
import CancelIcon from '@material-ui/icons/Cancel';
import PropTypes from 'prop-types';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import cx from 'classnames';
import {
  checkIsValidDate,
  parseDate,
  parseDateToString,
  parsedOnBlurInput,
  parseStringToDate,
} from './helpers';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const useStyles = makeStyles(() => ({
  popover: {
    width: 332,
    height: 332,
  },
  paper: {
    width: '100%',
    height: '100%',
    maxWidth: 'fit-content',
    maxHeight: 'fit-content',
    display: 'flex',
  },
  inputRoot: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  inputShouldRange: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  input: {
    fontSize: 16,
    fontWeight: 400,
    marginLeft: 6,
  },
  inputSmallFontSize: {
    fontWeight: 400,
    fontSize: 13,
  },
  inputExtraSmallFontSize: {
    fontWeight: 400,
    fontSize: 10,
  },
  placeholder: {
    fontSize: 15,
    fontWeight: 400,
    padding: '7px 0',
  },
  smallPlaceholder: {
    fontSize: 12,
    fontWeight: 400,
    padding: '7px 0',
  },
  extraSmallPlaceholder: {
    fontSize: 10,
    fontWeight: 400,
    padding: '7px 0',
  },
  pointerCursor: {
    cursor: 'pointer',
  },
  icon: {
    padding: 0,
    width: 15,
    height: 15,
    disableRipple: true,

    '& .MuiTouchRipple-root span': {
      backgroundColor: 'transparent !important',
    },
  },
  arrowIcon: {
    width: '1em',
    height: '1em',
  },
  disabled: {
    color: 'rgba(0, 0, 0, 0.38)',
  },
  dateRangeDelimiter: {
    width: 28,
    color: 'gray',
    fontSize: 9,
  },
  usualDateRangeDelimiter: {
    margin: '0 10px 2px 10px',
  },
  inputsData: {
    display: 'flex',
    alignItems: 'center',
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));

function RangeInput({
                      date,
                      onChange,
                      handleClick,
                      handleClose,
                      label,
                      shouldReduceFontSize,
                      disabled,
                      shortView,
                      error,
                    }) {
  const [startInputValue, setStartInputValue] = React.useState('');
  const [endInputValue, setEndInputValue] = React.useState('');

  const classes = useStyles();

  const tryParseDate = (newDate) => {
    if (newDate[0] != null && newDate[0].toString() !== 'Invalid Date') {
      setStartInputValue(parseDateToString(newDate[0]));
    } else {
      setStartInputValue('');
    }

    if (newDate[1] != null && newDate[1].toString() !== 'Invalid Date') {
      setEndInputValue(parseDateToString(newDate[1]));
    } else {
      setEndInputValue('');
    }
  };

  const handleDateChange = (newDate) => {
    if (onChange !== null && onChange !== undefined) {
      onChange(newDate);
    }

    tryParseDate(newDate);
  };

  function updateFilter(startDate, endDate) {
    if (endDate && startDate) {
      const isValidDate =
        checkIsValidDate(startDate) && checkIsValidDate(endDate);

      if (isValidDate) {
        handleDateChange([
          parseStringToDate(startDate),
          parseStringToDate(endDate),
        ]);
      }
    }
  }

  React.useEffect(() => {
    if (!date) {
      setStartInputValue('');
      setEndInputValue('');
    } else {
      tryParseDate(date);
    }
  }, [date]);

  return (
    <div className={cx(classes.inputShouldRange, {
      [classes.pointerCursor]: (!startInputValue && !endInputValue) || shortView,
    })}
         onClick={(!startInputValue && !endInputValue) ? (e) => {
           e.preventDefault();
           e.stopPropagation();
           handleClick(e);
         } : null}
    >
      <div className={classes.inputContainer}>
        <IconButton
          disabled={disabled}
          disableFocusRipple
          classes={{
            root: cx(classes.icon, {
              [classes.disabled]: disabled,
            }),
          }}
          onClick={handleClick}
        >
          <TodayIcon
            color='primary'
            classes={{
              root: cx(classes.icon, {
                [classes.disabled]: disabled,
              }),
            }}
          />
        </IconButton>

        <div className={classes.inputsData}
             style={{
               flexDirection: shortView ? 'column' : 'row',
               marginRight: shortView ? 4 : null,
             }}
        >
          <InputBase
            disabled={disabled}
            label={label}
            style={shortView ? {
              height: endInputValue !== startInputValue ? '.75rem' : null,
              fontSize: endInputValue !== startInputValue ? '.7rem' : null,
            } : null}
            error={error}
            classes={{
              root: cx(classes.inputRoot, {
                [classes.pointerCursor]: (!startInputValue && !endInputValue) || shortView,
              }),
              // eslint-disable-next-line no-nested-ternary
              input: cx(startInputValue
                ? shouldReduceFontSize
                  ? endInputValue !== startInputValue ? classes.inputExtraSmallFontSize : classes.inputSmallFontSize
                  : classes.input
                : shouldReduceFontSize
                  ? endInputValue !== startInputValue ? classes.extraSmallPlaceholder : classes.smallPlaceholder
                  : classes.placeholder, {
                  [classes.pointerCursor]: (!startInputValue && !endInputValue) || shortView,
                },
              ),
            }}
            onFocus={shortView ? (e) => {
              e.target.blur();
              e.preventDefault();
              e.stopPropagation();
              handleClick(e);
            } : null}
            value={startInputValue}
            onKeyUp={(event) => {
              const newValue = parseDate(
                event.currentTarget.value,
                startInputValue,
              );
              setStartInputValue(newValue);
              updateFilter(newValue, endInputValue);
            }}
            onBlur={(event) => {
              const newValue = parsedOnBlurInput(event.currentTarget.value);
              if (newValue.toString() !== 'NaN/NaN/NaN') {
                setStartInputValue(newValue);
              } else {
                setStartInputValue('');
              }
            }}
            placeholder={shortView ? '' : 'MM/DD/YYYY'}
            autoComplete='off'
            startAdornment={
              <InputAdornment position='end'>
                {date != null && date[1] !== null && endInputValue !== startInputValue && shortView && (
                  <div className={classes.dateRangeDelimiter}> from </div>
                )}
              </InputAdornment>
            }
          />
          {
            (endInputValue !== startInputValue || (!shortView && endInputValue && startInputValue)) &&
            <>
              {(!shortView && endInputValue && startInputValue) && (
                <div className={classes.usualDateRangeDelimiter}> to </div>
              )}
              <InputBase
                style={shortView ? { height: '.75rem', fontSize: '.7rem' } : null}
                error={error}
                classes={{
                  root: cx(classes.inputRoot, {
                    [classes.pointerCursor]: shortView,
                  }),
                  // eslint-disable-next-line no-nested-ternary
                  input: cx(startInputValue
                    ? shouldReduceFontSize
                      ? endInputValue !== startInputValue ? classes.inputExtraSmallFontSize : classes.inputSmallFontSize
                      : classes.input
                    : shouldReduceFontSize
                      ? endInputValue !== startInputValue ? classes.extraSmallPlaceholder : classes.smallPlaceholder
                      : classes.placeholder, {
                      [classes.pointerCursor]: shortView,
                    },
                  ),
                }}
                autoComplete='off'
                placeholder='MM/DD/YYYY'
                value={endInputValue}
                onChange={(event) => {
                  const newValue = parseDate(event.currentTarget.value, endInputValue);
                  setEndInputValue(newValue);
                  updateFilter(startInputValue, newValue);
                }}
                onFocus={shortView ? (e) => {
                  e.target.blur();
                  e.preventDefault();
                  e.stopPropagation();
                  handleClick(e);
                } : null}
                onBlur={(event) => {
                  const newValue = parsedOnBlurInput(event.currentTarget.value);
                  if (newValue.toString() !== 'NaN/NaN/NaN') {
                    setEndInputValue(newValue);
                  } else {
                    setEndInputValue('');
                  }
                }}
                startAdornment={
                  shortView && date != null && date[1] !== null && endInputValue !== startInputValue && shortView ? (
                    <InputAdornment position='end'>
                      <div className={classes.dateRangeDelimiter}> to</div>
                    </InputAdornment>
                  ) : null
                }
              />
            </>
          }
        </div>
      </div>
      {
        ((endInputValue || startInputValue) || (shortView && !endInputValue && !startInputValue)) && (
          <IconButton
            disabled={disabled}
            classes={{
              root: cx(classes.icon, {
                [classes.disabled]: disabled,
              }),
            }}
            onClick={() => {
              setStartInputValue('');
              setEndInputValue('');
              handleDateChange([null, null]);
              handleClose(null);
            }}
          >
            {(shortView && !endInputValue && !startInputValue) ?
              <ArrowDropDownIcon
                classes={{
                  root: cx(classes.icon, classes.arrowIcon, {
                    [classes.disabled]: disabled,
                  }),
                }}
              /> :
              <CancelIcon
                classes={{
                  root: cx(classes.icon, {
                    [classes.disabled]: disabled,
                  }),
                }}
              />
            }
          </IconButton>
        )}
    </div>
  );
}

RangeInput.propTypes = {
  date: PropTypes.instanceOf(Date),
  onChange: PropTypes.func,
  handleClick: PropTypes.func,
  handleClose: PropTypes.func,
  label: PropTypes.string,
  shouldReduceFontSize: PropTypes.bool,
  disabled: PropTypes.bool,
  shortView: PropTypes.bool,
};

RangeInput.defaultProps = {
  date: null,
  onChange: undefined,
  handleClose: undefined,
  handleClick: undefined,
  label: undefined,
  shouldReduceFontSize: true,
  disabled: false,
  shortView: false,
};

export default RangeInput;
