import React, { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  makeStyles,
  IconButton,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';
import MomentUtils from '@date-io/moment';
import cx from 'classnames';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from '@material-ui/pickers';
import Timer from '@material-ui/icons/Timer';
import CancelIcon from '@material-ui/icons/Cancel';
import FormikSelectField from '../../../FormikFields/FormikSelectField';
import momentTZ from 'moment-timezone';
import { getDateFromMinuteOffset } from '../../../../helpers/dateHelper';

const useStyles = makeStyles((theme) => ({
  title: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(1),
    fontWeight: 500,
    color: theme.palette.text.secondary,
  },
  dataContainer: {
    maxWidth: 800,
  },
  checkbox: {
    marginLeft: theme.spacing(0.5),
  },
  divider: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  addBtn: {
    margin: theme.spacing(1, 0, 1, 2),
  },
  fullWidth: {
    width: '100%',
    marginTop: 0,
  },
  formControl: {
    minWidth: 100,
    marginTop: theme.spacing(3),
  },
  timePicker: {
    padding: 0,

    '& .MuiOutlinedInput-input': {
      padding: '10.5px 14px',
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 16,
    },
    '& .MuiOutlinedInput-adornedStart': {
      paddingLeft: 2,
    },
    '& .MuiIconButton-root': {
      padding: 8,
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 12px) scale(1)',
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75)',
    },
  },
  icon: {
    width: 15,
    height: 15,
  },
  iconPadding: {
    padding: 8,
  },
  inputPadding: {
    padding: '10px 0 !important',
  },
}));

const ScheduledActivitiesSection = ({ scheduleDeliverTime }) => {
  const classes = useStyles();
  const { t } = useTranslation(['forms', 'errors', 'notifications']);
  const [selectedTime, handleTimeChange] = useState(null);
  const [timeZones, setTimeZones] = useState([]);

  useEffect(() => {
    const timeZonesList = momentTZ.tz.zonesForCountry('US', true);
    const parsedTimeZonesList = timeZonesList.map((timeZone) => {
      const offset = timeZone.offset / 60;

      return {
        label: `${timeZone.name} ${offset > 0 ? '+' : ''}${offset} hour(s)`,
        value: timeZone.name,
      };
    });

    setTimeZones(parsedTimeZonesList);
    const date = getDateFromMinuteOffset(scheduleDeliverTime);
    handleTimeChange(date);
  }, []);

  return (
    <Grid container direction='column' alignItems='flex-start'>
      <Typography className={classes.title}>
        {t('forms:scheduledActivities')}:
      </Typography>
      <Grid container spacing={3} className={classes.dataContainer}>
        <Grid item xs={5}>
          <FormikSelectField
            name='ianaTimeZone'
            label={t('forms:ianaTimeZone')}
            type='text'
            menuItems={timeZones}
            className={cx(classes.fullWidth)}
            required
          />
        </Grid>
        <Grid item xs={4}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Grid container justifyContent='space-around'>
              <Field name='scheduleDeliverTime' type='time'>
                {({ field, form }) => (
                  <KeyboardTimePicker
                    label={t('forms:scheduleDeliverTime')}
                    mask='__:__ _M'
                    inputVariant='outlined'
                    className={cx(classes.timePicker, classes.fullWidth)}
                    required
                    value={selectedTime}
                    autoOk
                    name={field.name}
                    onChange={(e) => {
                      // eslint-disable-next-line no-underscore-dangle
                      if (e && e._isValid) {
                        form.setFieldValue(
                          field.name,
                          // eslint-disable-next-line no-underscore-dangle
                          e._d.getHours() * 60 + e._d.getMinutes(),
                        );
                      } else {
                        form.setFieldValue(field.name, 'invalid_value');
                      }
                      handleTimeChange(e);
                    }}
                    keyboardIcon={
                      <Timer color='primary' className={classes.icon} />
                    }
                    InputProps={{
                      classes: {
                        input: classes.inputPadding,
                      },
                      endAdornment: (
                        <IconButton
                          className={classes.iconPadding}
                          onClick={() => form.setFieldValue(field.name, null)}
                        >
                          <CancelIcon className={classes.icon} />
                        </IconButton>
                      ),
                    }}
                    InputAdornmentProps={{
                      position: 'start',
                    }}
                  />
                )}
              </Field>
            </Grid>
          </MuiPickersUtilsProvider>
        </Grid>
      </Grid>
    </Grid>
  );
};

ScheduledActivitiesSection.propTypes = {};

ScheduledActivitiesSection.defaultProps = {};

export default ScheduledActivitiesSection;
